export const MAX_LENGTH_VEHICLE_REG = 17;
export const MAX_LENGTH_ILU_CODE = 17;
export const MAX_LENGTH_EMPTY_VEHICLE_WEIGHT = 6;
export const MAX_LENGTH_GOODS_WEIGHT = 5;
export const MAX_LENGTH_LENGTH = 4; //  decimals: 1
export const MAX_LENGTH_WIDTH = 4; //  decimals: 2
export const MAX_LENGTH_HEIGHT = 4; //  decimals: 2
export const MAX_LENGTH_CUSTOMER_REFERENCE = 17;
export const MAX_LENGTH_PARTNER_BOOKING_NUMBER = 20;
export const MAX_LENGTH_DROP_OFF_REFERENCE = 50;
export const MAX_LENGTH_PICK_UP_REFERENCE = 50;
export const MAX_LENGTH_LOADING_NOTE = 130;
export const MAX_LENGTH_TEMPERATURE = 15;
