import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getIntermodalCreateFormModel } from "../redux/formSlice";
import { useEffect, useRef } from "react";
import {
  RecalculateJourneyInput,
  recalculateJourneyThunk,
  transformToIntermodalJourneyInput,
} from "../redux/recalculateJourneyThunk";

export const useIntermodalJourneyFetcher = () => {
  const {
    intermodalForm: model,
    legs,
    sailingsMeta: { loading },
  } = useSelector(getIntermodalCreateFormModel);

  const oldVariables = useRef<RecalculateJourneyInput | undefined>(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    const newVariables = transformToIntermodalJourneyInput(model);

    if (
      newVariables != null &&
      !shallowEqual(newVariables, oldVariables.current)
    ) {
      dispatch(recalculateJourneyThunk(newVariables) as any);
    }
    oldVariables.current = newVariables;
  }, [model, oldVariables, dispatch]);

  return { legs: legs, loading };
};
