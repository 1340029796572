import { Box, BoxProps, Row, Text } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { ComponentPropsWithoutRef, ReactNode } from "react";

export interface LabelProps extends BoxProps {
  text: string;
  optional?: boolean;
  maxCharacters?: number;
  textWidth?: BoxProps["width"];
  htmlFor?: ComponentPropsWithoutRef<"label">["htmlFor"];

  customInfoLabel?: ReactNode;
}

export const Label: React.FC<LabelProps> = React.memo(
  ({
    text,
    optional = false,
    children,
    maxCharacters,
    row,
    textWidth,
    htmlFor,
    customInfoLabel,
    ...boxProps
  }) => {
    const extraInfoLabel = getExtraInfoLabel(optional, maxCharacters);

    const infoLabel =
      customInfoLabel ??
      (extraInfoLabel && (
        <Text
          color={cssColor("--lhds-color-ui-600")}
          variant={"caption"}
          size={"small"}
        >
          {extraInfoLabel}
        </Text>
      ));

    return (
      <label htmlFor={htmlFor}>
        <Box {...boxProps} row={row} gap>
          <Row
            justifyContent={"space-between"}
            alignItems={"center"}
            width={textWidth}
            gap={1.5}
          >
            <Text
              color={cssColor("--lhds-color-ui-600")}
              size={"small"}
              whiteSpace={"nowrap"}
            >
              {text}
            </Text>
            {!row && infoLabel}
          </Row>
          {children}
        </Box>
        {row && (
          <Row justifyContent={"flex-end"} indent={0.5} spacing={0.5}>
            {infoLabel}
          </Row>
        )}
      </label>
    );
  }
);

const getExtraInfoLabel = (
  optional: boolean,
  maxCharacters: number | undefined
): string | undefined => {
  const list: Array<string> = [];
  if (optional) {
    list.push("optional");
  }
  if (maxCharacters) {
    list.push("max " + maxCharacters + " characters");
  }
  if (list.length) {
    return "(" + list.join(", ") + ")";
  }
  return undefined;
};
