import { RouteLegProps } from "@stenajs-webui/elements";
import { parseISO } from "date-fns";
import { RouteLegInfo } from "../../../common/types/RouteLegInfo";
import {
  FormIntermodalBookingLegFragment,
  IntermodalJourneyLegFragment,
  Route,
  Sailing,
} from "../../../generated";
import { formatDepartureDateTime } from "./dateUtils";

export const transformIntermodalBookingToRouteLegProps = (
  firstSailing: Pick<Sailing, "scheduledDeparture" | "publicDeparture"> & {
    route: Pick<Route, "departureLocation">;
  },
  lastSailing: Pick<Sailing, "scheduledArrival" | "publicArrival"> & {
    route: Pick<Route, "arrivalLocation">;
  }
): Pick<RouteLegProps, "departure" | "arrival"> => {
  return {
    departure: {
      location: firstSailing.route.departureLocation,
      dateTime: formatDepartureDateTime(parseISO(firstSailing.publicDeparture)),
      originalDateTime: getRouteLegOriginalDateTime(
        firstSailing.scheduledDeparture,
        firstSailing.publicDeparture
      ),
    },
    arrival: {
      location: lastSailing.route.arrivalLocation,
      dateTime: formatDepartureDateTime(parseISO(lastSailing.publicArrival)),
      originalDateTime: getRouteLegOriginalDateTime(
        lastSailing.scheduledArrival,
        lastSailing.publicArrival
      ),
    },
  };
};

export const transformBookingToRouteLegInfo = (
  booking: FormIntermodalBookingLegFragment
): RouteLegInfo => {
  return {
    ...transformSailingToRouteLegInfo(booking.sailing),
    timeFromPreviousArrivalInSeconds: booking.timeFromPreviousArrivalInSeconds,
    departureTimeIsValid: booking.departureTimeIsValid,
  };
};

export const transformLegToRouteLegInfo = (
  leg: IntermodalJourneyLegFragment
): RouteLegInfo => {
  return {
    ...transformSailingToRouteLegInfo(leg.sailing),
    timeFromPreviousArrivalInSeconds: leg.timeFromPreviousArrivalInSeconds,
    departureTimeIsValid: true,
  };
};

const transformSailingToRouteLegInfo = (
  sailing: FormIntermodalBookingLegFragment["sailing"]
): Omit<
  RouteLegInfo,
  "timeFromPreviousArrivalInSeconds" | "departureTimeIsValid"
> => {
  return {
    sailingId: sailing.id,
    isTrain: sailing.isTrain,
    route: sailing.route,
    scheduledArrival: sailing.scheduledArrival,
    scheduledDeparture: sailing.scheduledDeparture,
    publicArrival: sailing.publicArrival,
    publicDeparture: sailing.publicDeparture,
  };
};

export const getRouteLegOriginalDateTime = (
  scheduledDateTime: string,
  publicDateTime: string
) => {
  return publicDateTime !== scheduledDateTime
    ? formatDepartureDateTime(parseISO(scheduledDateTime))
    : undefined;
};
