import "./index.css";
import { App } from "./App";
import { createRoot } from "react-dom/client";
import Modal from "react-modal";

Modal.setAppElement("#root");

const container = document.getElementById("root")!;

const root = createRoot(container);

root.render(<App />);
