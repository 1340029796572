import {
  FormIntermodalBookingFragment,
  FormIntermodalBookingLegFragment,
  IntermodalJourneyInput,
  IntermodalJourneySailingFragment,
} from "../../../generated";

export const shouldShowTrainBooking = (
  selectedBookingId: string | null,
  booking: FormIntermodalBookingLegFragment
): boolean =>
  booking.sailing.isTrain &&
  (selectedBookingId == null || selectedBookingId === booking.id);

export const isTrainBooking = (
  booking: FormIntermodalBookingLegFragment
): boolean => booking.sailing.isTrain;

function shouldShowPluginForSailing(
  sailing: Pick<IntermodalJourneySailingFragment, "enablePlugin">
) {
  return sailing.enablePlugin;
}

export function shouldShowPluginForJourney(
  sailings: Pick<IntermodalJourneySailingFragment, "enablePlugin">[]
) {
  return sailings.some(shouldShowPluginForSailing);
}

export const transformIntermodalBookingToIntermodalJourneyInput = (
  intermodalBooking: FormIntermodalBookingFragment,
  date: string,
  time: string,
  firstLegNumber: number
): IntermodalJourneyInput => {
  return {
    customerNumber: intermodalBooking.bookings[0].customer.id,
    date,
    time,
    height: intermodalBooking.bookings[0].height,
    length: intermodalBooking.bookings[0].length,
    numberOfPlugins: intermodalBooking.numberOfPlugins,
    routeCode: intermodalBooking.route.id,
    searchByDeparture: true,
    totalWeight: intermodalBooking.bookings[0].weight,
    vehicleTypeCode: intermodalBooking.bookings[0].vehicleType.id,
    width: intermodalBooking.bookings[0].width,
    firstLegNumber,
  };
};
