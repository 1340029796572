import { parseFloatElseUndefined } from "@stenajs-webui/core";
import { FieldValidationError } from "./types";

export const isDefined = <T>(value: T | null | undefined): value is T => {
  return value != null;
};

export function validate<TKey>(
  errors: FieldValidationError<TKey>[],
  cond: boolean,
  fieldName: TKey,
  message: string
): asserts cond {
  if (!cond) {
    errors.push({ fieldName, message });
  }
}

export function validateAndParseNumber<TKey>(
  errors: FieldValidationError<TKey>[],
  value: string,
  fieldName: TKey,
  name: TKey | string = fieldName
): number {
  if (value.length === 0) {
    errors.push({
      fieldName,
      message: `${name} is empty`,
    });

    return 0;
  }

  const x = parseFloatElseUndefined(normalizeFloatStringValue(value));

  if (x == null) {
    errors.push({
      fieldName,
      message: `"${value}" is not a number`,
    });

    return 0;
  }

  return x;
}

export const normalizeFloatStringValue = (s: string): string =>
  s.replace(/,/g, ".").replace(/ /g, "");
