import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { useDispatch, useSelector } from "react-redux";
import {
  getIntermodalCreateFormIntermodalBooking,
  setCreateIntermodalFormValue,
} from "../redux/formSlice";
import { ENABLE_CREATE_BOOKING_ARRIVE_BEFORE } from "../../../config/featureFlags";

export const DateVariantSelector = () => {
  const { searchByDeparture } = useSelector(
    getIntermodalCreateFormIntermodalBooking
  );
  const dispatch = useDispatch();

  const onValueChange = (value: boolean) =>
    dispatch(
      setCreateIntermodalFormValue({
        values: { searchByDeparture: value },
      })
    );
  return (
    <>
      <RadioButtonWithLabel
        className="t_depart_after"
        label={"Depart after"}
        checked={searchByDeparture === true}
        value={"DepartAfter"}
        onValueChange={() => onValueChange(true)}
      />
      {ENABLE_CREATE_BOOKING_ARRIVE_BEFORE && (
        <RadioButtonWithLabel
          className="t_arrive_before"
          label={"Arrive before"}
          checked={searchByDeparture === false}
          value={"ArriveBefore"}
          onValueChange={() => onValueChange(false)}
        />
      )}
    </>
  );
};
