import { TableDepartureFragment } from "../../../generated";
import { DepartureTableBookingViewModel } from "../models/DepartureTableBookingViewModel";

export const transformDepartureBookingToViewModel = (
  booking: TableDepartureFragment["bookings"][number]
): DepartureTableBookingViewModel => {
  return {
    intermodalBookingNumber: booking.intermodalBooking.id,
    account: booking.customer.customerIndex,
    id: booking.id,
    iluCode: booking.iluCode,
    loadingNote: booking.loadingNote,
    partnerBookingNumber: booking.partnerBooking?.number,
    routeCode: booking.intermodalBooking.route.id,
    status: booking.status,
  };
};
