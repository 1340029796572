import { useQuery } from "@apollo/client";
import { GetIntermodalRoutesDocument } from "../../generated";

export function useRoutesOptions(validFromDate: string | null) {
  const { data, loading } = useQuery(GetIntermodalRoutesDocument, {
    variables: {
      validFromDate,
    },
  });

  const routeOptions =
    data?.allIntermodalRoutes.map((r) => ({
      label: r.description,
      value: r.id,
    })) ?? [];
  return {
    routeOptions,
    loading,
  };
}
