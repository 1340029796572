import { Space, Txt } from "@stenajs-webui/core";
import * as React from "react";
import { cssColor } from "@stenajs-webui/theme";
import { Icon, stenaCog } from "@stenajs-webui/elements";
import styled from "@emotion/styled";

const CustomStyledEnvWarning = styled.div({
  padding: "4px 12px",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px inset ${cssColor("--lhds-color-ui-300")}`,
  backgroundColor: cssColor("--lhds-color-ui-100"),
});

const getEnvName = () => {
  switch (window.location.host) {
    case "sl-intermodal-test.apps.openshift.development.stena.io":
      return "Test environment";
    case "sl-intermodal-qa.apps.ocp.aws.stena.io":
      return "QA environment";
    case "localhost:8080":
      return "localhost";
    case "sl-intermodal-prod.apps.ocp.aws.stena.io":
    default:
      return null;
  }
};

export const EnvironmentWarning: React.FC = () => {
  const envWarningText = getEnvName();

  if (envWarningText == null) {
    return null;
  }

  return (
    <CustomStyledEnvWarning>
      <Icon icon={stenaCog} />
      <Space />
      <Txt>{envWarningText}</Txt>
    </CustomStyledEnvWarning>
  );
};
