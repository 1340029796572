import { HazardousGoodsStatus } from "../../../generated";

export const hazardousGoodsOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const hazardousGoodsStatusOptions = [
  {
    label: "-",
    value: HazardousGoodsStatus.NotRequested,
  },
  {
    label: "Requested",
    value: HazardousGoodsStatus.Requested,
  },
  {
    label: "Confirmed",
    value: HazardousGoodsStatus.Confirmed,
  },
];
