import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  authError: boolean;
}

const initialState: UserState = {
  authError: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    showAuthError(state) {
      state.authError = true;
    },
  },
});

export const { showAuthError } = userSlice.actions;

export const userReducer = userSlice.reducer;
