import { Box, Clickable } from "@stenajs-webui/core";

interface Props {
  selectable?: boolean;
  selected: boolean;
  onSelected: () => void;
  children?: React.ReactNode;
  className?: string;
}

export const SelectableBox: React.FC<Props> = ({
  selectable = true,
  selected,
  onSelected,
  children,
  ...props
}) => {
  if (selectable) {
    return (
      <Box
        {...props}
        shadow={selected ? "box" : undefined}
        background={selected ? "white" : undefined}
      >
        <Clickable onClick={onSelected}>{children}</Clickable>
      </Box>
    );
  } else {
    return <Box>{children}</Box>;
  }
};
