import { PickByValue } from "@stenajs-webui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  getIntermodalBookingLegFormValueByIndex,
  IntermodalBookingLegFormModel,
  setBookingFormValue,
} from "../../redux/formSlice";
import { ConditionallyEditableTextArea } from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableTextArea";
import { TextAreaProps } from "@stenajs-webui/forms";

interface Props
  extends Omit<TextAreaProps, "value" | "onValueChange" | "name"> {
  selectedBookingIndex: number | null;
  name: keyof PickByValue<IntermodalBookingLegFormModel, string>;
  editable: boolean;
}

export const UpdateBookingTextArea: React.FC<Props> = ({
  editable,
  selectedBookingIndex,
  name,
  ...props
}) => {
  const value = useSelector((state: RootState) =>
    getIntermodalBookingLegFormValueByIndex(state, selectedBookingIndex, name)
  );
  const dispatch = useDispatch();

  return (
    <ConditionallyEditableTextArea
      {...props}
      editable={editable}
      value={value}
      onValueChange={(value) =>
        dispatch(
          setBookingFormValue({
            index: selectedBookingIndex,
            values: { [name]: value },
          })
        )
      }
    />
  );
};
