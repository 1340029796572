import { flatMap } from "lodash";
import {
  HazardousGoodsStatus,
  TableIntermodalBookingFragment,
} from "../../../generated";
import { SpecialProperty } from "../bookings-table/models/SpecialProperty";

export function getBookingSpecialPropertiesFromBookings(
  intermodalBooking: TableIntermodalBookingFragment
): SpecialProperty[] {
  return flatMap(intermodalBooking.bookings, (booking) =>
    getBookingSpecialProperties(
      booking.hazardousGoodsStatus,
      intermodalBooking.craneable,
      intermodalBooking.numberOfPlugins
    )
  );
}

export function getBookingSpecialProperties(
  hazardousGoodsStatus: HazardousGoodsStatus,
  craneable: boolean,
  numberOfPlugins: number
): SpecialProperty[] {
  const specialProperties: SpecialProperty[] = [];
  if (!craneable) {
    specialProperties.push(SpecialProperty.NonCraneable);
  }

  if (hazardousGoodsStatus !== HazardousGoodsStatus.NotRequested) {
    specialProperties.push(SpecialProperty.Hazardous);
  }

  if (numberOfPlugins > 0) {
    specialProperties.push(SpecialProperty.Plugin);
  }

  return specialProperties;
}
