import { Box, Row, Space, Txt } from "@stenajs-webui/core";
import { PrimaryButton } from "@stenajs-webui/elements";
import { msalInstance } from "../AuthGate";
import { scopes } from "../authConfig";

export const ErrorLoginPrompt = () => (
  <Box justifyContent={"flex-start"}>
    <Txt size={"large"}>Unfortunately we were unable to log you in.</Txt>
    <Space num={3} />
    <Txt size={"large"}>
      If you want access to the Intermodal admin tool please contact Stena IT.
      Your Stena Line credentials are used for login.
    </Txt>
    <Space num={3} />
    <Row justifyContent={"center"}>
      <PrimaryButton
        onClick={() =>
          msalInstance.loginRedirect({
            prompt: "select_account",
            scopes: scopes,
          })
        }
        label={"Go back to Login"}
      />
    </Row>
  </Box>
);
