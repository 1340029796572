import { Box, Txt } from "@stenajs-webui/core";
import { CardBody, PrimaryButton } from "@stenajs-webui/elements";
import { BaseModal } from "@stenajs-webui/modal";
import { useRevalidator } from "react-router-dom";
import { refetchQueries } from "../../../config/graphql/apollo";
import refreshImage from "./refresh.svg";

interface Props {
  onRefresh: () => void;
}

export const RefreshModal = ({ onRefresh }: Props) => {
  const revalidator = useRevalidator();
  const handleRefresh = () => {
    revalidator.revalidate();
    refetchQueries();
    onRefresh();
  };

  return (
    <BaseModal isOpen={true} width="500px">
      <CardBody justifyContent={"center"} alignItems={"center"} gap={3}>
        <img src={refreshImage} width={216} alt="Refresh page icon" />
        <Box gap={2} justifyContent={"center"} alignItems={"center"}>
          <Txt size={"large"} variant={"bold"}>
            Refresh to make sure the data is up to date
          </Txt>
        </Box>
        <PrimaryButton onClick={handleRefresh} label={"Refresh page"} />
      </CardBody>
    </BaseModal>
  );
};
