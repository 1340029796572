import { Box, Indent, Space } from "@stenajs-webui/core";
import {
  ResultListBanner,
  ResultListBannerProps,
} from "@stenajs-webui/elements";
import { FC } from "react";
import { FormIntermodalBookingLegFragment } from "../../../generated";
import { ResultMessageBanner } from "../../common/ResultMessageBanner";
import {
  getBannerInvalidJourneyState,
  getBannerUpdatedState,
  getBannerHazardousGoodsState,
} from "../../common/banner/bannerUtils";
import { ResultMessage } from "../../common/utils/apolloErrorUtils";

interface Props {
  statusCode: string;
  bannerProps: ResultMessage[] | ResultListBannerProps | null;
  bookings: FormIntermodalBookingLegFragment[];
}

export const BookingDetailsBanners: FC<Props> = ({
  bannerProps,
  statusCode,
  bookings,
}) => {
  const bookingUpdatedBannerProps = getBannerUpdatedState(statusCode);
  const invalidJourneyStateProps = getBannerInvalidJourneyState(bookings);
  const hazardousGoodsStateProps = getBannerHazardousGoodsState(bookings);
  if (
    !bookingUpdatedBannerProps &&
    !bannerProps &&
    !invalidJourneyStateProps &&
    !hazardousGoodsStateProps
  ) {
    return null;
  }

  return (
    <Indent num={3}>
      <Space num={3} />
      <Box gap>
        {bookingUpdatedBannerProps && (
          <ResultListBanner {...bookingUpdatedBannerProps} />
        )}
        {invalidJourneyStateProps && (
          <ResultListBanner {...invalidJourneyStateProps} />
        )}
        {hazardousGoodsStateProps && (
          <ResultListBanner {...hazardousGoodsStateProps} />
        )}
        {bannerProps &&
          (Array.isArray(bannerProps) ? (
            <ResultMessageBanner messages={bannerProps} />
          ) : (
            <ResultListBanner {...bannerProps} />
          ))}
      </Box>
    </Indent>
  );
};
