import { resolveIntermodalBookingPlainTextTableCellContent } from "../bookings-table/components/helpers/resolveIntermodalBookingPlainTextTableCellContent";
import { IntermodalBookingTableViewModel } from "../bookings-table/models/IntermodalBookingsTableViewModel";
import { plainTextTableColumns } from "../bookings-table/common/TableColumns";
import { BookingViewModel } from "../bookings-table/models/BookingViewModel";
import { resolveBookingLegPlainTextTableCellContent } from "../bookings-table/components/helpers/resolveBookingLegPlainTextTableCellContent";

function copyHtmlTableToClipboard(table: HTMLTableElement) {
  document.body.appendChild(table);

  try {
    const range = document.createRange();
    const sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();

      range.selectNodeContents(table);
      sel.addRange(range);

      document.execCommand("Copy");
    }
  } finally {
    table.remove();
  }
}

export const formatAndCopyBookingsAsTable = (
  allBookings: IntermodalBookingTableViewModel[]
) => {
  const table = createTableForCopy();

  const tbody = document.createElement("tbody");

  allBookings.forEach((booking) => {
    const tr = document.createElement("tr");
    plainTextTableColumns.forEach((column) => {
      const td = document.createElement("td");
      td.textContent = resolveIntermodalBookingPlainTextTableCellContent(
        column,
        booking
      );

      td.style.width = column.width + "px";
      tr.appendChild(td);
    });
    tbody.appendChild(tr);
  });
  table.appendChild(tbody);

  copyHtmlTableToClipboard(table);
};

export const formatAndCopyBookingAsKeyValueTable = (
  booking: IntermodalBookingTableViewModel
) => {
  const table = document.createElement("table");
  table.style.height = "0px";
  table.style.width = "0px";

  const tbody = document.createElement("tbody");

  plainTextTableColumns.forEach((column) => {
    const tr = document.createElement("tr");
    const th = document.createElement("th");
    th.textContent = column.label;
    th.scope = "row";
    th.style.textAlign = "left";

    tr.appendChild(th);

    const td = document.createElement("td");
    td.textContent = resolveIntermodalBookingPlainTextTableCellContent(
      column,
      booking
    );

    td.style.width = column.width + "px";
    tr.appendChild(td);

    tbody.appendChild(tr);
  });

  table.appendChild(tbody);

  copyHtmlTableToClipboard(table);
};

export const formatAndCopyBookingLegsAsTable = (
  bookings: BookingViewModel[]
) => {
  const table = createTableForCopy();

  const tbody = document.createElement("tbody");

  bookings.forEach((booking) => {
    const tr = document.createElement("tr");
    plainTextTableColumns.forEach((column) => {
      const td = document.createElement("td");
      td.textContent = resolveBookingLegPlainTextTableCellContent(
        column,
        booking
      );

      td.style.width = column.width + "px";
      tr.appendChild(td);
    });
    tbody.appendChild(tr);
  });
  table.appendChild(tbody);

  copyHtmlTableToClipboard(table);
};

const createTableForCopy = () => {
  const table = document.createElement("table");
  table.style.height = "0px";
  table.style.width = "0px";
  const thead = document.createElement("thead");
  const thead_tr = document.createElement("tr");

  plainTextTableColumns.forEach((column) => {
    const th = document.createElement("th");
    th.textContent = column.label;
    th.style.textAlign = "left";
    thead_tr.appendChild(th);
  });
  thead.appendChild(thead_tr);
  table.appendChild(thead);

  return table;
};
