import { Select, SelectProps } from "@stenajs-webui/select";
import { padStart, range } from "lodash";
import { FC } from "react";

interface Props extends SelectProps {
  setTime: (time: string) => void;
  time: string;
}
const createHalfHourOptions = (hour: number) => [
  padStart(`${hour}:00`, 5, "0"),
  padStart(`${hour}:30`, 5, "0"),
];

export const TIME_OPTIONS = range(24)
  .flatMap(createHalfHourOptions)
  .map((v) => ({ label: v, value: v }));

export const TimeSelect: FC<Props> = ({ time, setTime, ...props }) => {
  const selectedTime = TIME_OPTIONS?.find((option) => option.value === time);

  return (
    <Select
      {...props}
      menuPlacement={"auto"}
      menuPosition={"fixed"}
      menuShouldBlockScroll
      menuPortalTarget={document.getElementById("popup-root")}
      options={TIME_OPTIONS}
      value={selectedTime}
      onChange={(option) => {
        if (option) {
          setTime(option.value ?? "00:00");
        }
      }}
    />
  );
};
