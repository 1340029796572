import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  MismatchIndicator,
  useMismatchState,
} from "../../../common/form-fields/MismatchIndicator";
import {
  ConditionallyEditableSelect,
  ConditionallyEditableSelectProps,
  TypedOption,
} from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableSelect";
import {
  IntermodalBookingLegFormModel,
  getIntermodalBookingLegFormValueByIndex,
  setBookingFormValue,
} from "../../redux/formSlice";

interface Props<
  TKey extends keyof IntermodalBookingLegFormModel,
  TData,
  TOption extends TypedOption<IntermodalBookingLegFormModel[TKey], TData>
> extends Omit<
    ConditionallyEditableSelectProps<
      IntermodalBookingLegFormModel[TKey],
      TData,
      TOption
    >,
    "value" | "onValueChange"
  > {
  name: TKey;
  selectedBookingIndex: number | null;
  onValueChange?: (option: TOption) => void;
  className?: string;
}

function formatPartnerConfirmedOptionValue<
  TOption extends { value: unknown; label: string }
>(options: TOption[] | undefined, mismatchValue: unknown): string | undefined {
  const fallbackValue =
    mismatchValue !== undefined ? String(mismatchValue) : undefined;

  return (
    options?.find((o) => o.value === mismatchValue)?.label ?? fallbackValue
  );
}

export function UpdateBookingSelect<
  TKey extends keyof IntermodalBookingLegFormModel,
  TData,
  TOption extends TypedOption<IntermodalBookingLegFormModel[TKey], TData>
>({
  isLoading = false,
  options,
  editable,
  isDisabled = false,
  selectedBookingIndex,
  name,
  onValueChange,
  ...props
}: Props<TKey, TData, TOption>) {
  const value = useSelector((state: RootState) =>
    getIntermodalBookingLegFormValueByIndex(state, selectedBookingIndex, name)
  );
  const mismatchState = useMismatchState(name, selectedBookingIndex);

  const dispatch = useDispatch();

  const hasUpdatedValue = mismatchState != null;

  return (
    <ConditionallyEditableSelect
      {...props}
      editable={editable}
      contentLeft={
        mismatchState ? (
          <MismatchIndicator
            message={
              mismatchState.type === "partner"
                ? formatPartnerConfirmedOptionValue(
                    options,
                    mismatchState.value
                  )
                : undefined
            }
          />
        ) : null
      }
      bold={hasUpdatedValue}
      isDisabled={isDisabled}
      isLoading={isLoading}
      options={options}
      value={value}
      onValueChange={(option) => {
        dispatch(
          setBookingFormValue({
            index: selectedBookingIndex,
            values: { [name]: option.value },
          })
        );
        onValueChange?.(option);
      }}
    />
  );
}
