import { useQuery } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import { Space, useBoolean } from "@stenajs-webui/core";
import {
  ActionMenuItem,
  stenaTodoList,
  stenaTurnOff,
  stenaUserCircle,
} from "@stenajs-webui/elements";
import {
  NavBar,
  NavBarButton,
  NavBarHeading,
  NavBarNotificationButton,
  NavBarUserButton,
} from "@stenajs-webui/panels";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useOnNoMouseMovement } from "../../common/hooks/useOnNoMouseMovement";
import { ENABLE_DEPARTURES_VIEW } from "../../config/featureFlags";
import {
  GetTodosDocument,
  GetTodosQuery,
  GetTodosQueryVariables,
} from "../../generated";
import logo from "../../logo.svg";
import { NotificationCenterDrawer } from "../notification-center/components/NotificationCenterDrawer";
import { EnvironmentWarning } from "./EnvironmentWarning";
import { RefreshModal } from "./refresh/RefreshModal";

const TEN_MINUTES_IN_MS: number = 10 * 60 * 1000;
const FIVE_MINUTES_IN_MS: number = 5 * 60 * 1000;

export const TopMenu = () => {
  const msal = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, open, close] = useBoolean(false);

  const [userIsActive, activate, deactivate] = useBoolean(true);

  useOnNoMouseMovement(deactivate, TEN_MINUTES_IN_MS);

  const { data } = useQuery<GetTodosQuery, GetTodosQueryVariables>(
    GetTodosDocument,
    {
      pollInterval: userIsActive ? FIVE_MINUTES_IN_MS : undefined,
    }
  );

  const todos = data?.todoResult?.toDoIntermodalBookings ?? [];
  const messages = data?.todoResult?.messages ?? [];

  const count = todos.length;

  const username = msal.accounts[0].name ?? msal.accounts[0].username;
  const bookingsUrl = "/bookings";
  const departuresUrl = "/departures";

  return (
    <>
      <NavBar
        left={<img alt="logo" src={logo} height={"52px"} />}
        center={<EnvironmentWarning />}
        right={
          <>
            <NavBarNotificationButton
              className="t_todos"
              count={count}
              unread={count > 0}
              icon={stenaTodoList}
              iconClassName={"animationDisabled"}
              onClick={() => {
                open();
                activate();
              }}
            />
            <Space />
            <NavBarUserButton
              className="t_user"
              icon={stenaUserCircle}
              username={username}
              renderItems={() => (
                <ActionMenuItem
                  onClick={() => msal.instance.logoutRedirect()}
                  leftIcon={stenaTurnOff}
                  label={"Log out"}
                />
              )}
            />
          </>
        }
      >
        <NavBarHeading>Intermodal</NavBarHeading>
        <NavBarButton
          className="t_bookings"
          label={"Bookings"}
          onClick={() => navigate(bookingsUrl)}
          selected={matchPath({ path: bookingsUrl }, location.pathname) != null}
        />
        {ENABLE_DEPARTURES_VIEW && (
          <NavBarButton
            className="t_departures"
            label={"Departures"}
            onClick={() => navigate(departuresUrl)}
            selected={
              matchPath({ path: departuresUrl }, location.pathname) != null
            }
          />
        )}
      </NavBar>
      <NotificationCenterDrawer
        isDrawerOpen={isOpen}
        closeDrawer={close}
        todos={todos}
        messages={messages}
      />
      {!userIsActive && <RefreshModal onRefresh={activate} />}
    </>
  );
};
