import styled from "@emotion/styled";
import { Box } from "@stenajs-webui/core";
import { FC } from "react";
import { LoadingPanel } from "@stenajs-webui/panels";

const Overlay = styled(Box)`
  position: absolute;
  inset: 0;
  z-index: 1;
  margin: 0 auto;
`;

const StyledUpdating = styled("div")`
  padding-top: 200px;
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  z-index: 10;
  will-change: transform;
`;

export const AreaLoadingModal: FC<{ variant?: "light" | "dark" }> = ({
  variant = "light",
}) => (
  <Overlay
    background={
      variant === "light"
        ? "rgba(255, 255, 255, 0.8)"
        : "var(--swui-overlay-bg-color)"
    }
  >
    <StyledUpdating>
      <LoadingPanel />
    </StyledUpdating>
  </Overlay>
);
