import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { LoginPage } from "./LoginPage";
import { useSelector } from "react-redux";
import { RootState } from "../../config/redux/store";

interface Props {
  children?: React.ReactNode;
}

export const msalInstance = new PublicClientApplication(msalConfig);

export const AuthMsalGate: React.FC<Props> = ({ children }) => {
  const isAuthError = useSelector((state: RootState) => state.user.authError);

  if (isAuthError) {
    return <LoginPage hasError />;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};
