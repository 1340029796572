import { Box, Column, SeparatorLine } from "@stenajs-webui/core";
import { RouteLeg, Spinner, stenaSwitch } from "@stenajs-webui/elements";
import { RouteLegProps } from "@stenajs-webui/elements/dist/components/ui/route-leg/RouteLeg";
import { cssColor } from "@stenajs-webui/theme";
import { RouteLegInfo } from "../../../common/types/RouteLegInfo";
import { ColumnHeader } from "../../booking-details/components/columns/column-details/ColumnHeader";
import { SelectableBox } from "../../booking-details/components/columns/column-details/SelectableBox";
import { TimeGap } from "../../booking-details/components/columns/column-details/TimeGap";
import { getOrdinal } from "../../booking-details/utils/ordinalUtils";
import { BOOKING_TABLE_BORDER } from "../../bookings/bookings-table/components/BookingTableRow";
import {
  formatDepartureDateTime,
  getTimeGapFromSeconds,
} from "../utils/dateUtils";
import { EmptyConnectionsMedal } from "./EmptyConnectionsMedal";
import { getRouteLegOriginalDateTime } from "../utils/connectionsUtils";
import { parseISO } from "date-fns";

interface Props {
  background: string;
  isLoading?: boolean;
  routeLegInfos: RouteLegInfo[];
  selectedBookingIndex?: number | null;
  setSelectedBookingIndex?: (index: number | null) => void;
  intermodalDepartureArrival: Pick<
    RouteLegProps,
    "departure" | "arrival"
  > | null;
}

export const ConnectionsColumn: React.FC<Props> = ({
  routeLegInfos,
  background,
  isLoading = false,
  selectedBookingIndex,
  setSelectedBookingIndex,
  intermodalDepartureArrival,
}) => {
  return (
    <Column
      flex={"1 0 264px"}
      borderRight={BOOKING_TABLE_BORDER}
      background={
        !intermodalDepartureArrival
          ? cssColor("--lhds-color-blue-50")
          : background
      }
    >
      <ColumnHeader icon={stenaSwitch} heading={"Connections"} />
      <SeparatorLine />
      <Column position={"relative"} flex={"1 0 0px"}>
        {isLoading && (
          <Box
            position={"absolute"}
            background={"#fffb"}
            style={{ inset: 0, zIndex: 1 }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner size={"small"} />
          </Box>
        )}
        {!intermodalDepartureArrival ? (
          <EmptyConnectionsMedal />
        ) : (
          <>
            <SelectableBox
              className="t_booking_connection"
              selectable={Boolean(setSelectedBookingIndex)}
              selected={
                setSelectedBookingIndex ? selectedBookingIndex == null : false
              }
              onSelected={() => setSelectedBookingIndex?.(null)}
            >
              <Box spacing={3} indent={3} style={{ textAlign: "left" }}>
                <RouteLeg
                  selected={
                    setSelectedBookingIndex
                      ? selectedBookingIndex == null
                      : false
                  }
                  variant={"rail"}
                  size={"relaxed"}
                  {...intermodalDepartureArrival}
                />
              </Box>
            </SelectableBox>
            <SeparatorLine />
            {routeLegInfos.map((routeLegInfo, index) => (
              <Box key={routeLegInfo.sailingId}>
                {index !== 0 && (
                  <TimeGap
                    time={getTimeGapFromSeconds(
                      routeLegInfo.timeFromPreviousArrivalInSeconds
                    )}
                    valid={routeLegInfo.departureTimeIsValid}
                  />
                )}
                <SelectableBox
                  className="t_booking_leg_connection"
                  selectable={Boolean(setSelectedBookingIndex)}
                  selected={
                    setSelectedBookingIndex
                      ? selectedBookingIndex === index
                      : true
                  }
                  onSelected={() => setSelectedBookingIndex?.(index)}
                >
                  <Box
                    spacing={3}
                    indent={3}
                    style={{ textAlign: "left", width: "max-content" }}
                  >
                    <RouteLeg
                      label={getOrdinal(index + 1) + " ROUTE"}
                      selected={
                        setSelectedBookingIndex
                          ? selectedBookingIndex === index
                          : false
                      }
                      variant={routeLegInfo.isTrain ? "rail" : "ship"}
                      size={"compact"}
                      departure={{
                        location: routeLegInfo.route.departureLocation,
                        dateTime: formatDepartureDateTime(
                          parseISO(routeLegInfo.publicDeparture)
                        ),
                        originalDateTime: getRouteLegOriginalDateTime(
                          routeLegInfo.scheduledDeparture,
                          routeLegInfo.publicDeparture
                        ),
                      }}
                      arrival={{
                        location: routeLegInfo.route.arrivalLocation,
                        dateTime: formatDepartureDateTime(
                          parseISO(routeLegInfo.publicArrival)
                        ),
                        originalDateTime: getRouteLegOriginalDateTime(
                          routeLegInfo.scheduledArrival,
                          routeLegInfo.publicArrival
                        ),
                      }}
                    />
                  </Box>
                </SelectableBox>
              </Box>
            ))}
          </>
        )}
      </Column>
    </Column>
  );
};
