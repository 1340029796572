import {
  ActionMenuItem,
  ActionMenuSeparator,
  stenaArrowLeft,
  stenaBusinessSignature,
  stenaCheckCircle,
  stenaCopy,
  stenaHistory,
  stenaIntermodal,
  stenaMail,
  stenaPrint,
  stenaTimesCircle,
  stenaTodoDone,
} from "@stenajs-webui/elements";
import { ActionMenuSecondaryButton } from "@stenajs-webui/panels";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ENABLE_BOOKING_DETAILS_CANCEL_BOOKING,
  ENABLE_BOOKING_DETAILS_CHECK_IN_LEG,
  ENABLE_BOOKING_DETAILS_COPY_TO_CLIPBOARD,
  ENABLE_BOOKING_DETAILS_FIND_LATER_DEPARTURE,
  ENABLE_BOOKING_DETAILS_HISTORY,
  ENABLE_BOOKING_DETAILS_SEND_MAIL,
} from "../../../config/featureFlags";
import {
  FormIntermodalBookingFragment,
  IntermodalJourneyLeg,
  MarkPartnerBookingAsCompletedLegInput,
} from "../../../generated";
import { formatAndCopyBookingAsKeyValueTable } from "../../bookings/utils/copyBookingsTable";
import { transformIntermodalBookingToTableViewModel } from "../../bookings/utils/intermodalTableViewModelUtils";
import {
  setPartnerBookingConfirmStatuses,
  setPartnerBookingUnconfirmStatuses,
} from "../redux/formSlice";
import {
  isBookingLegAbleToCheckIn,
  isBookingLegCompletable,
  isBookingLegConfirmable,
  isBookingLegUnconfirmable,
} from "../utils/bookingActionsUtils";
import { useSelectedBookingIndex } from "./SelectedBookingIndexContext";
import { CancelBookingsModal } from "./modals/CancelBookingsModal";
import { CheckInBookingLegModal } from "./modals/CheckInBookingLegModal";
import {
  ConfirmBookingsModal,
  UnconfirmBookingsModal,
} from "./modals/ConfirmBookingsModal";
import { MarkBookingsAsCompletedModal } from "./modals/MarkBookingsAsCompletedModal";
import { NewJourneyModal } from "./modals/NewJourneyModal";

type ModalAction =
  | "mark-as-completed"
  | "confirm"
  | "unconfirm"
  | "cancel"
  | "new-journey"
  | "check-in";

interface BookingActionProps {
  intermodalBooking: FormIntermodalBookingFragment;
  isLoadingMarkAsCompleted: boolean;
  isLoadingUpdate: boolean;
  isLoadingCancel: boolean;
  isLoadingCheckIn: boolean;
  isLoadingSetJourney: boolean;
  onRequestSave: () => Promise<void>;
  onCancelIntermodalBooking: () => Promise<void>;
  onCheckInIntermodalBookingLeg: (bookingLegId: string) => Promise<void>;
  onMarkPartnerBookingsAsCompleted: (
    legs: MarkPartnerBookingAsCompletedLegInput[]
  ) => Promise<void>;
  onSetIntermodalJourney: (legs: IntermodalJourneyLeg[]) => Promise<void>;
}

export const BookingActions: FC<BookingActionProps> = ({
  intermodalBooking,
  onCancelIntermodalBooking,
  onCheckInIntermodalBookingLeg,
  onMarkPartnerBookingsAsCompleted,
  onSetIntermodalJourney,
  isLoadingCancel,
  isLoadingUpdate,
  isLoadingCheckIn,
  isLoadingMarkAsCompleted,
  isLoadingSetJourney,
  onRequestSave,
}) => {
  const dispatch = useDispatch();
  const selectedBookingIndex = useSelectedBookingIndex();
  const [modalAction, setModalAction] = useState<ModalAction | null>(null);
  const [initialBookingsToSelect, setBookingToSelect] = useState<boolean[]>([]);
  const [singleBookingLegIndex, setSingleBookingLegIndex] = useState<number>(
    selectedBookingIndex ?? 0
  );

  const handleOpenMarkAsCompletedModal = () => {
    if (selectedBookingIndex != null) {
      setBookingToSelect(
        intermodalBooking.bookings.map(
          (booking, index) =>
            isBookingLegCompletable(booking) && index === selectedBookingIndex
        )
      );
    } else {
      setBookingToSelect(
        intermodalBooking.bookings.map(isBookingLegCompletable)
      );
    }

    setModalAction("mark-as-completed");
  };

  const handleOpenBookingConfirmModal = () => {
    setBookingToSelect(
      intermodalBooking.bookings.map(
        (booking, index) =>
          isBookingLegConfirmable(booking) &&
          (index === selectedBookingIndex || selectedBookingIndex === null)
      )
    );

    setModalAction("confirm");
  };

  const handleOpenBookingUnconfirmModal = () => {
    setBookingToSelect(
      intermodalBooking.bookings.map(
        (booking, index) =>
          isBookingLegUnconfirmable(booking) &&
          (index === selectedBookingIndex || selectedBookingIndex === null)
      )
    );

    setModalAction("unconfirm");
  };

  const handleOpenNewJourneyModal = () => {
    const firstBookingIndexToFindLaterDeparture =
      intermodalBooking.bookings.findIndex((b) => b.isEditable);

    if (firstBookingIndexToFindLaterDeparture != null) {
      setSingleBookingLegIndex(firstBookingIndexToFindLaterDeparture);
      setModalAction("new-journey");
    }
  };

  const handleOpenBookingCheckInModal = () => {
    const firstBookingIndexToCheckIn = intermodalBooking.bookings.findIndex(
      isBookingLegAbleToCheckIn
    );

    if (firstBookingIndexToCheckIn != null) {
      setSingleBookingLegIndex(firstBookingIndexToCheckIn);
      setModalAction("check-in");
    }
  };

  const handleMarkBookingsAsCompleted = async (indexesToComplete: number[]) => {
    await onMarkPartnerBookingsAsCompleted(
      indexesToComplete.map((i) => {
        const b = intermodalBooking.bookings[i];

        return {
          id: b.id,
          version: b.version,
        };
      })
    );
    setModalAction(null);
  };

  const handleConfirmBooking = async (indexesToConfirm: number[]) => {
    dispatch(setPartnerBookingConfirmStatuses({ indexes: indexesToConfirm }));
    await onRequestSave();
    setModalAction(null);
  };

  const handleUnconfirmBooking = async (indexesToUnconfirm: number[]) => {
    dispatch(
      setPartnerBookingUnconfirmStatuses({ indexes: indexesToUnconfirm })
    );
    await onRequestSave();
    setModalAction(null);
  };

  const handleCancelIntermodalBooking = async () => {
    await onCancelIntermodalBooking();
    setModalAction(null);
  };

  const handleCheckInIntermodalBookingLeg = async (selectedIndex: number) => {
    await onCheckInIntermodalBookingLeg(
      intermodalBooking.bookings[selectedIndex].id
    );
    setModalAction(null);
  };

  const handleSetLaterDeparture = async (legs: IntermodalJourneyLeg[]) => {
    await onSetIntermodalJourney(legs);
    setModalAction(null);
  };

  const hasCompletableChanges = intermodalBooking.bookings.some(
    isBookingLegCompletable
  );

  return (
    <>
      <MarkBookingsAsCompletedModal
        bookings={intermodalBooking.bookings}
        isOpen={modalAction === "mark-as-completed"}
        loading={isLoadingMarkAsCompleted}
        onRequestClose={() => setModalAction(null)}
        onSubmit={handleMarkBookingsAsCompleted}
        initialState={initialBookingsToSelect}
      />
      <ConfirmBookingsModal
        bookings={intermodalBooking.bookings}
        isOpen={modalAction === "confirm"}
        loading={isLoadingUpdate}
        onRequestClose={() => setModalAction(null)}
        onSubmit={handleConfirmBooking}
        initialState={initialBookingsToSelect}
      />
      <UnconfirmBookingsModal
        bookings={intermodalBooking.bookings}
        isOpen={modalAction === "unconfirm"}
        loading={isLoadingUpdate}
        onRequestClose={() => setModalAction(null)}
        onSubmit={handleUnconfirmBooking}
        initialState={initialBookingsToSelect}
      />
      <CancelBookingsModal
        bookings={intermodalBooking.bookings}
        isOpen={modalAction === "cancel"}
        loading={isLoadingCancel}
        onRequestClose={() => setModalAction(null)}
        onSubmit={handleCancelIntermodalBooking}
      />
      <CheckInBookingLegModal
        initialSelected={singleBookingLegIndex}
        bookings={intermodalBooking.bookings}
        isOpen={modalAction === "check-in"}
        loading={isLoadingCheckIn}
        onRequestClose={() => setModalAction(null)}
        onSubmit={handleCheckInIntermodalBookingLeg}
      />
      {modalAction === "new-journey" && (
        <NewJourneyModal
          initialSelectedIndex={singleBookingLegIndex}
          intermodalBooking={intermodalBooking}
          loadingSetJourney={isLoadingSetJourney}
          onRequestClose={() => setModalAction(null)}
          onSubmitSetJourney={handleSetLaterDeparture}
        />
      )}

      <ActionMenuSecondaryButton
        label={"Booking actions"}
        renderItems={() => (
          <>
            {hasCompletableChanges && (
              <>
                <ActionMenuItem
                  label={"Mark to-do completed"}
                  leftIcon={stenaTodoDone}
                  onClick={handleOpenMarkAsCompletedModal}
                />
                <ActionMenuSeparator />
              </>
            )}
            <ActionMenuItem
              label={"Confirm booking"}
              disabled={
                !intermodalBooking.bookings.some(isBookingLegConfirmable)
              }
              leftIcon={stenaBusinessSignature}
              onClick={handleOpenBookingConfirmModal}
            />
            <ActionMenuItem
              label={"Set to in progress / unconfirmed"}
              leftIcon={stenaArrowLeft}
              disabled={
                !intermodalBooking.bookings.some(isBookingLegUnconfirmable)
              }
              onClick={handleOpenBookingUnconfirmModal}
            />
            {ENABLE_BOOKING_DETAILS_CHECK_IN_LEG && (
              <ActionMenuItem
                label={"Check-in booking"}
                leftIcon={stenaCheckCircle}
                disabled={
                  !intermodalBooking.bookings.some(isBookingLegAbleToCheckIn)
                }
                onClick={handleOpenBookingCheckInModal}
              />
            )}
            <ActionMenuSeparator />
            <ActionMenuItem
              label={"Print booking"}
              leftIcon={stenaPrint}
              onClick={() => {
                setTimeout(() => {
                  window.print();
                }, 10);
              }}
            />
            {ENABLE_BOOKING_DETAILS_COPY_TO_CLIPBOARD && (
              <ActionMenuItem
                label={"Copy to clipboard"}
                leftIcon={stenaCopy}
                onClick={() => {
                  formatAndCopyBookingAsKeyValueTable(
                    transformIntermodalBookingToTableViewModel(
                      intermodalBooking
                    )
                  );
                }}
              />
            )}
            {ENABLE_BOOKING_DETAILS_SEND_MAIL && (
              <>
                <ActionMenuSeparator />
                <ActionMenuItem label={"Send mail"} leftIcon={stenaMail} />
              </>
            )}
            {ENABLE_BOOKING_DETAILS_FIND_LATER_DEPARTURE && (
              <>
                <ActionMenuSeparator />
                <ActionMenuItem
                  label={"Find later departure"}
                  leftIcon={stenaIntermodal}
                  onClick={handleOpenNewJourneyModal}
                  disabled={
                    !intermodalBooking.bookings.some(
                      (booking) => booking.isEditable
                    )
                  }
                />
              </>
            )}
            {ENABLE_BOOKING_DETAILS_HISTORY && (
              <>
                <ActionMenuSeparator />
                <ActionMenuItem label={"History"} leftIcon={stenaHistory} />
              </>
            )}
            {ENABLE_BOOKING_DETAILS_CANCEL_BOOKING && (
              <>
                <ActionMenuSeparator />
                <ActionMenuItem
                  label={"Cancel booking"}
                  variant={intermodalBooking.isReadOnly ? "standard" : "danger"}
                  leftIcon={stenaTimesCircle}
                  onClick={() => setModalAction("cancel")}
                  disabled={intermodalBooking.isReadOnly}
                />
              </>
            )}
          </>
        )}
      />
    </>
  );
};
