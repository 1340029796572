import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import { AuthMsalGate } from "./common/auth/AuthGate";
import { client } from "./config/graphql/apollo";
import { store } from "./config/redux/store";
import {
  BookingDetailsPage,
  loader,
} from "./use-case/booking-details/BookingDetailsPage";
import { BookingsPage } from "./use-case/bookings/BookingsPage";
import { TopMenu } from "./use-case/top-menu/TopMenu";
import { DeparturesPage } from "./use-case/departures/DeparturesPage";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const Root = () => (
  <QueryParamProvider adapter={ReactRouter6Adapter}>
    <TopMenu />
    <Outlet />
  </QueryParamProvider>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={"/*"} element={<Root />}>
      <Route path={"bookings"} element={<BookingsPage />} />
      <Route path={"departures"} element={<DeparturesPage />} />
      <Route
        path={"bookings/:bookingId"}
        element={<BookingDetailsPage />}
        loader={loader}
      />
      <Route path={"*"} element={<Navigate to={"/bookings"} replace />} />
    </Route>
  )
);

export const App = () => (
  <Provider store={store}>
    <AuthMsalGate>
      <ApolloProvider client={client}>
        <RouterProvider router={router} />
      </ApolloProvider>
    </AuthMsalGate>
  </Provider>
);
