import { first, last } from "lodash";
import { ConnectionsColumn } from "../../../common/bookings/ConnectionsColumn";
import {
  transformIntermodalBookingToRouteLegProps,
  transformLegToRouteLegInfo,
} from "../../../common/utils/connectionsUtils";
import { useIntermodalJourneyFetcher } from "../../hooks/useIntermodalJourneyFetcher";

interface Props {}

export const ConnectionsCreateColumn: React.FC<Props> = () => {
  const { legs, loading } = useIntermodalJourneyFetcher();
  const sailings = legs?.map((l) => l.sailing) ?? [];
  const firstSailing = first(sailings);
  const lastSailing = last(sailings);
  const routeLegInfos =
    legs?.map((leg) => transformLegToRouteLegInfo(leg)) ?? [];

  const intermodalDepartureArrival =
    firstSailing && lastSailing
      ? transformIntermodalBookingToRouteLegProps(firstSailing, lastSailing)
      : null;

  return (
    <ConnectionsColumn
      isLoading={loading}
      background={"white"}
      routeLegInfos={routeLegInfos}
      intermodalDepartureArrival={intermodalDepartureArrival}
    />
  );
};
