import { IntermodalBookingTableViewModel } from "../models/IntermodalBookingsTableViewModel";
import { stringifySpecialProperties } from "../models/SpecialProperty";
import { SortState, SortStateKey } from "../types/SortState";

export const isSortingActive = (property: SortStateKey, sortState: SortState) =>
  sortState.sort === property;

export const getSortingArrow = (
  property: SortStateKey,
  sortState: SortState
) => {
  return isSortingActive(property, sortState)
    ? sortState.asc
      ? "down"
      : "up"
    : undefined;
};

export const getNewSortStateByIntermodalBookingProperty = (
  property: SortStateKey,
  { asc, sort }: SortState
): SortState => {
  return {
    sort: property,
    asc: sort === property ? !asc : true,
  };
};

export const resolveBookingSortProperty = (
  booking: IntermodalBookingTableViewModel,
  sortBy: SortStateKey
): string => {
  switch (sortBy) {
    case "bookings":
      return "";
    case "status":
      return booking.status.code;
    case "specialProperties":
      return booking.specialProperties
        ? stringifySpecialProperties(booking.specialProperties)
        : "";
    case "customerRef":
    case "departure":
    case "arrival":
    case "id":
    case "iluCode":
    case "loadingNote":
    case "numberOfPlugins":
    case "routeName":
    case "vehicleReg":
    case "vehicleType":
    case "firstSailingId":
    case "firstPartnerBookingNumber":
    case "secondPartnerBookingNumber":
    default:
      return booking[sortBy] ?? "";
  }
};
