import { Column, Heading, Row, Space, Txt } from "@stenajs-webui/core";
import { Tag } from "@stenajs-webui/elements";
import { Checkbox } from "@stenajs-webui/forms";
import { parseISO } from "date-fns";
import { FC } from "react";
import { Sailing } from "../../../../generated";
import { formatDateTimeISO } from "../../../common/utils/dateUtils";

export interface SetLaterDepartureOption {
  sailing: Sailing;
  legNumber: number;
  label: string;
  isCurrent: boolean;
  isDisabled: boolean;
}

interface Props {
  selectedLegs: boolean[];
  onClickOption: (index: number) => void;
  options: SetLaterDepartureOption[];
}

const formatDate = (date: string) => formatDateTimeISO(parseISO(date));

export const SetLaterDeparture: FC<Props> = ({
  selectedLegs,
  onClickOption,
  options,
}) => (
  <>
    {options.map((option, index) => (
      <Column key={option.sailing.id}>
        <Row gap={2} alignItems={"center"} justifyContent={"space-between"}>
          <Row gap={2}>
            <Checkbox
              disabled={option.isDisabled}
              value={selectedLegs[index]}
              onClick={() => {
                onClickOption(index);
              }}
            />
            <Heading variant="h4">{option.label}</Heading>
          </Row>
          {option.isCurrent ? (
            <Tag label="Current" />
          ) : (
            <Tag variant="success" label="New" />
          )}
        </Row>
        <Space />
        <Row gap>
          <Column width="32px" />
          <Column>
            <Row>
              <Txt>dep.</Txt>
            </Row>
            <Row>
              <Txt>arr.</Txt>
            </Row>
          </Column>
          <Column>
            <Row>
              <Txt>{formatDate(option.sailing.scheduledDeparture)}</Txt>
            </Row>
            <Row>
              <Txt>{formatDate(option.sailing.scheduledArrival)}</Txt>
            </Row>
          </Column>
        </Row>
        <Space />
      </Column>
    ))}
  </>
);
