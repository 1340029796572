import { uniq } from "lodash";

export enum SpecialProperty {
  Hazardous = "Hazardous",
  NonCraneable = "NonCraneable",
  Plugin = "Plugin",
}

export function stringifySpecialProperties(
  specialProperties: SpecialProperty[]
) {
  return uniq(specialProperties)
    .map((sp) => stringifySpecialProperty(sp))
    .join(", ");
}

export function stringifySpecialProperty(v: SpecialProperty) {
  switch (v) {
    case SpecialProperty.Hazardous:
      return "Hazardous goods";
    case SpecialProperty.NonCraneable:
      return "Non-craneable";
    case SpecialProperty.Plugin:
      return "Plugin";
  }
}
