import { PickByValue } from "@stenajs-webui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  getIntermodalBookingFormValueByIndex,
  IntermodalBookingFormModel,
  setIntermodalBookingFormValue,
} from "../../redux/formSlice";
import { ConditionallyEditableTextInput } from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableTextInput";
import { TextInputProps } from "@stenajs-webui/forms";

interface Props
  extends Omit<TextInputProps, "name" | "value" | "onValueChange"> {
  editable: boolean;
  showCopyButton?: boolean;
  name: keyof PickByValue<IntermodalBookingFormModel, string>;
}

export const UpdateIntermodalBookingTextInput: React.FC<Props> = ({
  editable,
  showCopyButton = false,
  name,
  ...props
}) => {
  const value = useSelector((state: RootState) =>
    getIntermodalBookingFormValueByIndex(state, name)
  );
  const dispatch = useDispatch();

  return (
    <ConditionallyEditableTextInput
      {...props}
      editable={editable}
      showCopyButton={showCopyButton}
      value={value ?? ""}
      onValueChange={(value) =>
        dispatch(
          setIntermodalBookingFormValue({
            values: { [name]: value },
          })
        )
      }
    />
  );
};
