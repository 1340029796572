import { Box, Row } from "@stenajs-webui/core";
import { stenaSailingTrailer } from "@stenajs-webui/elements";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";

import { Label } from "../../../../common/common-ui/Label";
import {
  formatNumericalDecimalString,
  formatNumericalString,
  formatRegistrationNumberString,
} from "../../../../common/utils/stringUtils";
import { GetVehicleTypesQuery } from "../../../../generated";
import {
  useCraneableOptions,
  useCraneableStatusOptions,
} from "../../../../services/hooks/useCraneableOptions";
import { useVehicleTypeOptions } from "../../../../services/hooks/useVehicleTypeOptions";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import {
  MAX_LENGTH_EMPTY_VEHICLE_WEIGHT,
  MAX_LENGTH_HEIGHT,
  MAX_LENGTH_ILU_CODE,
  MAX_LENGTH_LENGTH,
  MAX_LENGTH_VEHICLE_REG,
  MAX_LENGTH_WIDTH,
} from "../../../common/bookings/fieldLengths";
import { useCreateBookingFormField } from "../../hooks/useCreateBookingFormField";
import {
  getIntermodalCreateFormField,
  setCreateIntermodalFormValue,
} from "../../redux/formSlice";
import { CreateBookingSelect } from "../form-fields/CreateBookingSelect";
import { CreateBookingTextInput } from "../form-fields/CreateBookingTextInput";

interface Props {}

export const VehicleColumn: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const onVehicleTypeChanged = (option: typeof vehicleTypeOptions[number]) => {
    const { defaultHeight, defaultLength, defaultWeight, defaultWidth } =
      option.data;
    dispatch(
      setCreateIntermodalFormValue({
        values: {
          emptyVehicleWeight: defaultWeight.toString(),
          vehicleWidth: defaultWidth.toString(),
          vehicleHeight: defaultHeight.toString(),
          vehicleLength: defaultLength.toString(),
        },
      })
    );
  };

  const craneable = useCreateBookingFormField("craneable");

  const vehicleTypeCode = useSelector((state: RootState) =>
    getIntermodalCreateFormField(state, "vehicleTypeCode")
  );
  const { loading, vehicleTypeOptions } = useVehicleTypeOptions(
    (query: GetVehicleTypesQuery) => {
      const selectedVehicleType = query.vehicleTypes.find(
        (vehicleType) => vehicleType.id === vehicleTypeCode
      );
      if (selectedVehicleType) {
        dispatch(
          setCreateIntermodalFormValue({
            values: {
              emptyVehicleWeight: selectedVehicleType.defaultWeight.toString(),
              vehicleWidth: selectedVehicleType.defaultWidth.toString(),
              vehicleHeight: selectedVehicleType.defaultHeight.toString(),
              vehicleLength: selectedVehicleType.defaultLength.toString(),
            },
          })
        );
      }
    }
  );
  const craneableOptions = useCraneableOptions();
  const craneableStatusOptions = useCraneableStatusOptions();

  const onCraneableChanged = (option: typeof craneableOptions[number]) => {
    dispatch(
      setCreateIntermodalFormValue({
        values: { craneableStatus: option.value ? null : "R" },
      })
    );
  };

  return (
    <BookingDetailsColumn
      heading="Vehicle"
      icon={stenaSailingTrailer}
      borderRight
      gap={2}
    >
      <Label text="Vehicle registration">
        <CreateBookingTextInput
          className="t_vehicle_reg"
          showCopyButton
          name="vehicleRegistration"
          maxLength={MAX_LENGTH_VEHICLE_REG}
          maskInput={formatRegistrationNumberString}
        />
      </Label>
      <Label text="ILU code">
        <CreateBookingTextInput
          className="t_ilu_code"
          showCopyButton
          name="iluCode"
          maxLength={MAX_LENGTH_ILU_CODE}
          maskInput={formatRegistrationNumberString}
        />
      </Label>
      <Label text="Vehicle type">
        <CreateBookingSelect
          className="t_vehicle_type"
          name="vehicleTypeCode"
          isLoading={loading}
          options={vehicleTypeOptions}
          onValueChange={onVehicleTypeChanged}
        />
      </Label>
      <Row gap={2}>
        <Box flex={1}>
          <Label text="Craneable">
            <CreateBookingSelect
              className="t_craneable"
              name="craneable"
              isLoading={loading}
              options={craneableOptions}
              onValueChange={onCraneableChanged}
            />
          </Label>
        </Box>
        {!craneable && (
          <Box flex={1}>
            <Label text="Status">
              <CreateBookingSelect
                className="t_craneable_status"
                name="craneableStatus"
                isLoading={loading}
                options={craneableStatusOptions}
              />
            </Label>
          </Box>
        )}
      </Row>
      <Label text="Empty vehicle weight (kg)">
        <CreateBookingTextInput
          className="t_weight"
          showCopyButton
          name="emptyVehicleWeight"
          maxLength={MAX_LENGTH_EMPTY_VEHICLE_WEIGHT}
          maskInput={formatNumericalString}
        />
      </Label>
      <Row gap={2}>
        <Label text="Length (m)">
          <CreateBookingTextInput
            className="t_length"
            name="vehicleLength"
            maxLength={MAX_LENGTH_LENGTH}
            maskInput={formatNumericalDecimalString}
          />
        </Label>
        <Label text="Width (m)">
          <CreateBookingTextInput
            className="t_width"
            name="vehicleWidth"
            maxLength={MAX_LENGTH_WIDTH}
            maskInput={formatNumericalDecimalString}
          />
        </Label>
        <Label text="Height (m)">
          <CreateBookingTextInput
            className="t_height"
            name="vehicleHeight"
            maxLength={MAX_LENGTH_HEIGHT}
            maskInput={formatNumericalDecimalString}
          />
        </Label>
      </Row>
    </BookingDetailsColumn>
  );
};
