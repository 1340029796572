import { PickByValue } from "@stenajs-webui/core";
import { TextInputProps } from "@stenajs-webui/forms";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import { ConditionallyEditableTextInput } from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableTextInput";
import {
  CreateIntermodalBookingFormModel,
  getIntermodalCreateFormField,
  setCreateIntermodalFormValue,
} from "../../redux/formSlice";

interface Props
  extends Omit<TextInputProps, "name" | "value" | "onValueChange"> {
  showCopyButton?: boolean;
  name: keyof PickByValue<CreateIntermodalBookingFormModel, string>;
  maskInput?: (value: string) => string;
}

export const CreateBookingTextInput: React.FC<Props> = ({
  name,
  showCopyButton,
  maskInput = undefined,
  ...props
}) => {
  const value = useSelector((state: RootState) =>
    getIntermodalCreateFormField(state, name)
  );
  const dispatch = useDispatch();

  return (
    <ConditionallyEditableTextInput
      {...props}
      maskInput={maskInput}
      editable
      showCopyButton={showCopyButton}
      value={value ?? ""}
      onValueChange={(value) =>
        dispatch(
          setCreateIntermodalFormValue({
            values: { [name]: value },
          })
        )
      }
    />
  );
};
