import { useQuery } from "@apollo/client";
import { Select } from "@stenajs-webui/select";
import { FC, useMemo } from "react";
import { routeSelectStyle } from "../../common/styles/routeSelectStyle";
import {
  GetAllTrainRoutesDocument,
  GetAllTrainRoutesQuery,
  GetAllTrainRoutesQueryVariables,
} from "../../generated";

interface Props {
  onChange: (value: string) => void;
}

export const RouteSelect: FC<Props> = ({ onChange }) => {
  const { loading: loadingRoutes, data } = useQuery<
    GetAllTrainRoutesQuery,
    GetAllTrainRoutesQueryVariables
  >(GetAllTrainRoutesDocument);

  const routeOptions = useMemo(
    () =>
      data?.allTrainRoutes?.map((route) => ({
        value: route.id,
        label: route.description,
      })) ?? [],
    [data]
  );

  return (
    <Select
      className="t_route_select"
      styles={routeSelectStyle}
      isLoading={loadingRoutes}
      options={routeOptions}
      onChange={(option) => onChange(option?.value ?? "")}
    />
  );
};
