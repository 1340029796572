import { some } from "lodash";
import { getSelectedBookingLegsFromIntermodalBookings } from "../../../../common/utils/intermodalBookingutils";
import { isBookingViewModelAbleToCheckIn } from "../../../booking-details/utils/bookingActionsUtils";
import { IntermodalBookingTableViewModel } from "../models/IntermodalBookingsTableViewModel";

export const isIdSelected = (id: string, selectedIds: string[]) =>
  selectedIds.includes(id);

export const isIntermodalBookingSelected = (
  intermodalBooking: IntermodalBookingTableViewModel,
  selectedBookingIds: string[]
) =>
  intermodalBooking.bookings.length > 0 &&
  intermodalBooking.bookings.every((booking) =>
    selectedBookingIds.includes(booking.id)
  );

export const isIntermodalBookingIndeterminatelySelected = (
  intermodalBooking: IntermodalBookingTableViewModel,
  selectedBookingIds: string[]
) =>
  intermodalBooking.bookings.some((booking) =>
    selectedBookingIds.includes(booking.id)
  ) &&
  !intermodalBooking.bookings.every((booking) =>
    selectedBookingIds.includes(booking.id)
  );

export const getSelectedIntermodalBookings = (
  intermodalBookings: IntermodalBookingTableViewModel[],
  selectedBookingIds: string[]
) => {
  return intermodalBookings.filter((intermodalBooking) =>
    intermodalBooking.bookings.some((booking) =>
      selectedBookingIds.includes(booking.id)
    )
  );
};

export const areAllIntermodalBookingsSelected = (
  intermodalBookings: IntermodalBookingTableViewModel[],
  selectedBookingIds: string[]
) =>
  intermodalBookings.length > 0 &&
  intermodalBookings.every((intermodalBooking) =>
    intermodalBooking.bookings.every((booking) =>
      selectedBookingIds.includes(booking.id)
    )
  );

export const areAllIntermodalBookingsIndeterminatelySelected = (
  intermodalBooking: IntermodalBookingTableViewModel[],
  selectedBookingIds: string[]
) =>
  intermodalBooking.some((intermodalBooking) =>
    intermodalBooking.bookings.some((booking) =>
      selectedBookingIds.includes(booking.id)
    )
  ) &&
  !intermodalBooking.every((intermodalBooking) =>
    intermodalBooking.bookings.every((booking) =>
      selectedBookingIds.includes(booking.id)
    )
  );

export const selectBookingId = (
  id: string,
  isSelecting: boolean,
  selectedBookingIds: string[]
) => {
  let modifiedSelectedBookingIds = [...selectedBookingIds];
  if (isSelecting) {
    modifiedSelectedBookingIds.push(id);
  } else {
    modifiedSelectedBookingIds = modifiedSelectedBookingIds.filter(
      (selectedLegId) => selectedLegId !== id
    );
  }
  return modifiedSelectedBookingIds;
};

export const selectIntermodalBooking = (
  intermodalBooking: IntermodalBookingTableViewModel,
  isSelecting: boolean,
  selectedBookingIds: string[]
) => {
  let modifiedSelectedBookingIds = [...selectedBookingIds];
  if (isSelecting) {
    intermodalBooking.bookings.forEach((booking) => {
      modifiedSelectedBookingIds.push(booking.id);
    });
  } else {
    intermodalBooking.bookings.forEach((booking) => {
      modifiedSelectedBookingIds = modifiedSelectedBookingIds.filter(
        (selectedLegId) => selectedLegId !== booking.id
      );
    });
  }
  return modifiedSelectedBookingIds;
};

export const selectAllIntermodalBookings = (
  intermodalBookings: IntermodalBookingTableViewModel[],
  isSelecting: boolean
) => {
  let modifiedSelectedBookingIds: string[] = [];
  if (isSelecting) {
    intermodalBookings.forEach((intermodalBooking) =>
      intermodalBooking.bookings.forEach((booking) => {
        modifiedSelectedBookingIds.push(booking.id);
      })
    );
  } else {
    modifiedSelectedBookingIds = [];
  }
  return modifiedSelectedBookingIds;
};

export const isAnySelectedBookingAbleToCheckIn = (
  intermodalBookings: IntermodalBookingTableViewModel[],
  selectedLegIds: string[]
) => {
  const selectedLegBookings = getSelectedBookingLegsFromIntermodalBookings(
    intermodalBookings,
    selectedLegIds
  );
  return some(selectedLegBookings, (booking) =>
    isBookingViewModelAbleToCheckIn(booking)
  );
};
