import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sumBy } from "lodash";
import { RootState } from "../../../config/redux/store";
import { HazardousGoodsStatus, PartnerBookingStatus } from "../../../generated";

export interface IntermodalBookingFormModel
  extends IntermodalBookingLegFormModel {
  dropOffReference: string;
  pickUpReference: string;
  craneable: boolean;
  hazardousGoods: boolean;
  numberOfPlugins: number;
  temperature: string;
  userMail: string;
  bookGoods: BookGoodsFormModel[];
}

export interface IntermodalBookingLegFormModel {
  id: string;
  vehicleRegistration: string;
  vehicleTypeCode: string;
  loadingNote: string;
  customerNumber: string;
  timestamp: string;
  legNumber: number;
  sailingId: string;
  iluCode: string;
  emptyVehicleWeight: string;
  vehicleLength: string;
  vehicleHeight: string;
  vehicleWidth: string;
  customerReference: string;
  bookGoods: BookGoodsFormModel[] | null;
  hazardousGoodsStatus: HazardousGoodsStatus;
  routeCode: string;
  partnerBookingNumber: string;
  partnerBookingStatus: PartnerBookingStatus | null;
  isTrain: boolean;
  enablePlugin: boolean;
  craneableStatus: string;
  isPartOfRoute: boolean;
}

export interface IntermodalBookingLegPartnerConfirmedModel
  extends IntermodalBookingLegFormModel {
  sailing: { scheduledDeparture: string; scheduledArrival: string };
  craneable: boolean;
  craneableStatus: string;
  numberOfPlugins: number;
  pluginTemperature: string;
  totalGoodsWeight: number;
}

export interface BookGoodsFormModel {
  id: string | null;
  code: string | null;
  weight: string;
}

export interface IntermodalFormModel {
  intermodalForm: IntermodalBookingFormModel;
  bookingForms: IntermodalBookingLegFormModel[];
  partnerConfirmedBookingForms: Partial<IntermodalBookingLegPartnerConfirmedModel>[];
}

export interface IntermodalBookingReducerState {
  model: IntermodalFormModel | null;
}

const initialState: IntermodalBookingReducerState = {
  model: null,
};

export const formSlice = createSlice({
  name: "updateIntermodalBookingForm",
  initialState,
  reducers: {
    initializeBookingForm: (
      state,
      action: PayloadAction<IntermodalFormModel>
    ) => {
      state.model = action.payload;
    },
    setIntermodalBookingFormValue: (
      state,
      action: PayloadAction<{
        values: Partial<IntermodalBookingFormModel>;
      }>
    ) => {
      if (state.model) {
        Object.assign(state.model.intermodalForm, action.payload.values);
      }
    },
    setBookingFormValue: (
      state,
      action: PayloadAction<{
        index: number | null;
        values: Partial<IntermodalBookingLegFormModel>;
      }>
    ) => {
      if (state.model) {
        if (action.payload.index == null) {
          Object.assign(state.model.intermodalForm, action.payload.values);
          for (
            let index = 0;
            index < state.model.bookingForms.length;
            index++
          ) {
            Object.assign(
              state.model.bookingForms[index],
              action.payload.values
            );
          }
        } else {
          Object.assign(
            state.model.bookingForms[action.payload.index],
            action.payload.values
          );
        }
      }
    },
    addBookGood(state) {
      if (state.model) {
        state.model.intermodalForm.bookGoods.push({
          id: null,
          weight: "",
          code: null,
        });

        for (let index = 0; index < state.model.bookingForms.length; index++) {
          state.model.bookingForms[index].bookGoods = null;
        }
      }
    },
    setBookGood(
      state,
      action: PayloadAction<{ index: number; code?: string; weight?: string }>
    ) {
      if (state.model) {
        const { index, ...values } = action.payload;
        Object.assign(state.model.intermodalForm.bookGoods[index], values);

        for (let index = 0; index < state.model.bookingForms.length; index++) {
          state.model.bookingForms[index].bookGoods = null;
        }
      }
    },
    removeBookGood(state, action: PayloadAction<{ index: number }>) {
      if (state.model) {
        state.model.intermodalForm.bookGoods.splice(action.payload.index, 1);

        for (let index = 0; index < state.model.bookingForms.length; index++) {
          state.model.bookingForms[index].bookGoods = null;
        }
      }
    },
    setPartnerBookingConfirmStatuses(
      state,
      action: PayloadAction<{ indexes: number[] }>
    ) {
      if (state.model) {
        for (let i = 0; i < action.payload.indexes.length; i++) {
          const index = action.payload.indexes[i];
          state.model.bookingForms[index].partnerBookingStatus =
            PartnerBookingStatus.Confirmed;
        }
      }
    },
    setPartnerBookingUnconfirmStatuses(
      state,
      action: PayloadAction<{ indexes: number[] }>
    ) {
      if (state.model) {
        for (let i = 0; i < action.payload.indexes.length; i++) {
          const index = action.payload.indexes[i];
          state.model.bookingForms[index].partnerBookingStatus =
            PartnerBookingStatus.Unconfirmed;
        }
      }
    },
  },
});

export const {
  initializeBookingForm,
  setBookingFormValue,
  setIntermodalBookingFormValue,
  addBookGood,
  removeBookGood,
  setBookGood,
  setPartnerBookingConfirmStatuses,
  setPartnerBookingUnconfirmStatuses,
} = formSlice.actions;

export const updateIntermodalBookingFormReducer = formSlice.reducer;
export const getIntermodalBookingFormState = (state: RootState) =>
  state.updateIntermodalBookingForm;
export const getIntermodalBookingFormModel = (state: RootState) =>
  getIntermodalBookingFormState(state).model;

export const getIntermodalBookingPartnerConfirmedMismatchValue = <
  TKey extends keyof IntermodalBookingLegFormModel
>(
  state: RootState,
  name: TKey,
  index: number | null
): IntermodalBookingLegFormModel[TKey] | undefined => {
  const model = getIntermodalBookingFormModel(state);

  if (!model || index == null) {
    return undefined;
  }

  const partnerConfirmedValue = model.partnerConfirmedBookingForms[index][name];
  const formValue = model.bookingForms[index][name];

  if (partnerConfirmedValue === undefined) {
    return undefined;
  }

  if (partnerConfirmedValue !== formValue) {
    return partnerConfirmedValue;
  }
};

export const getPartnerConfirmedValue = <
  TKey extends keyof IntermodalBookingLegPartnerConfirmedModel
>(
  state: RootState,
  name: TKey,
  index: number | null
): IntermodalBookingLegPartnerConfirmedModel[TKey] | undefined => {
  const model = getIntermodalBookingFormModel(state);

  if (!model || index == null) {
    return undefined;
  }

  return model.partnerConfirmedBookingForms[index][name];
};

const getTrainLegsValueIsMismatch = (
  forms: IntermodalBookingLegFormModel[],
  name: keyof IntermodalBookingLegFormModel,
  model: IntermodalFormModel
) => {
  return forms.some(
    (form) =>
      form.isPartOfRoute &&
      form.isTrain &&
      form[name] !== model.intermodalForm[name]
  );
};

export const getIntermodalBookingValueIsMismatched = (
  state: RootState,
  name: keyof IntermodalBookingLegFormModel
): boolean => {
  const model = getIntermodalBookingFormModel(state);

  if (!model) {
    return false;
  }

  if (name === "craneableStatus") {
    return getTrainLegsValueIsMismatch(model.bookingForms, name, model);
  }
  return model.bookingForms.some(
    (form) => form.isPartOfRoute && form[name] !== model.intermodalForm[name]
  );
};

export const getIntermodalBookingFormValueByIndex = <
  TKey extends keyof IntermodalBookingFormModel
>(
  state: RootState,
  name: TKey
) => {
  return getIntermodalBookingFormState(state).model?.intermodalForm?.[name];
};

export const getIntermodalBookingLegFormValueByIndex = <
  TKey extends keyof IntermodalBookingLegFormModel
>(
  state: RootState,
  index: number | null,
  name: TKey
): IntermodalBookingLegFormModel[TKey] | undefined => {
  if (index == null) {
    return getIntermodalBookingFormState(state).model?.intermodalForm?.[name];
  }

  return getIntermodalBookingFormState(state).model?.bookingForms[index]?.[
    name
  ];
};

export const getIntermodalBookingBookGoods = (
  state: RootState,
  index: number | null
): BookGoodsFormModel[] => {
  const intermodalBookGoods =
    getIntermodalBookingFormState(state).model?.intermodalForm?.bookGoods ?? [];
  if (index == null) {
    return intermodalBookGoods;
  }

  const bookingLegBookGoods =
    getIntermodalBookingFormState(state).model?.bookingForms[index].bookGoods;

  return bookingLegBookGoods ?? intermodalBookGoods;
};

export const getShowHazardousGoodsStatus = (state: RootState) => {
  return (
    getIntermodalBookingFormValueByIndex(state, "hazardousGoods") &&
    getIntermodalBookingFormValueByIndex(state, "hazardousGoodsStatus") !==
      HazardousGoodsStatus.NotRequested
  );
};

export const getTotalGoodsWeight = (
  state: RootState,
  index: number | null
): number => {
  const bookGoods = getIntermodalBookingBookGoods(state, index);

  return sumBy(bookGoods, (b) => Number(b.weight));
};

export const getTotalWeightMismatchedValue = (
  state: RootState,
  index: number | null
): number | null => {
  const model = getIntermodalBookingFormModel(state);

  if (!model || index == null) {
    return null;
  }

  const totalGoodsWeight = getTotalGoodsWeight(state, index);
  const partnerBookingConfirmedTotalWeight =
    model.partnerConfirmedBookingForms[index].totalGoodsWeight;

  if (partnerBookingConfirmedTotalWeight == null) {
    return null;
  }

  return partnerBookingConfirmedTotalWeight !== totalGoodsWeight
    ? partnerBookingConfirmedTotalWeight
    : null;
};
