import { DateTextInput } from "@stenajs-webui/calendar";
import { Txt } from "@stenajs-webui/core";
import { isStringValidDate } from "../../utils/dateUtils";

interface Props {
  value: string;
  editable: boolean;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
}

export const ConditionallyEditableDateInput: React.FC<Props> = ({
  value,
  editable,
  onValueChange,
  disabled = false,
  ...props
}) => {
  return editable ? (
    <DateTextInput
      {...props}
      variant={isStringValidDate(value) ? "standard" : "error"}
      disabled={disabled}
      value={value}
      onValueChange={(dateValue) => {
        if (true) {
          onValueChange(dateValue);
        }
      }}
    />
  ) : (
    <Txt>{value}</Txt>
  );
};
