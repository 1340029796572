import { Label, stenaDetails } from "@stenajs-webui/elements";
import { formatCustomerReferenceString } from "../../../../common/utils/stringUtils";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import {
  MAX_LENGTH_CUSTOMER_REFERENCE,
  MAX_LENGTH_LOADING_NOTE,
} from "../../../common/bookings/fieldLengths";
import { CreateBookingTextArea } from "../form-fields/CreateBookingTextArea";
import { CreateBookingTextInput } from "../form-fields/CreateBookingTextInput";

interface Props {}

export const DetailsColumn: React.FC<Props> = () => {
  return (
    <BookingDetailsColumn
      heading="Details"
      icon={stenaDetails}
      borderRight
      gap={2}
    >
      <Label text="Customer reference">
        <CreateBookingTextInput
          className="t_customer_reference"
          name="customerReference"
          maxLength={MAX_LENGTH_CUSTOMER_REFERENCE}
          maskInput={formatCustomerReferenceString}
        />
      </Label>
      <Label text="Loading note">
        <CreateBookingTextArea
          className="t_loading_note"
          name="loadingNote"
          maxLength={MAX_LENGTH_LOADING_NOTE}
        />
      </Label>
    </BookingDetailsColumn>
  );
};
