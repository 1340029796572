import { useQuery } from "@apollo/client";
import {
  Row,
  SeparatorLine,
  useBoolean,
  useDebounce,
} from "@stenajs-webui/core";
import { PrimaryButton, stenaPlusCircle } from "@stenajs-webui/elements";
import { LoadingModal } from "@stenajs-webui/modal";
import { orderBy } from "lodash";
import { useDeferredValue, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigation } from "react-router-dom";
import { Filter } from "../../common/components/Filter";
import { getSelectedBookingLegsFromIntermodalBookings } from "../../common/utils/intermodalBookingutils";
import { ENABLE_CREATE_BOOKING } from "../../config/featureFlags";
import {
  GetIntermodalBookingsDocument,
  GetIntermodalBookingsQuery,
  GetIntermodalBookingsQueryVariables,
} from "../../generated";
import { CreateIntermodalBookingsModal } from "../booking-create/CreateIntermodalBookingsModal";
import { useUpdateKey } from "../booking-create/hooks/useUpdateKey";
import { initializeCreateIntermodalForm } from "../booking-create/redux/formSlice";
import { createInitialCreateForm } from "../booking-create/redux/initialCreateForm";
import { IntermodalRouteSelect } from "../common/IntermodalRouteSelect";
import { BookingsTableHeader } from "./bookings-table/components/BookingTableHeader";
import { BookingsTable } from "./bookings-table/components/BookingsTable";
import { useFilterState } from "./bookings-table/useFilterState";
import { useSortState } from "./bookings-table/useSortState";
import { resolveBookingSortProperty } from "./bookings-table/utils/sortingUtils";
import { bookingMatchesSearchInput } from "./utils/bookingFilterUtils";
import {
  formatAndCopyBookingLegsAsTable,
  formatAndCopyBookingsAsTable,
} from "./utils/copyBookingsTable";
import { formatServerDate } from "./utils/dateUtils";
import { transformIntermodalBookingToTableViewModel } from "./utils/intermodalTableViewModelUtils";

export const BookingsPage = () => {
  const [key, forceUpdate] = useUpdateKey();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [createModalIsOpen, openCreateModal, closeCreateModal] =
    useBoolean(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedLegIds, setSelectedLegIds] = useState<string[]>([]);
  const [filter, setFilterState] = useFilterState();
  const [sortState, onClickSort] = useSortState();

  const debouncedFilter = useDebounce(filter, 700);

  const {
    loading: loadingBookings,
    error,
    previousData,
    data = previousData,
  } = useQuery<GetIntermodalBookingsQuery, GetIntermodalBookingsQueryVariables>(
    GetIntermodalBookingsDocument,
    {
      variables: {
        endDate: formatServerDate(debouncedFilter.endDate),
        startDate: formatServerDate(debouncedFilter.startDate),
        routeCodes: debouncedFilter.routes,
      },
    }
  );

  const intermodalBookingViewModels = useMemo(
    () =>
      data?.intermodalBookings.map((intermodalBooking) =>
        transformIntermodalBookingToTableViewModel(intermodalBooking)
      ) ?? [],
    [data]
  );

  const deferredSearchInput = useDeferredValue(searchInput);

  const filteredBookings = useMemo(
    () =>
      intermodalBookingViewModels.filter((booking) =>
        bookingMatchesSearchInput(booking, deferredSearchInput)
      ),
    [intermodalBookingViewModels, deferredSearchInput]
  );

  const sortedBookings = useMemo(
    () =>
      orderBy(
        filteredBookings,
        (booking) => resolveBookingSortProperty(booking, sortState.sort),
        sortState.asc ? "asc" : "desc"
      ),
    [filteredBookings, sortState]
  );

  const handleCopyBookings = () => {
    formatAndCopyBookingsAsTable(sortedBookings);
  };

  const handleCopySelectedLegBookings = () => {
    const bookingLegs = getSelectedBookingLegsFromIntermodalBookings(
      sortedBookings,
      selectedLegIds
    );
    formatAndCopyBookingLegsAsTable(bookingLegs);
  };

  return (
    <>
      <Filter
        dateRange={{
          endDate: formatServerDate(filter.endDate),
          startDate: formatServerDate(filter.startDate),
        }}
        onDateRangeChanged={(dateRange) => {
          setFilterState(
            {
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
            },
            "replaceIn"
          );
        }}
        contentLeft={
          <IntermodalRouteSelect
            startDate={formatServerDate(filter.startDate)}
            onChange={(values) => {
              setFilterState({ routes: values }, "replaceIn");
            }}
          />
        }
        contentRight={
          ENABLE_CREATE_BOOKING && (
            <Row style={{ marginLeft: "auto" }}>
              <PrimaryButton
                className="t_add_booking"
                onClick={() => {
                  dispatch(
                    initializeCreateIntermodalForm(
                      createInitialCreateForm(new Date())
                    )
                  );
                  openCreateModal();
                }}
                leftIcon={stenaPlusCircle}
                variant={"success"}
                label={"Add booking"}
              />
            </Row>
          )
        }
      />
      <SeparatorLine />
      <BookingsTableHeader
        intermodalBookings={intermodalBookingViewModels}
        selectedLegIds={selectedLegIds}
        filteredBookingsLength={filteredBookings.length}
        setSearchInput={setSearchInput}
        onCopyBookings={handleCopyBookings}
        onCopySelectedLegBookings={handleCopySelectedLegBookings}
      />
      {useMemo(
        () => (
          <BookingsTable
            bookings={sortedBookings}
            selectedBookingIds={selectedLegIds}
            loading={loadingBookings}
            error={error}
            setSelectedBookingIds={setSelectedLegIds}
            sortState={sortState}
            onClickSort={onClickSort}
          />
        ),
        [
          sortedBookings,
          selectedLegIds,
          loadingBookings,
          error,
          sortState,
          onClickSort,
        ]
      )}
      {navigation.state === "loading" && <LoadingModal />}
      <CreateIntermodalBookingsModal
        key={key}
        isOpen={createModalIsOpen}
        onRequestClose={() => {
          closeCreateModal();
          forceUpdate();
        }}
      />
    </>
  );
};
