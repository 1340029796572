import { CheckboxWithLabel } from "@stenajs-webui/forms";
import { FC, ReactNode, useEffect, useState } from "react";
import { filterMap } from "../../../../common/filterMap";
import {
  BookingActionsModal,
  BookingActionsModalProps,
} from "./BookingActionsModal";
import { Row } from "@stenajs-webui/core";

interface SelectorOption {
  label: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  contentRight?: ReactNode;
}

interface OptionsModalProps
  extends Omit<BookingActionsModalProps, "onSubmit" | "children"> {
  onSubmit: (indexesToConfirm: number[]) => void;
  options: SelectorOption[];
  initialState: boolean[];
}

export const SelectMultipleModal: FC<OptionsModalProps> = ({
  isOpen,
  options,
  onSubmit,
  initialState,
  ...props
}) => {
  const [values, setValues] = useState<Array<boolean>>(initialState);

  useEffect(() => {
    if (isOpen) {
      setValues(initialState);
    }
  }, [isOpen, initialState]);

  const handleSubmit = () => {
    onSubmit(
      filterMap(options, (option, index) =>
        values[index] && !option.disabled ? index : null
      )
    );
  };

  return (
    <BookingActionsModal {...props} isOpen={isOpen} onSubmit={handleSubmit}>
      {options.map((option, index) => {
        return (
          <Row
            alignItems={"center"}
            key={index}
            justifyContent={"space-between"}
          >
            <CheckboxWithLabel
              key={index}
              label={option.label}
              disabled={option.disabled}
              value={values[index]}
              onClick={() =>
                setValues((values) => {
                  const newValues = values.slice();
                  newValues[index] = !newValues[index];
                  return newValues;
                })
              }
            />
            {option.contentRight}
          </Row>
        );
      })}
    </BookingActionsModal>
  );
};
