import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Box, Column, Row, SeparatorLine, Txt } from "@stenajs-webui/core";
import {
  formatDepartureDateTime,
  formatNotificationDate,
} from "../../common/utils/dateUtils";
import { parseISO } from "date-fns";
import {
  Icon,
  stenaArrowRight,
  Tag,
  TagVariant,
} from "@stenajs-webui/elements";
import { TodoFragment } from "../../../generated";

const BookingRequestItemLink = styled(Link)`
  text-decoration: none;

  &:hover {
    background-color: var(--lhds-color-ui-200);
  }
`;

interface Props {
  todo: TodoFragment;
  onClick: () => void;
}

const getTagVariantFromTodoStatus = (status: string): TagVariant => {
  switch (status) {
    case "New request":
      return "warning";
    case "Arrived":
    case "Check in":
      return "info";
    case "Cancelled":
      return "error";
    case "Updated":
    default:
      return "info";
  }
};

export const TodoItem = ({ todo, onClick }: Props) => {
  const {
    arrivalLocation,
    departureLocation,
    vehicleRegistration,
    departure,
    bookingNumber,
    toDoStatus,
    date,
    customerIndex,
  } = todo;

  return (
    <Box>
      <BookingRequestItemLink
        to={`/bookings/${bookingNumber}`}
        onClick={onClick}
      >
        <Column spacing={3} indent={3} gap={2}>
          <Row justifyContent={"space-between"}>
            <Txt variant={"bold"} size={"large"}>
              {formatDepartureDateTime(parseISO(departure))}
            </Txt>
            <Tag
              variant={getTagVariantFromTodoStatus(toDoStatus)}
              label={toDoStatus}
              size={"small"}
            />
          </Row>
          <Row gap alignItems={"center"}>
            <Txt variant={"bold"}>{departureLocation}</Txt>
            <Icon icon={stenaArrowRight} size={12} />
            <Txt variant={"bold"}>{arrivalLocation}</Txt>
          </Row>

          <Txt>{vehicleRegistration || bookingNumber}</Txt>
          <Row gap={2}>
            {customerIndex != null ? <Txt>{customerIndex}</Txt> : null}
            <Txt>{formatNotificationDate(parseISO(date))}</Txt>
          </Row>
        </Column>
      </BookingRequestItemLink>
      <SeparatorLine />
    </Box>
  );
};
