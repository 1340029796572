import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { BoxProps, Column, SeparatorLine } from "@stenajs-webui/core";
import { ColumnHeader } from "../../booking-details/components/columns/column-details/ColumnHeader";
import { BOOKING_TABLE_BORDER } from "../../bookings/bookings-table/components/BookingTableRow";

interface Props extends Omit<BoxProps, "borderRight"> {
  heading: string;
  icon: IconDefinition;
  borderRight?: boolean;
  indentAndSpacing?: boolean;
}

export const BookingDetailsColumn: React.FC<Props> = ({
  heading,
  icon,
  borderRight,
  children,
  gap,
  ...props
}) => {
  return (
    <Column
      {...props}
      flex={"1 0 264px"}
      borderRight={borderRight ? BOOKING_TABLE_BORDER : undefined}
    >
      <ColumnHeader icon={icon} heading={heading} />
      <SeparatorLine />
      <Column indent={3} spacing={2} gap={gap}>
        {children}
      </Column>
    </Column>
  );
};
