import { useLazyQuery } from "@apollo/client";
import {
  faCheckCircle,
  faMagnifyingGlass,
} from "@fortawesome/pro-light-svg-icons";
import { Txt } from "@stenajs-webui/core";
import { Banner, ResultListBanner } from "@stenajs-webui/elements";
import { FC, useState } from "react";
import { filterMap } from "../../../../common/filterMap";
import {
  FormIntermodalBookingFragment,
  GetIntermodalJourneyByIdDocument,
  IntermodalJourneyLeg,
} from "../../../../generated";
import { getBannerErrorState } from "../../../common/utils/apolloErrorUtils";
import { formatDateISO } from "../../../common/utils/dateUtils";
import { getDepartureOptionState } from "../../utils/bookingActionsUtils";
import {
  formatBookingRoute,
  formatLaterDepartureForJourney,
  formatLaterDepartureOption,
} from "../formatter";
import { BookingActionsModal } from "./BookingActionsModal";
import { FindLaterDeparture } from "./FindLaterDeparture";
import { SetLaterDeparture } from "./SetLaterDeparture";
import { toggleValueByIndex } from "../../../common/utils/booleanArray";

interface Props {
  onRequestClose: () => void;
  onSubmitSetJourney: (legs: IntermodalJourneyLeg[]) => Promise<void>;
  initialSelectedIndex: number;
  loadingSetJourney: boolean;
  intermodalBooking: FormIntermodalBookingFragment;
}

type ModalState = "find-new-journey" | "set-new-journey";

export const NewJourneyModal: FC<Props> = ({
  initialSelectedIndex,
  onSubmitSetJourney,
  onRequestClose,
  loadingSetJourney,
  intermodalBooking,
}) => {
  const [modalState, setModalState] = useState<ModalState>("find-new-journey");

  const [journeyLegs, setJourneyLegs] = useState<boolean[]>([]);
  const [selectedIndex, setSelectedIndex] =
    useState<number>(initialSelectedIndex);

  const [date, setDate] = useState<string>(formatDateISO(new Date()));
  const [time, setTime] = useState<string>("00:00");

  const [fetchJourney, { data: legsData, error, loading: loadingNewJourney }] =
    useLazyQuery(GetIntermodalJourneyByIdDocument, {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });

  const legs = legsData?.intermodalJourneyById.legs;

  const bannerProps = getBannerErrorState(error);

  const options = legs?.map((leg) => {
    const { isCurrent, isDisabled } = getDepartureOptionState(
      intermodalBooking.bookings,
      leg
    );

    return {
      sailing: leg.sailing,
      legNumber: leg.legNumber,
      label: formatBookingRoute(leg.sailing.route),
      isCurrent,
      isDisabled,
    };
  });

  const handleFindLaterDeparture = async (
    selectedIndex: number,
    date: string,
    time: string
  ) => {
    const result = await fetchJourney({
      variables: {
        intermodalBookingId: intermodalBooking.id,
        date: date,
        time: time,
        firstLegNumber: selectedIndex + 1,
      },
    });

    const legs = result.data?.intermodalJourneyById.legs;

    if (result.error == null && legs != null) {
      const selectedLegs = legs.map(
        (leg) =>
          getDepartureOptionState(intermodalBooking.bookings, leg).isPreselected
      );

      setJourneyLegs(selectedLegs);
      setModalState("set-new-journey");
    }
  };

  const handleSetNewJourney = async () => {
    if (legs == null) {
      return;
    }
    const legsToSet = filterMap(journeyLegs, (selected, i) =>
      selected ? legs[i] : null
    );
    await onSubmitSetJourney(legsToSet);
    setModalState("find-new-journey");
  };

  const laterDepartureForJourneyText = formatLaterDepartureForJourney(
    legs,
    intermodalBooking
  );

  return (
    <BookingActionsModal
      loading={loadingSetJourney || loadingNewJourney}
      onRequestClose={onRequestClose}
      headerText="Find later departure"
      isOpen={true}
      onSubmit={() => {
        if (modalState === "find-new-journey") {
          handleFindLaterDeparture(selectedIndex, date, time);
        } else {
          handleSetNewJourney();
        }
      }}
      onBack={
        modalState === "set-new-journey"
          ? () => setModalState("find-new-journey")
          : undefined
      }
      subHeader={
        modalState === "find-new-journey" ? (
          <Txt>Find intermodal connections for:</Txt>
        ) : laterDepartureForJourneyText ? (
          <Txt>
            An intermodal connection was found for{" "}
            <Txt variant="bold">{laterDepartureForJourneyText}</Txt>
          </Txt>
        ) : (
          <Banner variant="error" headerText="The journey contains no legs" />
        )
      }
      actionText={
        modalState === "find-new-journey"
          ? "Find later departure"
          : "Book selected"
      }
      submitIcon={
        modalState === "find-new-journey" ? faMagnifyingGlass : faCheckCircle
      }
      width="450px"
    >
      {modalState === "find-new-journey" ? (
        <FindLaterDeparture
          options={intermodalBooking.bookings.map((b) => ({
            label: formatLaterDepartureOption(
              b.sailing.route,
              intermodalBooking
            ),
            disabled: !b.isEditable,
          }))}
          date={date}
          time={time}
          selectedLegIndex={selectedIndex}
          setDate={setDate}
          setSelectedLegIndex={setSelectedIndex}
          setTime={setTime}
        />
      ) : (
        <SetLaterDeparture
          selectedLegs={journeyLegs}
          onClickOption={(index) => {
            setJourneyLegs((legs) => toggleValueByIndex(legs, index));
          }}
          options={options ?? []}
        />
      )}
      {error != null && bannerProps != null && (
        <ResultListBanner {...bannerProps} />
      )}
    </BookingActionsModal>
  );
};
