const ENABLED_IN_DEV = process.env.NODE_ENV === "development";
export const ENABLE_CREATE_BOOKING = true;
export const ENABLE_BOOKING_DETAILS_CHECK_IN_LEG = true;
export const ENABLE_BOOKING_DETAILS_COPY_TO_CLIPBOARD = true;
export const ENABLE_BOOKING_DETAILS_SEND_MAIL = ENABLED_IN_DEV;
export const ENABLE_BOOKING_DETAILS_FIND_LATER_DEPARTURE = true;
export const ENABLE_BOOKING_DETAILS_HISTORY = ENABLED_IN_DEV;
export const ENABLE_DEPARTURES_VIEW = true;
export const ENABLE_DEPARTURES_BULK_ACTIONS = ENABLED_IN_DEV;
export const ENABLE_USE_BLOCK_BOOKINGS = true;
export const ENABLE_BOOKING_DETAILS_CANCEL_BOOKING = true;
export const ENABLE_CREATE_BOOKING_ARRIVE_BEFORE = true;
