import { PickByValue } from "@stenajs-webui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import { ConditionallyEditableDateInput } from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableDateInput";
import {
  CreateIntermodalBookingFormModel,
  getIntermodalCreateFormField,
  setCreateIntermodalFormValue,
} from "../../redux/formSlice";

interface Props {
  name: keyof PickByValue<CreateIntermodalBookingFormModel, string>;
  className?: string;
}

export const CreateBookingDateInput: React.FC<Props> = ({ name, ...props }) => {
  const value = useSelector((state: RootState) =>
    getIntermodalCreateFormField(state, name)
  );
  const dispatch = useDispatch();

  return (
    <ConditionallyEditableDateInput
      {...props}
      editable
      onValueChange={(date) => {
        dispatch(
          setCreateIntermodalFormValue({
            values: { [name]: date },
          })
        );
      }}
      value={value}
    />
  );
};
