export function findIndexOrNull<T>(
  array: Array<T> | undefined,
  predicate: (value: T, index: number, obj: T[]) => boolean
) {
  if (array == null) {
    return null;
  }
  const index = array.findIndex(predicate);
  return index !== -1 ? index : null;
}
