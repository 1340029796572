import { useCallback, useMemo } from "react";
import {
  BooleanParam,
  DecodedValueMap,
  QueryParamConfig,
  useQueryParams,
} from "use-query-params";
import { SortState, SortStateKey } from "./types/SortState";
import { createEnumParam } from "serialize-query-params/src/params";
import { createIntermodalBookingTableViewModelMock } from "../utils/mocks/intermodalViewModelMock";
import { IntermodalBookingTableViewModel } from "./models/IntermodalBookingsTableViewModel";
import { getNewSortStateByIntermodalBookingProperty } from "./utils/sortingUtils";

const getInitialState = (): SortState => ({
  sort: "departure",
  asc: true,
});

const sortKey = createEnumParam<keyof IntermodalBookingTableViewModel>(
  Object.keys(
    createIntermodalBookingTableViewModelMock()
  ) as (keyof IntermodalBookingTableViewModel)[]
);

const parseUrlWithFallback = (
  searchParams: DecodedValueMap<{
    sort: QueryParamConfig<SortStateKey | null | undefined>;
    asc: QueryParamConfig<boolean | null | undefined>;
  }>
) => {
  const defaultState = getInitialState();
  return {
    sort: searchParams.sort ?? defaultState.sort,
    asc: searchParams.asc ?? defaultState.asc,
  };
};

export const useSortState = () => {
  const [searchParams, setSearchParams] = useQueryParams({
    sort: sortKey,
    asc: BooleanParam,
  });

  const params = useMemo<SortState>(() => {
    return parseUrlWithFallback(searchParams);
  }, [searchParams]);

  const onSort = useCallback(
    (columnId: SortStateKey) => {
      setSearchParams(
        (prev) =>
          getNewSortStateByIntermodalBookingProperty(
            columnId,
            parseUrlWithFallback(prev)
          ),
        "replaceIn"
      );
    },
    [setSearchParams]
  );

  return [params, onSort] as const;
};
