import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { first } from "lodash";
import { scopes } from "../../common/auth/authConfig";
import { msalInstance } from "../../common/auth/AuthGate";
import { getIntermodalGQLError } from "../../use-case/common/utils/apolloErrorUtils";
import { showAuthError } from "../../use-case/top-menu/userSlice";
import { store } from "../redux/store";

const redirectPromise = msalInstance.handleRedirectPromise();

const acquireAccessToken = async () => {
  const redirectResponse = await redirectPromise;
  if (redirectResponse) {
    msalInstance.setActiveAccount(redirectResponse.account);
    return redirectResponse.accessToken;
  }
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  const request = {
    scopes: scopes,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    msalInstance.setActiveAccount(authResult.account);
    return authResult.accessToken;
  } catch (error) {
    console.error("Could not acquireTokenSilent", error);
    return null;
  }
};

const tokenLink = setContext(async (_, { headers }) => {
  const token = await acquireAccessToken();
  if (token == null) {
    console.error("Could not acquire token");
    return;
  }
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const httpLink = createHttpLink({
  uri: (operation) =>
    import.meta.env.VITE_GQL_ENDPOINT +
    "?operationName=" +
    operation.operationName,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (getIntermodalGQLError(first(graphQLErrors))?.code === "UNAUTHORIZED") {
      msalInstance.setActiveAccount(null);

      store.dispatch(showAuthError());
      return;
    }

    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  link: from([tokenLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          intermodalBookingById: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "IntermodalBooking",
                id: args!.id,
              });
            },
          },
        },
      },
    },
  }),
});

export const refetchQueries = () =>
  client.refetchQueries({ include: "active" });
