import { useQuery } from "@apollo/client";
import { GetCustomersDocument, GetCustomersQuery } from "../../generated";
import { formatCustomerName } from "../../use-case/common/utils/customerUtils";

export function useCustomerOptions() {
  const { data, loading } = useQuery<GetCustomersQuery>(GetCustomersDocument);

  const customerOptions =
    data?.allCustomers.map((customer) => ({
      value: customer.id,
      label: formatCustomerName(customer),
      data: customer,
    })) ?? [];
  return { loading, customerOptions };
}
