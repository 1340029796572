import { Txt } from "@stenajs-webui/core";
import { TextArea, TextAreaProps } from "@stenajs-webui/forms";

interface Props extends TextAreaProps {
  editable: boolean;
  bold?: boolean;
}

export const ConditionallyEditableTextArea: React.FC<Props> = ({
  editable,
  bold,
  ...props
}) => {
  return editable ? (
    <TextArea
      {...props}
      style={{
        ["--swui-textinput-font-weight" as string]: bold
          ? "var(--swui-font-weight-text-bold)"
          : undefined,
      }}
    />
  ) : (
    <Txt>{props.value || "–"}</Txt>
  );
};
