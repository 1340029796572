import { ConditionallyEditableDateInput } from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableDateInput";

interface Props {
  value: string;
  editable: boolean;
  className?: string;
}

export const UpdateBookingDateInput: React.FC<Props> = ({
  value,
  editable,
  ...props
}) => {
  return (
    <ConditionallyEditableDateInput
      {...props}
      editable={editable}
      value={value}
      disabled
      onValueChange={() => {}}
    />
  );
};
