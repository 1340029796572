import { useSelector } from "react-redux";
import { RootState } from "../../../config/redux/store";
import {
  CreateIntermodalBookingFormModel,
  getIntermodalCreateFormField,
  getIntermodalCreateFormIntermodalBooking,
} from "../redux/formSlice";

export function useCreateBookingFormField<
  TKey extends keyof CreateIntermodalBookingFormModel
>(name: TKey) {
  return useSelector((state: RootState) =>
    getIntermodalCreateFormField(state, name)
  );
}

export function useCreateBookingForm() {
  return useSelector((state: RootState) =>
    getIntermodalCreateFormIntermodalBooking(state)
  );
}
