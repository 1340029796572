import { Txt } from "@stenajs-webui/core";
import { FlatButton, stenaCopy } from "@stenajs-webui/elements";
import { TextInput, TextInputProps } from "@stenajs-webui/forms";

interface Props extends TextInputProps {
  editable: boolean;
  bold?: boolean;
  showCopyButton?: boolean;
  maskInput?: (value: string) => string;
}

export const ConditionallyEditableTextInput: React.FC<Props> = ({
  editable,
  bold,
  showCopyButton = false,
  onValueChange,
  maskInput = (value: string) => value,
  ...props
}) => {
  return editable ? (
    <TextInput
      {...props}
      onValueChange={(value) => {
        onValueChange?.(maskInput(value));
      }}
      style={{
        ["--swui-textinput-font-weight" as string]: bold
          ? "var(--swui-font-weight-text-bold)"
          : undefined,
      }}
      contentRight={
        showCopyButton &&
        props.value && (
          <FlatButton
            leftIcon={stenaCopy}
            onClick={() => navigator.clipboard.writeText(props.value ?? "")}
          />
        )
      }
    />
  ) : (
    <Txt>{props.value || "–"}</Txt>
  );
};
