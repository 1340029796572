import { GetVehicleTypesDocument, GetVehicleTypesQuery } from "../../generated";
import { useQuery } from "@apollo/client";

export function useVehicleTypeOptions(
  onCompleted?: (data: GetVehicleTypesQuery) => void
) {
  const { data, loading } = useQuery<GetVehicleTypesQuery>(
    GetVehicleTypesDocument,
    {
      onCompleted,
    }
  );

  const vehicleTypeOptions =
    data?.vehicleTypes.map((vehicleType) => ({
      value: vehicleType.id,
      label: vehicleType.description,
      data: vehicleType,
    })) ?? [];
  return { loading, vehicleTypeOptions };
}
