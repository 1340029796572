import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  getIntermodalBookingFormValueByIndex,
  IntermodalBookingFormModel,
  setIntermodalBookingFormValue,
} from "../../redux/formSlice";
import {
  ConditionallyEditableSelect,
  ConditionallyEditableSelectProps,
  TypedOption,
} from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableSelect";

interface Props<
  TKey extends keyof IntermodalBookingFormModel,
  TData,
  TOption extends TypedOption<IntermodalBookingFormModel[TKey], TData>
> extends Omit<
    ConditionallyEditableSelectProps<
      IntermodalBookingFormModel[TKey],
      TData,
      TOption
    >,
    "value" | "onValueChange"
  > {
  name: TKey;
  onValueChange?: (option: TOption) => void;
  className?: string;
}

export function UpdateIntermodalBookingSelect<
  TKey extends keyof IntermodalBookingFormModel,
  TData,
  TOption extends TypedOption<IntermodalBookingFormModel[TKey], TData>
>({
  isLoading = false,
  options,
  editable,
  isDisabled = false,
  name,
  onValueChange,
  ...props
}: Props<TKey, TData, TOption>) {
  const value = useSelector((state: RootState) =>
    getIntermodalBookingFormValueByIndex(state, name)
  );
  const dispatch = useDispatch();
  return (
    <ConditionallyEditableSelect
      {...props}
      editable={editable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      options={options}
      value={value}
      onValueChange={(option) => {
        dispatch(
          setIntermodalBookingFormValue({
            values: { [name]: option.value },
          })
        );
        onValueChange?.(option);
      }}
    />
  );
}
