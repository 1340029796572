import { Box } from "@stenajs-webui/core";
import { Banner, BannerVariant } from "@stenajs-webui/elements";
import { ResultMessage } from "../../generated";
import { mapResultMessage } from "./utils/apolloErrorUtils";

interface ResultMessageBannerProps {
  messages?: ResultMessage[];
}

const getBannerVariantFromCode = (
  code: string | null | undefined
): BannerVariant => {
  switch (code?.[0]) {
    case "E":
      return "error";
    case "W":
      return "warning";
    case "I":
    default:
      return "info";
  }
};

export const ResultMessageBanner: React.FC<ResultMessageBannerProps> = ({
  messages,
}) => {
  if (messages && messages.length > 0) {
    return (
      <Box minWidth={400} gap>
        {messages.map((message, index) => (
          <Banner
            variant={getBannerVariantFromCode(message.code)}
            key={index}
            text={mapResultMessage(message)}
          />
        ))}
      </Box>
    );
  }

  return null;
};
