import { useQuery } from "@apollo/client";
import { OverflowingMultiSelect } from "@stenajs-webui/select";
import { useMemo } from "react";
import { routeSelectStyle } from "../../common/styles/routeSelectStyle";
import {
  GetIntermodalRoutesDocument,
  GetIntermodalRoutesQuery,
  GetIntermodalRoutesQueryVariables,
} from "../../generated";

interface Props {
  startDate: string;
  onChange: (values: string[]) => void;
}

export const IntermodalRouteSelect: React.FC<Props> = ({
  startDate,
  onChange,
}) => {
  const { loading: loadingRoutes, data: dataRoutes } = useQuery<
    GetIntermodalRoutesQuery,
    GetIntermodalRoutesQueryVariables
  >(GetIntermodalRoutesDocument, {
    variables: {
      validFromDate: startDate,
    },
  });

  const routeOptions = useMemo(
    () =>
      dataRoutes?.allIntermodalRoutes?.map((route) => ({
        value: route.id,
        label: route.description,
      })) ?? [],
    [dataRoutes]
  );
  return (
    <OverflowingMultiSelect
      className="t_intermodal_route"
      closeMenuOnSelect={false}
      placeholder={"All routes"}
      styles={routeSelectStyle}
      isLoading={loadingRoutes}
      options={routeOptions}
      onChange={(option) => onChange(option.map((option) => option.value))}
    />
  );
};
