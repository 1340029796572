import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../config/redux/store";
import { IntermodalJourneyLegFragment } from "../../../generated";
import { createInitialCreateForm } from "./initialCreateForm";
import { last } from "lodash";
import { recalculateJourneyThunk } from "./recalculateJourneyThunk";
import { IntermodalGQLError } from "../../common/utils/apolloErrorUtils";

export interface CreateIntermodalBookingFormModel {
  dropOffReference: string;
  pickUpReference: string;
  vehicleRegistration: string;
  vehicleTypeCode: string;
  loadingNote: string;
  craneable: boolean;
  customerNumber: string | null;
  date: string;
  time: string;
  craneableStatus: string | null;
  iluCode: string;
  emptyVehicleWeight: string;
  vehicleLength: string;
  vehicleHeight: string;
  vehicleWidth: string;
  customerReference: string;
  bookGoods: BookGoodsFormModel[];
  numberOfPlugins: number;
  temperature: string;
  hazardousGoods: boolean;
  routeCode: string | null;
  enablePlugin: boolean;
  searchByDeparture: boolean;
}

export interface BookGoodsFormModel {
  id: string | null;
  code: string | null;
  weight: string;
}

interface SailingsMeta {
  loading: boolean;
  latestRequestId: string | null;
  error: IntermodalGQLError | null;
}

export interface IntermodalCreateFormModel {
  intermodalForm: CreateIntermodalBookingFormModel;
  legs: IntermodalJourneyLegFragment[] | null;
  sailingsMeta: SailingsMeta;
}

export interface IntermodalBookingReducerState {
  model: IntermodalCreateFormModel;
}

const INITIAL_SAILINGS_META: SailingsMeta = {
  loading: false,
  latestRequestId: null,
  error: null,
};

const initialState: IntermodalBookingReducerState = {
  model: {
    legs: null,
    intermodalForm: createInitialCreateForm(new Date()),
    sailingsMeta: INITIAL_SAILINGS_META,
  },
};

export const formSlice = createSlice({
  name: "createIntermodalBookingForm",
  initialState,
  reducers: {
    initializeCreateIntermodalForm: (
      state,
      action: PayloadAction<CreateIntermodalBookingFormModel>
    ) => {
      state.model = {
        intermodalForm: action.payload,
        legs: null,
        sailingsMeta: INITIAL_SAILINGS_META,
      };
    },
    setCreateIntermodalFormValue: (
      state,
      action: PayloadAction<{
        values: Partial<CreateIntermodalBookingFormModel>;
      }>
    ) => {
      Object.assign(state.model.intermodalForm, action.payload.values);
    },
    addBookGood(state) {
      state.model.intermodalForm.bookGoods.push({
        id: null,
        weight: "",
        code: null,
      });
    },
    setBookGood(
      state,
      action: PayloadAction<{ index: number; code?: string; weight?: string }>
    ) {
      const { index, ...values } = action.payload;
      Object.assign(state.model.intermodalForm.bookGoods[index], values);
    },
    removeBookGood(state, action: PayloadAction<{ index: number }>) {
      state.model.intermodalForm.bookGoods.splice(action.payload.index, 1);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(recalculateJourneyThunk.pending, (state, action) => {
        state.model.sailingsMeta.loading = true;
        state.model.sailingsMeta.latestRequestId = action.meta.requestId;
      })
      .addCase(recalculateJourneyThunk.rejected, (state, action) => {
        if (
          state.model.sailingsMeta.latestRequestId === action.meta.requestId
        ) {
          state.model.legs = null;
          state.model.sailingsMeta.loading = false;
          if (action.payload) {
            state.model.sailingsMeta.error = action.payload;
          } else {
            state.model.sailingsMeta.error = {
              name: action.error.name ?? "Unknown error",
              message: action.error.message ?? "",
            };
          }
        }
      })
      .addCase(recalculateJourneyThunk.fulfilled, (state, action) => {
        if (
          state.model.sailingsMeta.latestRequestId === action.meta.requestId
        ) {
          state.model.legs = action.payload;
          state.model.sailingsMeta.error = null;
          state.model.sailingsMeta.loading = false;
        }
      }),
});

export const {
  initializeCreateIntermodalForm,
  setCreateIntermodalFormValue,
  addBookGood,
  setBookGood,
  removeBookGood,
} = formSlice.actions;
export const createIntermodalBookingFormReducer = formSlice.reducer;

export const getIntermodalCreateForm = (state: RootState) =>
  state.createIntermodalBookingForm;

export const getIntermodalCreateFormModel = (state: RootState) =>
  getIntermodalCreateForm(state).model;

export const getIntermodalCreateFormIntermodalBooking = (state: RootState) =>
  getIntermodalCreateFormModel(state).intermodalForm;

export const getIntermodalCreateFormBookGoods = (state: RootState) =>
  getIntermodalCreateFormIntermodalBooking(state).bookGoods;

export const getIntermodalCreateJourney = (state: RootState) =>
  getIntermodalCreateFormModel(state).legs;

export const getIntermodalCreateSubmitIsDisabled = (state: RootState) =>
  getIntermodalCreateFormModel(state).sailingsMeta.loading;

export const getIntermodalCreateJourneyError = (state: RootState) =>
  getIntermodalCreateFormModel(state).sailingsMeta.error;

export const getIntermodalCreateJourneyLastLeg = (state: RootState) =>
  last(getIntermodalCreateJourney(state));

export const getIntermodalCreateFormField = <
  TKey extends keyof CreateIntermodalBookingFormModel
>(
  state: RootState,
  name: TKey
) => getIntermodalCreateFormIntermodalBooking(state)[name];
