import { Column, Row, Spacing, Txt } from "@stenajs-webui/core";
import {
  FlatButton,
  Icon,
  Label,
  stenaArrowRightAngle,
  stenaMinusCircle,
} from "@stenajs-webui/elements";
import { formatNumericalString } from "../../../common/utils/stringUtils";
import { BookGoodsFormModel } from "../../booking-details/redux/formSlice";
import { MAX_LENGTH_GOODS_WEIGHT } from "../bookings/fieldLengths";
import { ConditionallyEditableSelect } from "./conditionally-editable-fields/ConditionallyEditableSelect";
import { ConditionallyEditableTextInput } from "./conditionally-editable-fields/ConditionallyEditableTextInput";

interface Props {
  loading: boolean;
  index: number;
  value: BookGoodsFormModel;
  goodsTypeOptions: {
    value: string;
    label: string;
  }[];
  editable?: boolean;
  onSetBookGood: (bookGood: {
    index: number;
    code?: string;
    weight?: string;
  }) => void;
  onRemoveBookGood: (index: number) => void;
}

export const GoodsDetail: React.FC<Props> = ({
  goodsTypeOptions,
  loading,
  index,
  value,
  onSetBookGood,
  onRemoveBookGood,
  editable = true,
}) => {
  return (
    <>
      <Label text="Goods description">
        <ConditionallyEditableSelect
          className="t_goods_description"
          editable={editable}
          isDisabled={!editable}
          isLoading={loading}
          value={value.code}
          options={goodsTypeOptions}
          onValueChange={(option) => {
            if (option?.value) {
              onSetBookGood({ index, code: option.value });
            }
          }}
        />
      </Label>
      <Row alignItems={"end"} gap>
        <Column>
          <Icon size={20} icon={stenaArrowRightAngle} />
          <Spacing num={0.5} />
        </Column>
        <Column width={"100%"}>
          <Label text="Weight (kg)">
            {editable ? (
              <Row>
                <ConditionallyEditableTextInput
                  className="t_goods_weight"
                  editable
                  value={value.weight}
                  onValueChange={(value) => {
                    onSetBookGood({ index, weight: value });
                  }}
                  maxLength={MAX_LENGTH_GOODS_WEIGHT}
                  maskInput={formatNumericalString}
                />
                <FlatButton
                  className="t_goods_remove"
                  variant={"danger"}
                  leftIcon={stenaMinusCircle}
                  onClick={() => {
                    onRemoveBookGood(index);
                  }}
                />
              </Row>
            ) : (
              <>
                <Txt>{value.weight ?? "–"}</Txt>
                <Spacing num={0.5} />
              </>
            )}
          </Label>
        </Column>
      </Row>
    </>
  );
};
