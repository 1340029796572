import { Box, Indent, Row, Txt } from "@stenajs-webui/core";
import { Label, stenaSailingLocation } from "@stenajs-webui/elements";
import { parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { useCustomerOptions } from "../../../../services/hooks/useCustomerOptions";
import { useRoutesOptions } from "../../../../services/hooks/useRouteOptions";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import {
  formatArrivalDateTime,
  formatDateISO,
} from "../../../common/utils/dateUtils";
import { getIntermodalCreateJourneyLastLeg } from "../../redux/formSlice";
import { DateVariantSelector } from "../DateVariantSelector";
import { CreateBookingDateInput } from "../form-fields/CreateBookingDateInput";
import { CreateBookingSelect } from "../form-fields/CreateBookingSelect";
import { CreateBookingTimeInput } from "../form-fields/CreateBookingTimeInput";

const today = formatDateISO(new Date());

interface Props {}

export const DepartureColumn: React.FC<Props> = () => {
  const { routeOptions, loading: routesLoading } = useRoutesOptions(today);
  const { loading: loadingCustomerOptions, customerOptions } =
    useCustomerOptions();

  const lastLeg = useSelector(getIntermodalCreateJourneyLastLeg);

  const arrivalDate = lastLeg?.sailing.scheduledArrival;

  return (
    <BookingDetailsColumn
      heading="Departure"
      icon={stenaSailingLocation}
      borderRight
      gap={4}
    >
      <Label text="Account">
        <CreateBookingSelect
          className="t_account"
          name="customerNumber"
          options={customerOptions}
          isLoading={loadingCustomerOptions}
          placeholder={"Select account"}
        />
      </Label>
      <Label text="Intermodal route">
        <CreateBookingSelect
          className="t_route"
          options={routeOptions}
          name={"routeCode"}
          isLoading={routesLoading}
        />
      </Label>
      <DateVariantSelector />
      <Row>
        <Box width={"160px"}>
          <Label text="Date">
            <CreateBookingDateInput className="t_date" name={"date"} />
          </Label>
        </Box>
        <Indent />
        <Box width={"90px"}>
          <Label text="Time">
            <CreateBookingTimeInput className="t_time" name={"time"} />
          </Label>
        </Box>
      </Row>
      {arrivalDate != null && (
        <Txt variant={"overline"} size={"small"}>
          Arrives {formatArrivalDateTime(parseISO(arrivalDate))}
        </Txt>
      )}
    </BookingDetailsColumn>
  );
};
