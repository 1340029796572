import { useMutation, useQuery } from "@apollo/client";
import { Row, Txt, useBoolean } from "@stenajs-webui/core";
import {
  Icon,
  ResultListBanner,
  ResultListBannerProps,
  stenaExclamationCircle,
} from "@stenajs-webui/elements";
import { LoadingModal, Modal } from "@stenajs-webui/modal";
import {
  CheckInIntermodalBookingLegDocument,
  CheckInIntermodalBookingLegMutation,
  CheckInIntermodalBookingLegMutationVariables,
  GetIntermodalBookingByIdDocument,
  GetIntermodalBookingByIdQuery,
  GetIntermodalBookingByIdQueryVariables,
  GetIntermodalBookingsDocument,
} from "../../../../generated";
import { CheckInBookingLegModal } from "../../../booking-details/components/modals/CheckInBookingLegModal";
import { isBookingLegAbleToCheckIn } from "../../../booking-details/utils/bookingActionsUtils";
import { getBannerErrorState } from "../../../common/utils/apolloErrorUtils";
import { findIndexOrNull } from "../../../common/utils/arrayUtils";

interface Props {
  id: string;
  onCheckInModalClose: () => void;
}

export const CheckInBookingsTableModal: React.FC<Props> = ({
  id,
  onCheckInModalClose,
}) => {
  const [checkInSucceeded, setCheckInSucceeded, resetCheckInSucceeded] =
    useBoolean(false);
  const { data, loading, error } = useQuery<
    GetIntermodalBookingByIdQuery,
    GetIntermodalBookingByIdQueryVariables
  >(GetIntermodalBookingByIdDocument, {
    variables: { id },
  });
  const [
    checkInIntermodalBookingLeg,
    { loading: checkInLoading, error: checkInError },
  ] = useMutation<
    CheckInIntermodalBookingLegMutation,
    CheckInIntermodalBookingLegMutationVariables
  >(CheckInIntermodalBookingLegDocument);

  const initialSelectedIndex = findIndexOrNull(
    data?.intermodalBookingById?.bookings,
    isBookingLegAbleToCheckIn
  );

  const checkInLeg = async (selectedIndex: number) => {
    resetCheckInSucceeded();
    const legId = data?.intermodalBookingById?.bookings[selectedIndex].id;
    if (legId != null) {
      try {
        await checkInIntermodalBookingLeg({
          variables: {
            intermodalBookingId: id,
            legId: legId,
          },
          refetchQueries: [GetIntermodalBookingsDocument],
        });
        setCheckInSucceeded();
      } catch {
        // error is handled internally by Apollo
      }
    }
  };

  const getBannerSuccessState = (): ResultListBannerProps | null => {
    return checkInSucceeded
      ? {
          variant: "success",
          bannerState: {
            headerText: "Check in successful",
          },
        }
      : null;
  };

  const bannerProps =
    getBannerErrorState(checkInError) || getBannerSuccessState();

  return loading ? (
    <LoadingModal />
  ) : error || data == null || data.intermodalBookingById == null ? (
    <Modal
      isOpen
      header={
        <Row gap>
          <Icon icon={stenaExclamationCircle} />
          <Txt>Something went wrong</Txt>
        </Row>
      }
      onRequestClose={onCheckInModalClose}
      width="360px"
    >
      <Txt>
        You can go back and try again in a few minutes. If it still doesn't
        work, contact us for help.
      </Txt>
    </Modal>
  ) : (
    <CheckInBookingLegModal
      initialSelected={initialSelectedIndex}
      bookings={data.intermodalBookingById.bookings}
      isOpen
      loading={checkInLoading}
      onRequestClose={onCheckInModalClose}
      onSubmit={checkInLeg}
    >
      {bannerProps && <ResultListBanner {...bannerProps} />}
    </CheckInBookingLegModal>
  );
};
