import { stenaDetails } from "@stenajs-webui/elements";
import * as React from "react";
import { Label } from "../../../../common/common-ui/Label";
import { formatCustomerReferenceString } from "../../../../common/utils/stringUtils";
import {
  FormIntermodalBookingLegFragment,
  PartnerBookingStatus,
} from "../../../../generated";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import {
  MAX_LENGTH_CUSTOMER_REFERENCE,
  MAX_LENGTH_DROP_OFF_REFERENCE,
  MAX_LENGTH_LOADING_NOTE,
  MAX_LENGTH_PARTNER_BOOKING_NUMBER,
  MAX_LENGTH_PICK_UP_REFERENCE,
} from "../../../common/bookings/fieldLengths";
import { isTrainBooking } from "../../utils/bookingDetailsUtils";
import { getOrdinal } from "../../utils/ordinalUtils";
import { PartnerBookingStatusTag } from "../PartnerBookingStatusTag";
import { useSelectedBookingIndex } from "../SelectedBookingIndexContext";
import { UpdateBookingTextArea } from "../form-fields/UpdateBookingTextArea";
import { UpdateBookingTextInput } from "../form-fields/UpdateBookingTextInput";
import { UpdateIntermodalBookingTextInput } from "../form-fields/UpdateIntermodalBookingTextInput";

interface Props {
  selectedBooking: FormIntermodalBookingLegFragment | null;
  trainBookings: FormIntermodalBookingLegFragment[];
  allBookings: FormIntermodalBookingLegFragment[];
  editable: boolean;
  dropOffRefEditable: boolean;
  pickUpRefEditable: boolean;
}

export const DetailsColumn: React.FC<Props> = ({
  selectedBooking,
  trainBookings,
  allBookings,
  editable,
  dropOffRefEditable,
  pickUpRefEditable,
}) => {
  const selectedBookingIndex = useSelectedBookingIndex();

  return (
    <BookingDetailsColumn
      heading="Details"
      icon={stenaDetails}
      borderRight
      gap={2}
    >
      <Label text="Customer reference">
        <UpdateBookingTextInput
          className="t_customer_reference"
          name="customerReference"
          selectedBookingIndex={selectedBookingIndex}
          editable={editable}
          showCopyButton
          maxLength={MAX_LENGTH_CUSTOMER_REFERENCE}
          maskInput={formatCustomerReferenceString}
        />
      </Label>
      {!selectedBooking ? (
        trainBookings.map((booking, index) => {
          return (
            <Label
              text={`${getOrdinal(index + 1)} train booking`}
              key={booking.id}
              customInfoLabel={
                <PartnerBookingStatusTag
                  partnerBookingStatus={booking.partnerBooking?.status}
                />
              }
            >
              <UpdateBookingTextInput
                className="t_partner_booking_number"
                name="partnerBookingNumber"
                selectedBookingIndex={allBookings.findIndex(
                  (b) => b.id === booking.id
                )}
                editable={
                  editable &&
                  booking.partnerBooking?.status ===
                    PartnerBookingStatus.Unconfirmed
                }
                disableMismatch
                showCopyButton
                maxLength={MAX_LENGTH_PARTNER_BOOKING_NUMBER}
              />
            </Label>
          );
        })
      ) : isTrainBooking(selectedBooking) ? (
        <Label
          text={`${getOrdinal(
            trainBookings.findIndex((tb) => tb.id === selectedBooking.id) + 1
          )} train booking`}
          customInfoLabel={
            <PartnerBookingStatusTag
              partnerBookingStatus={selectedBooking.partnerBooking?.status}
            />
          }
        >
          <UpdateBookingTextInput
            className="t_partner_booking_number"
            name={"partnerBookingNumber"}
            selectedBookingIndex={selectedBookingIndex}
            editable={
              editable &&
              selectedBooking.partnerBooking?.status ===
                PartnerBookingStatus.Unconfirmed
            }
            disableMismatch
            showCopyButton
            maxLength={MAX_LENGTH_PARTNER_BOOKING_NUMBER}
          />
        </Label>
      ) : null}
      <Label text="Drop off reference">
        <UpdateIntermodalBookingTextInput
          className="t_drop_off_reference"
          name={"dropOffReference"}
          editable={editable && dropOffRefEditable}
          showCopyButton
          maxLength={MAX_LENGTH_DROP_OFF_REFERENCE}
        />
      </Label>
      <Label text="Pick up reference">
        <UpdateIntermodalBookingTextInput
          className="t_pick_up_reference"
          name={"pickUpReference"}
          editable={editable && pickUpRefEditable}
          showCopyButton
          maxLength={MAX_LENGTH_PICK_UP_REFERENCE}
        />
      </Label>
      <Label text="Loading note">
        <UpdateBookingTextArea
          className="t_loading_note"
          selectedBookingIndex={selectedBookingIndex}
          name={"loadingNote"}
          editable={editable}
          maxLength={MAX_LENGTH_LOADING_NOTE}
        />
      </Label>
    </BookingDetailsColumn>
  );
};
