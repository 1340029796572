import {
  FormIntermodalBookingLegFragment,
  IntermodalJourneyLeg,
  PartnerBookingStatus,
} from "../../../generated";
import { BookingViewModel } from "../../bookings/bookings-table/models/BookingViewModel";

export const isBookingLegUnconfirmable = (
  booking: FormIntermodalBookingLegFragment
) => {
  return (
    booking.sailing.isTrain &&
    booking.partnerBooking?.status === PartnerBookingStatus.Confirmed &&
    booking.isEditable
  );
};

export const isBookingLegConfirmable = (
  booking: FormIntermodalBookingLegFragment
) => {
  return (
    booking.sailing.isTrain &&
    booking.partnerBooking?.status === PartnerBookingStatus.Unconfirmed &&
    booking.isEditable
  );
};

export const isBookingLegAbleToCheckIn = (
  booking: FormIntermodalBookingLegFragment
) => {
  return isAbleToCheckIn(booking.sailing.isTrain, booking.isEditable);
};

export const isBookingViewModelAbleToCheckIn = (booking: BookingViewModel) => {
  return isAbleToCheckIn(booking.isTrain, booking.isEditable);
};

const isAbleToCheckIn = (isTrain: boolean, isEditable: boolean) => {
  return isTrain && isEditable;
};

export const isBookingLegCompletable = (b: FormIntermodalBookingLegFragment) =>
  Boolean(b.partnerBooking?.isUpdated || b.partnerBooking?.isCancelled);

interface DepartureOptionState {
  isCurrent: boolean;
  isPreselected: boolean;
  isDisabled: boolean;
}

export const getDepartureOptionState = (
  bookings: FormIntermodalBookingLegFragment[],
  journeyLeg: IntermodalJourneyLeg
): DepartureOptionState => {
  const booking = bookings.find((b) => b.legNumber === journeyLeg.legNumber);

  if (!booking) {
    return { isCurrent: false, isPreselected: false, isDisabled: false };
  }

  const isCurrent = booking.sailing.id === journeyLeg.sailing.id;
  const isDisabled = isCurrent || !booking.isEditable;
  const isPreselected = !isCurrent && booking.isEditable;

  return { isCurrent, isDisabled, isPreselected };
};
