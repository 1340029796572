import { Box } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import { orderBy } from "lodash";
import { FC, useMemo } from "react";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
import { TableDepartureFragment } from "../../../generated";
import { DepartureCard } from "./DepartureCard";

interface Props {
  departures: TableDepartureFragment[];
  loading: boolean;
  error: boolean;
}

export const DeparturesList: FC<Props> = ({ departures, loading, error }) => {
  const sortedDepartures = useMemo(
    () =>
      orderBy(departures, (departure) => departure.sailing.scheduledDeparture),
    [departures]
  );

  if (loading || departures.length === 0) {
    return (
      <LoadingScreen
        loading={loading}
        error={error}
        errorMessage="Could not fetch departures"
        defaultMessage="We found no departures on these dates and route. Change the dates or route to widen the search"
      />
    );
  }

  return (
    <Box
      background={cssColor("--lhds-color-ui-300")}
      indent={3}
      spacing={3}
      gap={2}
      flex={1}
    >
      {sortedDepartures.map((departure) => (
        <DepartureCard key={departure.sailing.id} departure={departure} />
      ))}
    </Box>
  );
};
