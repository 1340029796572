import { useQuery } from "@apollo/client";
import { Box, Row } from "@stenajs-webui/core";
import {
  FlatButton,
  Label,
  stenaPlusCircle,
  stenaSailingCargo,
} from "@stenajs-webui/elements";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  GetGoodsTypesDocument,
  GetGoodsTypesQuery,
} from "../../../../generated";

import { shouldShowPluginForJourney } from "../../../booking-details/utils/bookingDetailsUtils";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import { MAX_LENGTH_TEMPERATURE } from "../../../common/bookings/fieldLengths";
import { GoodsDetail } from "../../../common/form-fields/GoodsDetail";
import { pluginOptions } from "../../../common/options/plugins";
import {
  addBookGood,
  getIntermodalCreateFormBookGoods,
  getIntermodalCreateJourney,
  removeBookGood,
  setBookGood,
} from "../../redux/formSlice";
import { CreateBookingSelect } from "../form-fields/CreateBookingSelect";
import { CreateBookingTextInput } from "../form-fields/CreateBookingTextInput";
import { hazardousGoodsOptions } from "../../../common/options/hazardousGoods";

interface Props {}

export const CargoColumn: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const intermodalJourney = useSelector((state: RootState) =>
    getIntermodalCreateJourney(state)
  );

  const { loading, data } = useQuery<GetGoodsTypesQuery>(GetGoodsTypesDocument);
  const goodsTypeOptions =
    data?.goodsTypes.map((goodsType) => ({
      value: goodsType.id,
      label: goodsType.description,
    })) ?? [];

  const shouldShowPlugin = shouldShowPluginForJourney(
    intermodalJourney?.map((j) => j.sailing) ?? []
  );

  const bookGoodsFormModel = useSelector((state: RootState) =>
    getIntermodalCreateFormBookGoods(state)
  );

  return (
    <BookingDetailsColumn
      heading="Cargo"
      icon={stenaSailingCargo}
      borderRight
      gap={2}
    >
      <Row gap={2}>
        <Box flex={2}>
          <Label text="Hazardous goods">
            <CreateBookingSelect
              className="t_hazardous_goods"
              name={"hazardousGoods"}
              isLoading={loading}
              options={hazardousGoodsOptions}
            />
          </Label>
        </Box>
      </Row>
      {shouldShowPlugin && (
        <Row gap={2}>
          <Box flex={1}>
            <Label text="Plugin">
              <CreateBookingSelect
                className="t_plugin"
                name={"numberOfPlugins"}
                options={pluginOptions}
              />
            </Label>
          </Box>
          <Box flex={1}>
            <Label text="Temp (°C)">
              <CreateBookingTextInput
                className="t_temperature"
                name={"temperature"}
                maxLength={MAX_LENGTH_TEMPERATURE}
              />
            </Label>
          </Box>
        </Row>
      )}
      {bookGoodsFormModel.map((bookGoods, index) => (
        <GoodsDetail
          index={index}
          key={bookGoods.id ?? index}
          loading={loading}
          value={bookGoodsFormModel[index]}
          goodsTypeOptions={goodsTypeOptions}
          onSetBookGood={(bookGood) => dispatch(setBookGood(bookGood))}
          onRemoveBookGood={(index) => dispatch(removeBookGood({ index }))}
        />
      ))}
      <Row>
        <FlatButton
          className="t_add_goods"
          leftIcon={stenaPlusCircle}
          label="Add goods"
          onClick={() => {
            dispatch(addBookGood());
          }}
        />
      </Row>
    </BookingDetailsColumn>
  );
};
