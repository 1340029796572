import { PickByValue } from "@stenajs-webui/core";
import { SelectProps } from "@stenajs-webui/select";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimeSelect } from "../../../../common/common-ui/TimeSelect";
import { RootState } from "../../../../config/redux/store";
import {
  CreateIntermodalBookingFormModel,
  getIntermodalCreateFormField,
  setCreateIntermodalFormValue,
} from "../../redux/formSlice";

interface Props extends SelectProps {
  name: keyof PickByValue<CreateIntermodalBookingFormModel, string>;
}

export const CreateBookingTimeInput: FC<Props> = ({ name, ...props }) => {
  const value = useSelector((state: RootState) =>
    getIntermodalCreateFormField(state, name)
  );
  const dispatch = useDispatch();

  return (
    <TimeSelect
      {...props}
      setTime={(value) =>
        dispatch(
          setCreateIntermodalFormValue({
            values: { [name]: value },
          })
        )
      }
      time={value}
    />
  );
};
