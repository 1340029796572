import { FC, ReactNode } from "react";
import { FormIntermodalBookingLegFragment } from "../../../../generated";
import { isBookingLegAbleToCheckIn } from "../../utils/bookingActionsUtils";
import { formatCheckInBookingRoute } from "../formatter";
import { SelectSingleModal } from "./SelectSingleModal";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (index: number) => void;
  loading: boolean;
  initialSelected: number | null;
  bookings: FormIntermodalBookingLegFragment[];
  children?: ReactNode;
}

export const CheckInBookingLegModal: FC<Props> = ({
  bookings,
  initialSelected,
  ...props
}) => {
  return (
    <SelectSingleModal
      {...props}
      initialSelected={initialSelected}
      subHeaderText={"Select booking to check-in:"}
      headerText={"Check-in booking"}
      options={bookings.map((b) => ({
        label: formatCheckInBookingRoute(b.sailing.route, b.sailing.isTrain),
        disabled: !isBookingLegAbleToCheckIn(b),
      }))}
    />
  );
};
