import { DateRange, DateRangeDualTextInput } from "@stenajs-webui/calendar";
import { Row } from "@stenajs-webui/core";
import { parseISO } from "date-fns";
import { ReactNode, useMemo } from "react";
import { StringDateRange } from "../types/StringDateRange";

interface Props {
  dateRange: StringDateRange;
  contentRight?: ReactNode;
  contentLeft: ReactNode;
  onDateRangeChanged: (dateRange: DateRange) => void;
}

export const Filter: React.FC<Props> = ({
  dateRange,
  contentRight,
  onDateRangeChanged,
  contentLeft,
}) => {
  const parsedDateRange = useMemo(
    () => ({
      startDate: dateRange.startDate
        ? parseISO(dateRange.startDate)
        : undefined,
      endDate: dateRange.endDate ? parseISO(dateRange.endDate) : undefined,
    }),
    [dateRange.startDate, dateRange.endDate]
  );

  return (
    <Row indent={3} spacing={2} gap className="t_date_range">
      <DateRangeDualTextInput
        value={parsedDateRange}
        onValueChange={onDateRangeChanged}
      />
      {contentLeft}
      {contentRight}
    </Row>
  );
};
