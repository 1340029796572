import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { FC, ReactNode, useEffect, useState } from "react";
import { BookingActionsModal } from "./BookingActionsModal";

interface SelectorOption {
  label: string;
  disabled?: boolean;
}

interface OptionsModalProps {
  headerText: string;
  subHeaderText: string;
  actionText?: string;
  cancelText?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (selectedIndex: number) => void;
  options: SelectorOption[];
  loading: boolean;
  initialSelected: number | null;
  children?: ReactNode;
}

export const SelectSingleModal: FC<OptionsModalProps> = ({
  isOpen,
  options,
  onSubmit,
  initialSelected,
  children,
  ...props
}) => {
  const [selected, setSelected] = useState<number | null>(initialSelected);

  useEffect(() => {
    if (isOpen) {
      setSelected(initialSelected);
    }
  }, [isOpen, initialSelected]);

  return (
    <BookingActionsModal
      {...props}
      isOpen={isOpen}
      onSubmit={
        selected != null
          ? () => {
              onSubmit(selected);
            }
          : undefined
      }
    >
      {options.map((option, index) => {
        return (
          <RadioButtonWithLabel
            className="t_selected"
            key={index}
            label={option.label}
            disabled={option.disabled}
            checked={selected === index}
            onClick={() => setSelected(index)}
          />
        );
      })}
      {children}
    </BookingActionsModal>
  );
};
