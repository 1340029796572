import backgroundUrl from "./resources/kiel.jpg";
import { Box, Space } from "@stenajs-webui/core";
import { CardBody } from "@stenajs-webui/elements";
import { LoginPageHeader } from "./partials/LoginPageHeader";
import { LoginPrompt } from "./partials/LoginPrompt";
import { ErrorLoginPrompt } from "./partials/ErrorLoginPrompt";

interface Props {
  hasError?: boolean;
}

export const LoginPage: React.FC<Props> = ({ hasError = false }) => (
  <div
    style={{
      backgroundImage: `url(${backgroundUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: "100vh",
      display: "grid",
    }}
  >
    <Box
      minWidth={400}
      minHeight={300}
      maxWidth={400}
      style={{ margin: "auto" }}
      shadow={"modal"}
      background={"white"}
    >
      <CardBody alignItems={"center"}>
        <LoginPageHeader />
        <Space num={4} />
        {hasError ? <ErrorLoginPrompt /> : <LoginPrompt />}
      </CardBody>
    </Box>
  </div>
);
