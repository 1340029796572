import { Row, Spacing } from "@stenajs-webui/core";
import { Card, CardBody } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import { parseISO } from "date-fns";
import { useState } from "react";
import {
  FormIntermodalBookingFragment,
  FormIntermodalBookingLegFragment,
  Route,
} from "../../../generated";
import { BOOKING_TABLE_BORDER } from "../../bookings/bookings-table/components/BookingTableRow";
import { ConnectionsColumn } from "../../common/bookings/ConnectionsColumn";
import {
  transformBookingToRouteLegInfo,
  transformIntermodalBookingToRouteLegProps,
} from "../../common/utils/connectionsUtils";
import { formatArrivalDateTime } from "../../common/utils/dateUtils";
import { isTrainBooking } from "../utils/bookingDetailsUtils";
import { BookingDetailsCardHeader } from "./BookingsDetailsCardHeader";
import { SelectedBookingIndexContextProvider } from "./SelectedBookingIndexContext";
import { CargoColumn } from "./columns/CargoColumn";
import { CustomerColumn } from "./columns/CustomerColumn";
import { DepartureColumn } from "./columns/DepartureColumn";
import { DetailsColumn } from "./columns/DetailsColumn";
import { VehicleColumn } from "./columns/VehicleColumn";

interface Props {
  intermodalBooking: FormIntermodalBookingFragment;
  firstBooking: FormIntermodalBookingLegFragment;
}

export const BookingDetailsView: React.FC<Props> = ({
  intermodalBooking,
  firstBooking,
}) => {
  const [selectedBookingIndex, setSelectedBookingIndex] = useState<
    number | null
  >(null);
  const selectedBooking =
    selectedBookingIndex != null
      ? intermodalBooking.bookings[selectedBookingIndex]
      : null;

  const trainBookings = intermodalBooking.bookings.filter(isTrainBooking);
  const route = selectedBooking?.sailing.route ?? intermodalBooking.route;
  const scheduledArrivalDate =
    selectedBooking?.sailing.scheduledArrival ??
    intermodalBooking.lastSailing.scheduledArrival;
  const publicArrivalDate =
    selectedBooking?.sailing.publicArrival ??
    intermodalBooking.lastSailing.publicArrival;
  const booking = selectedBooking ?? firstBooking;

  const editable = selectedBooking
    ? selectedBooking.isEditable
    : !intermodalBooking.isReadOnly;
  const isTrain = selectedBooking?.sailing.isTrain ?? true;

  const showUpdateButton = intermodalBooking.bookings.some((b) => b.isEditable);

  const intermodalDepartureArrival = transformIntermodalBookingToRouteLegProps(
    intermodalBooking.firstSailing,
    intermodalBooking.lastSailing
  );

  return (
    <SelectedBookingIndexContextProvider value={selectedBookingIndex}>
      <Spacing num={2} gap={2}>
        <Card>
          <BookingDetailsCardHeader
            intermodalBooking={intermodalBooking}
            editable={editable}
            selectedBooking={selectedBooking ?? intermodalBooking}
            showUpdateButton={showUpdateButton}
          />
          <CardBody overflow={"auto"}>
            <Row border={BOOKING_TABLE_BORDER} width={1800}>
              <ConnectionsColumn
                background={cssColor("--lhds-color-ui-100")}
                routeLegInfos={intermodalBooking.bookings.map(
                  transformBookingToRouteLegInfo
                )}
                selectedBookingIndex={selectedBookingIndex}
                setSelectedBookingIndex={setSelectedBookingIndex}
                intermodalDepartureArrival={intermodalDepartureArrival}
              />
              <DepartureColumn
                booking={booking}
                routeOptions={intermodalBooking.alternativeRoutes?.map(
                  transformRouteToOption
                )}
                selectedRoute={transformRouteToOption(route)}
                intermodalBookingSelected={selectedBookingIndex == null}
                scheduledArrivalDate={formatArrivalDateTime(
                  parseISO(scheduledArrivalDate)
                )}
                editable={editable}
                publicArrivalDate={formatArrivalDateTime(
                  parseISO(publicArrivalDate)
                )}
              />
              <VehicleColumn
                editable={editable}
                showCraneableStatus={isTrain}
              />
              <CargoColumn
                editable={editable}
                shouldShowPlugin={intermodalBooking.enablePlugin}
              />
              <DetailsColumn
                selectedBooking={selectedBooking}
                trainBookings={trainBookings}
                allBookings={intermodalBooking.bookings}
                editable={editable}
                dropOffRefEditable={
                  !intermodalBooking.isDropOffReferenceReadOnly
                }
                pickUpRefEditable={!intermodalBooking.isPickUpReferenceReadOnly}
              />
              <CustomerColumn
                intermodalBooking={intermodalBooking}
                booking={booking}
                editable={editable}
              />
            </Row>
          </CardBody>
        </Card>
      </Spacing>
    </SelectedBookingIndexContextProvider>
  );
};

const transformRouteToOption = (route: Pick<Route, "id" | "description">) => ({
  label: route.description,
  value: route.id,
});
