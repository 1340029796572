import { PickByValue } from "@stenajs-webui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import { ConditionallyEditableTextArea } from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableTextArea";
import {
  CreateIntermodalBookingFormModel,
  getIntermodalCreateFormField,
  setCreateIntermodalFormValue,
} from "../../redux/formSlice";
import { TextAreaProps } from "@stenajs-webui/forms";

interface Props
  extends Omit<TextAreaProps, "value" | "onValueChange" | "name"> {
  name: keyof PickByValue<CreateIntermodalBookingFormModel, string>;
}

export const CreateBookingTextArea: React.FC<Props> = ({ name, ...props }) => {
  const value = useSelector((state: RootState) =>
    getIntermodalCreateFormField(state, name)
  );
  const dispatch = useDispatch();

  return (
    <ConditionallyEditableTextArea
      {...props}
      editable
      value={value ?? ""}
      onValueChange={(value) =>
        dispatch(
          setCreateIntermodalFormValue({
            values: { [name]: value },
          })
        )
      }
    />
  );
};
