import { configureStore } from "@reduxjs/toolkit";
import { createIntermodalBookingFormReducer } from "../../use-case/booking-create/redux/formSlice";
import { updateIntermodalBookingFormReducer } from "../../use-case/booking-details/redux/formSlice";
import { userReducer } from "../../use-case/top-menu/userSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    updateIntermodalBookingForm: updateIntermodalBookingFormReducer,
    createIntermodalBookingForm: createIntermodalBookingFormReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
