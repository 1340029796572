import {
  FormIntermodalBookingFragment,
  IntermodalJourneyLegFragment,
} from "../../../generated";
import { first } from "lodash";

export const formatBookingRoute = (
  route: FormIntermodalBookingFragment["route"]
): string => {
  return route.departureLocation + " - " + route.arrivalLocation;
};

export const formatCheckInBookingRoute = (
  route: FormIntermodalBookingFragment["route"],
  isTrain: boolean
): string => {
  return formatBookingRoute(route) + (!isTrain ? " (Sailing)" : "");
};

export const formatLaterDepartureForJourney = (
  legs: IntermodalJourneyLegFragment[] | undefined,
  intermodalBooking: FormIntermodalBookingFragment
) => {
  const firstLeg = first(legs);

  return firstLeg
    ? formatLaterDepartureOption(firstLeg.sailing.route, intermodalBooking)
    : undefined;
};

export const formatLaterDepartureOption = (
  firstRoute: IntermodalJourneyLegFragment["sailing"]["route"],
  intermodalBooking: FormIntermodalBookingFragment
) => {
  const parentRoute = intermodalBooking.route;

  return firstRoute.departureLocation + " - " + parentRoute.arrivalLocation;
};
