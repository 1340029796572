import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { Row, Indent, Heading } from "@stenajs-webui/core";
import { Icon } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  icon: IconDefinition;
  heading: string;
}

export const ColumnHeader: React.FC<Props> = ({ icon, heading }) => {
  return (
    <Row background={"white"} indent={2} spacing={2}>
      <Icon color={cssColor("--lhds-color-ui-700")} icon={icon} size={16} />
      <Indent />
      <Heading variant={"h5"}>{heading}</Heading>
    </Row>
  );
};
