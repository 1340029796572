import { Configuration } from "@azure/msal-browser";

const locationHost = window.location.origin;

export const msalConfig: Configuration = {
  auth: {
    clientId: "0058d7d8-8a97-4cc8-bf06-98f75db6d234", // This is the ONLY mandatory field that you need to supply.
    authority:
      "https://login.microsoftonline.com/5d0501ba-8bc1-464e-b3ad-9e2c8779c55c", // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: locationHost, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const scopes = [
  `api://b27a459a-ceb4-4952-8b86-19354209ebca/IntermodalAPI`,
];
