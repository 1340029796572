import { Icon, stenaTags } from "@stenajs-webui/elements";
import { Checkbox } from "@stenajs-webui/forms";
import { TableHeadItem, TableHeadRow } from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import { tableColumns } from "../common/TableColumns";
import { IntermodalBookingTableViewModel } from "../models/IntermodalBookingsTableViewModel";
import { SortState, SortStateKey } from "../types/SortState";
import {
  areAllIntermodalBookingsIndeterminatelySelected,
  areAllIntermodalBookingsSelected,
} from "../utils/selectIdsUtil";
import { getSortingArrow, isSortingActive } from "../utils/sortingUtils";
import { BOOKING_TABLE_BORDER } from "./BookingTableRow";

interface BookingsTableHeadRowProps {
  intermodalBookings: IntermodalBookingTableViewModel[];
  selectedBookingIds: string[];
  onSelectAll: (value: boolean) => void;
  sortState: SortState;
  onClickSort: (key: SortStateKey) => void;
}

export const BookingsTableHeadRow = ({
  intermodalBookings,
  onSelectAll,
  selectedBookingIds,
  sortState,
  onClickSort,
}: BookingsTableHeadRowProps) => {
  return (
    <TableHeadRow
      position={"sticky"}
      top={0}
      background={"inherit"}
      zIndex={1}
      borderBottom={BOOKING_TABLE_BORDER}
    >
      <TableHeadItem minWidth={"52px"} />
      <TableHeadItem minWidth={"40px"} width={"40px"}>
        <Checkbox
          disabled={intermodalBookings.length === 0}
          indeterminate={areAllIntermodalBookingsIndeterminatelySelected(
            intermodalBookings,
            selectedBookingIds
          )}
          value={areAllIntermodalBookingsSelected(
            intermodalBookings,
            selectedBookingIds
          )}
          size={"small"}
          onValueChange={onSelectAll}
        />
      </TableHeadItem>
      {tableColumns.map((column) => (
        <TableHeadItem
          key={column.id}
          minWidth={column.width}
          width={column.width}
          label={column.id === "specialProperties" ? "" : column.label}
          selected={isSortingActive(column.id, sortState)}
          borderBottom={
            isSortingActive(column.id, sortState)
              ? "1px solid " + cssColor("--lhds-color-blue-500")
              : "1px solid transparent"
          }
          onClick={() => onClickSort(column.id)}
          arrow={getSortingArrow(column.id, sortState)}
          children={
            column.id === "specialProperties" ? <Icon icon={stenaTags} /> : null
          }
        />
      ))}
    </TableHeadRow>
  );
};
