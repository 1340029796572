import { Box, Indent, Row, Text, Txt } from "@stenajs-webui/core";
import { Label, stenaSailingLocation } from "@stenajs-webui/elements";
import { Select } from "@stenajs-webui/select";
import { format, parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import { FormIntermodalBookingLegFragment } from "../../../../generated";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import { MismatchIndicator } from "../../../common/form-fields/MismatchIndicator";
import {
  formatDateTimeISO,
  formatTimeISO,
} from "../../../common/utils/dateUtils";
import {
  getIntermodalBookingPartnerConfirmedMismatchValue,
  getPartnerConfirmedValue,
} from "../../redux/formSlice";
import { useSelectedBookingIndex } from "../SelectedBookingIndexContext";
import { UpdateBookingDateInput } from "../form-fields/UpdateBookingDateInput";
import { UpdateIntermodalBookingSelect } from "../form-fields/UpdateIntermodalBookingSelect";

interface Props {
  selectedRoute: { label: string; value: string };
  routeOptions: { label: string; value: string }[] | undefined | null;
  scheduledArrivalDate: string;
  publicArrivalDate: string;
  editable: boolean;
  intermodalBookingSelected: boolean;
  booking: FormIntermodalBookingLegFragment;
}

const getSailingMismatch = (
  state: RootState,
  selectedBookingIndex: number | null
) => {
  const partnerConfirmedValueIsMismatched =
    getIntermodalBookingPartnerConfirmedMismatchValue(
      state,
      "sailingId",
      selectedBookingIndex
    ) !== undefined;

  const partnerConfirmedValue = getPartnerConfirmedValue(
    state,
    "sailing",
    selectedBookingIndex
  );

  return partnerConfirmedValueIsMismatched ? partnerConfirmedValue : undefined;
};

export const DepartureColumn: React.FC<Props> = ({
  routeOptions,
  selectedRoute,
  scheduledArrivalDate,
  editable,
  intermodalBookingSelected,
  booking,
  publicArrivalDate,
}) => {
  const selectedBookingIndex = useSelectedBookingIndex();
  const scheduledDepartureDate = parseISO(booking.sailing.scheduledDeparture);

  const sailingMismatch = useSelector((state: RootState) =>
    getSailingMismatch(state, selectedBookingIndex)
  );

  const showUpdateRouteSelect =
    intermodalBookingSelected &&
    routeOptions != null &&
    routeOptions.length > 1;

  return (
    <BookingDetailsColumn
      heading="Departure"
      icon={stenaSailingLocation}
      borderRight
      gap={4}
    >
      <Label text="Intermodal route">
        {showUpdateRouteSelect ? (
          <UpdateIntermodalBookingSelect
            className="t_intermodal_route"
            editable={editable}
            name={"routeCode"}
            options={routeOptions ?? []}
          />
        ) : editable ? (
          <Select
            className="t_intermodal_leg_route"
            isDisabled
            value={selectedRoute}
          />
        ) : (
          <Txt>{selectedRoute?.label || "–"}</Txt>
        )}
      </Label>
      <Row>
        <Box width={"160px"}>
          <Label text="Date">
            <UpdateBookingDateInput
              className="t_date"
              value={format(scheduledDepartureDate, "yyyy-MM-dd")}
              editable={editable}
            />
          </Label>
        </Box>
        <Indent />
        <Box width={"90px"}>
          <Label text="Time">
            <Row alignItems={"center"}>
              {editable ? (
                <Select
                  className="t_time"
                  value={{
                    label: formatTimeISO(scheduledDepartureDate),
                    value: booking.sailing.id,
                  }}
                  isDisabled
                />
              ) : (
                <Txt>{formatTimeISO(scheduledDepartureDate)}</Txt>
              )}
            </Row>
          </Label>
        </Box>
      </Row>
      <Box>
        {publicArrivalDate !== scheduledArrivalDate && (
          <Text
            variant={"overline"}
            style={{ textDecoration: "line-through" }}
            size={"small"}
          >
            Arrives {scheduledArrivalDate}
          </Text>
        )}
        <Row gap>
          <Text variant={"overline"} size={"small"}>
            Arrives {publicArrivalDate}
          </Text>
          {sailingMismatch && (
            <MismatchIndicator
              message={formatDateTimeISO(
                parseISO(sailingMismatch.scheduledArrival)
              )}
            />
          )}
        </Row>
      </Box>
    </BookingDetailsColumn>
  );
};
