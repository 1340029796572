import { useQuery } from "@apollo/client";
import { Box, Row, SeparatorLine, Txt } from "@stenajs-webui/core";
import {
  FlatButton,
  Label,
  stenaCopy,
  stenaPlusCircle,
  stenaSailingCargo,
} from "@stenajs-webui/elements";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  GetGoodsTypesDocument,
  GetGoodsTypesQuery,
} from "../../../../generated";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import { MAX_LENGTH_TEMPERATURE } from "../../../common/bookings/fieldLengths";
import { GoodsDetail } from "../../../common/form-fields/GoodsDetail";
import { MismatchIndicator } from "../../../common/form-fields/MismatchIndicator";
import { pluginOptions } from "../../../common/options/plugins";
import {
  addBookGood,
  getIntermodalBookingBookGoods,
  getTotalGoodsWeight,
  getTotalWeightMismatchedValue,
  removeBookGood,
  setBookGood,
  getShowHazardousGoodsStatus,
} from "../../redux/formSlice";
import { useSelectedBookingIndex } from "../SelectedBookingIndexContext";
import { UpdateIntermodalBookingSelect } from "../form-fields/UpdateIntermodalBookingSelect";
import { UpdateIntermodalBookingTextInput } from "../form-fields/UpdateIntermodalBookingTextInput";
import { hazardousGoodsOptions } from "../../../common/options/hazardousGoods";
import { UpdateBookingHazardousGoodsStatus } from "../form-fields/UpdateBookingHazardousGoodsStatus";

interface Props {
  editable: boolean;
  shouldShowPlugin: boolean;
}

export const CargoColumn: React.FC<Props> = ({
  editable,
  shouldShowPlugin,
}) => {
  const selectedBookingIndex = useSelectedBookingIndex();
  const { loading, data } = useQuery<GetGoodsTypesQuery>(GetGoodsTypesDocument);

  const dispatch = useDispatch();

  const goodsTypeOptions =
    data?.goodsTypes.map((goodsType) => ({
      value: goodsType.id,
      label: goodsType.description,
    })) ?? [];

  const bookGoodsFormModel = useSelector((state: RootState) =>
    getIntermodalBookingBookGoods(state, selectedBookingIndex)
  );

  const goodsEditable = editable && selectedBookingIndex == null;

  const totalGoodsWeight = useSelector((state: RootState) =>
    getTotalGoodsWeight(state, selectedBookingIndex)
  );

  const totalWeightMismatchedValue = useSelector((state: RootState) =>
    getTotalWeightMismatchedValue(state, selectedBookingIndex)
  );

  const showHazardousGoodsStatus = useSelector((state: RootState) =>
    getShowHazardousGoodsStatus(state)
  );

  return (
    <BookingDetailsColumn
      heading="Cargo"
      icon={stenaSailingCargo}
      borderRight
      gap={2}
    >
      <Row gap={2}>
        <Box flex={1}>
          <Label text="Hazardous goods">
            <UpdateIntermodalBookingSelect
              className="t_hazardous_goods"
              name={"hazardousGoods"}
              isLoading={loading}
              options={hazardousGoodsOptions}
              editable={editable}
            />
          </Label>
        </Box>
        {showHazardousGoodsStatus && (
          <Box flex={1}>
            <Label text="Status">
              <UpdateBookingHazardousGoodsStatus
                className="t_hazardous_goods_status"
                selectedBookingIndex={selectedBookingIndex}
              />
            </Label>
          </Box>
        )}
      </Row>

      {shouldShowPlugin && (
        <Row gap={2}>
          <Box flex={1}>
            <Label text="Plugin">
              <UpdateIntermodalBookingSelect
                className="t_plugin"
                name={"numberOfPlugins"}
                editable={editable}
                options={pluginOptions}
              />
            </Label>
          </Box>
          <Box flex={1}>
            <Label text="Temp (°C)">
              <UpdateIntermodalBookingTextInput
                className="t_temperature"
                editable={editable}
                name={"temperature"}
                maxLength={MAX_LENGTH_TEMPERATURE}
              />
            </Label>
          </Box>
        </Row>
      )}
      {bookGoodsFormModel.map((bookGoods, index) => (
        <GoodsDetail
          index={index}
          key={bookGoods.id ?? index}
          loading={loading}
          value={bookGoodsFormModel[index]}
          goodsTypeOptions={goodsTypeOptions}
          editable={goodsEditable}
          onSetBookGood={(bookGood) => dispatch(setBookGood(bookGood))}
          onRemoveBookGood={(index) => dispatch(removeBookGood({ index }))}
        />
      ))}
      <Row>
        {goodsEditable && (
          <FlatButton
            className="t_add_goods"
            leftIcon={stenaPlusCircle}
            label="Add goods"
            onClick={() => {
              dispatch(addBookGood());
            }}
          />
        )}
      </Row>
      <SeparatorLine />
      <Box>
        <Label text="Total goods weight (kg)" />
        <Row alignItems={"center"} gap>
          {totalWeightMismatchedValue != null && (
            <MismatchIndicator
              message={totalWeightMismatchedValue.toString()}
            />
          )}
          <Txt>{totalGoodsWeight}</Txt>
          <FlatButton
            className="t_copy_weight"
            leftIcon={stenaCopy}
            onClick={() =>
              navigator.clipboard.writeText(totalGoodsWeight.toString())
            }
          />
        </Row>
      </Box>
    </BookingDetailsColumn>
  );
};
