import { Row, SeparatorLine, Spacing, Txt } from "@stenajs-webui/core";
import { Icon, stenaClock } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";

interface Props {
  time: string;
  valid: boolean;
}

export const TimeGap: React.FC<Props> = ({ time, valid }) => {
  return (
    <>
      <SeparatorLine />
      <Spacing num={0.5} />
      <Row justifyContent={"center"} gap>
        <Icon
          icon={stenaClock}
          color={
            valid
              ? cssColor("--lhds-color-orange-600")
              : cssColor("--lhds-color-red-600")
          }
        />
        <Txt
          variant="overline"
          size={"smaller"}
          color={valid ? undefined : cssColor("--lhds-color-red-600")}
        >
          {time}
        </Txt>
      </Row>
      <Spacing num={0.5} />
      <SeparatorLine />
    </>
  );
};
