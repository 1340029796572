import { Space, Txt } from "@stenajs-webui/core";
import { Select, SelectProps } from "@stenajs-webui/select";
import { ReactNode } from "react";
import { components } from "react-select";

export type TypedOption<TValue, TData> =
  | {
      value: TValue;
      label: string;
      data: TData;
    }
  | {
      value: TValue;
      label: string;
    };

export interface ConditionallyEditableSelectProps<
  TValue,
  TData,
  TOption extends TypedOption<TValue, TData>
> extends Pick<SelectProps, "isDisabled" | "isLoading" | "placeholder"> {
  contentLeft?: ReactNode;
  options?: TOption[];
  editable: boolean;
  bold?: boolean;
  value: TValue;
  onValueChange: (option: TOption) => void;
  className?: string;
}

// we need to add contentLeft prop
const Control = (props: any) => (
  <components.Control {...props}>
    {props.selectProps.contentLeft && (
      <>
        <Space />
        {props.selectProps.contentLeft}
      </>
    )}
    {props.children}
  </components.Control>
);

export function ConditionallyEditableSelect<
  TValue,
  TData,
  TOption extends TypedOption<TValue, TData>
>({
  options,
  editable,
  onValueChange,
  contentLeft,
  value,
  bold,
  ...props
}: ConditionallyEditableSelectProps<TValue, TData, TOption>) {
  const selectedOption = options?.find((option) => option.value === value);

  return editable ? (
    <Select
      {...props}
      // @ts-ignore we need to add contentLeft prop to pass it to <Control />
      contentLeft={contentLeft}
      styles={{
        valueContainer: (base) => ({
          ...base,
          fontWeight: bold ? "var(--swui-font-weight-text-bold)" : undefined,
        }),
      }}
      components={{ Control }}
      menuPlacement={"auto"}
      menuPosition={"fixed"}
      menuShouldBlockScroll
      menuPortalTarget={document.getElementById("popup-root")}
      options={options}
      value={selectedOption ?? null}
      onChange={(option) => {
        if (option) {
          onValueChange(option);
        }
      }}
    />
  ) : (
    <Txt>{selectedOption?.label ?? "–"}</Txt>
  );
}
