import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
  Icon,
  stenaHazardous,
  stenaPlugin,
  stenaSailingCrane,
  stenaTags,
} from "@stenajs-webui/elements";
import { Tooltip } from "@stenajs-webui/tooltip";
import { first, uniq } from "lodash";
import {
  SpecialProperty,
  stringifySpecialProperties,
} from "../models/SpecialProperty";

interface Props {
  values: SpecialProperty[];
}

export const SpecialPropertyIcon = ({ values }: Props) => {
  const icon = getBookingSpecialPropertyIcon(values);

  if (icon) {
    return (
      <Tooltip label={stringifySpecialProperties(values)}>
        <Icon icon={icon} />
      </Tooltip>
    );
  }

  return null;
};

function getBookingSpecialPropertyIcon(
  values: SpecialProperty[]
): IconDefinition | null {
  if (uniq(values).length > 1) {
    return stenaTags;
  }
  const value = first(values);
  switch (value) {
    case SpecialProperty.Hazardous:
      return stenaHazardous;
    case SpecialProperty.NonCraneable:
      return stenaSailingCrane;
    case SpecialProperty.Plugin:
      return stenaPlugin;
    default:
      return null;
  }
}
