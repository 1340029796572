import { first, last } from "lodash";
import { SingleValidationResult } from "../../../common/validation/types";
import { parseFloatElseThrow } from "../../../common/validation/validation-old";
import {
  FormIntermodalBookingFragment,
  FormIntermodalBookingLegFragment,
  IntermodalBookingInput,
  IntermodalBookingLegInput,
  PartnerBookingConfirmedFieldsFragment,
  UpdateBookGoodsInput,
} from "../../../generated";
import {
  BookGoodsFormModel,
  IntermodalBookingFormModel,
  IntermodalBookingLegFormModel,
  IntermodalBookingLegPartnerConfirmedModel,
  IntermodalFormModel,
} from "./formSlice";

const transformPartnerBookingFields = (
  booking: PartnerBookingConfirmedFieldsFragment
): Partial<IntermodalBookingLegPartnerConfirmedModel> => ({
  craneable: booking.craneable,
  iluCode: booking.iluCode ?? "",
  vehicleHeight: booking.height.toString(),
  vehicleLength: booking.length.toString(),
  vehicleWidth: booking.width.toString(),
  vehicleTypeCode: booking.vehicleType.id,
  vehicleRegistration: booking.vehicleReg ?? "",
  sailingId: booking.sailing.id,
  sailing: {
    scheduledDeparture: booking.sailing.scheduledDeparture,
    scheduledArrival: booking.sailing.scheduledArrival,
  },
  hazardousGoodsStatus: booking.hazardousGoodsStatus,
  loadingNote: booking.loadingNote ?? "",
  emptyVehicleWeight: booking.weight.toString(),
  totalGoodsWeight: booking.totalGoodsWeight,
});

const transformIntermodalBookingLegFormModel = (
  booking: FormIntermodalBookingLegFragment
): IntermodalBookingLegFormModel => ({
  id: booking.id,
  customerNumber: booking.customer.id,
  customerReference: booking.customerRef ?? "",
  legNumber: booking.legNumber,
  routeCode: booking.sailing.route.id,
  iluCode: booking.iluCode ?? "",
  vehicleHeight: booking.height.toString(),
  vehicleLength: booking.length.toString(),
  vehicleWidth: booking.width.toString(),
  vehicleTypeCode: booking.vehicleType.id,
  vehicleRegistration: booking.vehicleReg ?? "",
  bookGoods: booking.bookGoods.map((bg) => ({
    id: bg.id,
    code: bg.goodsType.id,
    weight: bg.weight.toString(),
    description: "",
  })),
  sailingId: booking.sailing.id,
  timestamp: booking.timestamp,
  hazardousGoodsStatus: booking.hazardousGoodsStatus,
  loadingNote: booking.loadingNote ?? "",
  emptyVehicleWeight: booking.weight.toString(),
  partnerBookingNumber: booking.partnerBooking?.number ?? "",
  partnerBookingStatus: booking?.partnerBooking?.status ?? null,
  isTrain: booking.sailing.isTrain,
  enablePlugin: booking.sailing.enablePlugin,
  craneableStatus: booking.craneableStatus,
  isPartOfRoute: booking.isPartOfRoute,
});

const transformIntermodalBookingFormModel = (
  intermodalBooking: FormIntermodalBookingFragment
): IntermodalBookingFormModel => {
  const firstBooking = first(intermodalBooking.bookings);
  const lastBooking = last(intermodalBooking.bookings);

  if (!firstBooking || !lastBooking) {
    throw new Error("No booking legs in the booking");
  }

  const bookingForm = transformIntermodalBookingLegFormModel(firstBooking);

  return {
    ...bookingForm,
    bookGoods: bookingForm.bookGoods ?? [],
    id: intermodalBooking.id,
    pickUpReference: intermodalBooking.pickUpRef ?? "",
    dropOffReference: intermodalBooking.dropOffRef ?? "",
    routeCode: intermodalBooking.route.id,
    userMail: intermodalBooking.userMail ?? "",
    craneable: intermodalBooking.craneable,
    numberOfPlugins: intermodalBooking.numberOfPlugins,
    temperature: intermodalBooking.pluginTemperature ?? "",
    hazardousGoods: intermodalBooking.hazardousGoods,
    hazardousGoodsStatus: intermodalBooking.hazardousGoodsStatus,
  };
};

export const transformIntermodalFormModel = (
  intermodalBooking: FormIntermodalBookingFragment
): IntermodalFormModel => {
  return {
    intermodalForm: transformIntermodalBookingFormModel(intermodalBooking),
    bookingForms: intermodalBooking.bookings.map((booking) =>
      transformIntermodalBookingLegFormModel(booking)
    ),
    partnerConfirmedBookingForms: intermodalBooking.bookings.map((b) => {
      if (b.partnerBooking?.fields) {
        return transformPartnerBookingFields(b.partnerBooking.fields);
      } else {
        return {};
      }
    }),
  };
};

export const safeTransformIntermodalFormModelToIntermodalBookingInput = (
  model: IntermodalFormModel
): SingleValidationResult<IntermodalBookingInput> => {
  try {
    return {
      state: "success",
      result: transformIntermodalFormModelToIntermodalBookingInput(model),
    };
  } catch (e: any) {
    return {
      state: "error",
      error: e,
    };
  }
};

export const transformIntermodalFormModelToIntermodalBookingInput = (
  model: IntermodalFormModel
): IntermodalBookingInput => {
  return {
    id: model.intermodalForm.id,
    dropOffRef: model.intermodalForm.dropOffReference,
    pickUpRef: model.intermodalForm.pickUpReference,
    routeCode: model.intermodalForm.routeCode,
    craneable: model.intermodalForm.craneable,
    numberOfPlugins: model.intermodalForm.numberOfPlugins,
    temperature: model.intermodalForm.temperature,
    hazardousGoods: model.intermodalForm.hazardousGoods,
    bookings: model.bookingForms.map((bookingForm) =>
      transformIntermodalLegFormModelToIntermodalBookingLegInput(
        bookingForm,
        model.intermodalForm.bookGoods
      )
    ),
  };
};

export const transformIntermodalLegFormModelToIntermodalBookingLegInput = (
  model: IntermodalBookingLegFormModel,
  parentBookGoods: BookGoodsFormModel[]
): IntermodalBookingLegInput => {
  const bookGoods = model.bookGoods ?? parentBookGoods;

  return {
    id: model.id,
    sailingId: model.sailingId,
    timestamp: model.timestamp,
    customerNumber: model.customerNumber,
    customerReference: model.customerReference,
    emptyVehicleWeight: parseFloatElseThrow(
      model.emptyVehicleWeight,
      "Empty vehicle weight"
    ),
    hazardousGoodsStatus: model.hazardousGoodsStatus,
    iluCode: model.iluCode,
    loadingNote: model.loadingNote,
    vehicleHeight: parseFloatElseThrow(model.vehicleHeight, "Height"),
    vehicleLength: parseFloatElseThrow(model.vehicleLength, "Length"),
    vehicleRegistration: model.vehicleRegistration,
    vehicleTypeCode: model.vehicleTypeCode,
    vehicleWidth: parseFloatElseThrow(model.vehicleWidth, "Width"),
    partnerBookingNumber: model.partnerBookingNumber,
    partnerBookingStatus: model.partnerBookingStatus,
    bookGoods: bookGoods.map((bookGoods) =>
      transformBookGoodsFormModelToBookGoodsInput(bookGoods)
    ),
    craneableStatus: model.craneableStatus,
  };
};

export const transformBookGoodsFormModelToBookGoodsInput = (
  bookGoodsFormModel: BookGoodsFormModel
): UpdateBookGoodsInput => {
  if (!bookGoodsFormModel.code) {
    throw new Error("Missing goods code");
  }

  return {
    code: bookGoodsFormModel.code,
    id: bookGoodsFormModel.id ?? "0",
    weight: parseFloatElseThrow(bookGoodsFormModel.weight, "Weight"),
  };
};
