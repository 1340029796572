import { DepartureTableBookingViewModel } from "../models/DepartureTableBookingViewModel";

export const resolveDepartureBookingSortProperty = (
  booking: DepartureTableBookingViewModel,
  sortBy: keyof DepartureTableBookingViewModel | undefined
): string | number => {
  if (sortBy == null) {
    return "id";
  }
  switch (sortBy) {
    case "status":
      return booking.status.code;
    case "account":
    case "id":
    case "iluCode":
    case "loadingNote":
    case "intermodalBookingNumber":
    case "partnerBookingNumber":
    case "routeCode":
    default:
      return booking[sortBy] ?? "";
  }
};
