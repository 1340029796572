import { addDays, subDays } from "date-fns";
import { compact } from "lodash";
import { useMemo } from "react";
import { ArrayParam, DateParam, useQueryParams } from "use-query-params";

export interface FilterState {
  endDate: Date;
  startDate: Date;
  routes: string[];
}

const getInitialState = (today = new Date()): FilterState => ({
  startDate: subDays(today, 7),
  endDate: addDays(today, 7),
  routes: [],
});

export const useFilterState = () => {
  const [searchParams, setSearchParams] = useQueryParams({
    startDate: DateParam,
    endDate: DateParam,
    routes: ArrayParam,
  });

  const params = useMemo<FilterState>(() => {
    const defaultState = getInitialState();
    return {
      startDate: searchParams.startDate ?? defaultState.startDate,
      endDate: searchParams.endDate ?? defaultState.endDate,
      routes: compact(searchParams.routes) ?? defaultState.routes,
    };
  }, [searchParams]);

  return [params, setSearchParams] as const;
};
