import { useQuery } from "@apollo/client";
import { useDebounce } from "@stenajs-webui/core";
import { LoadingModal } from "@stenajs-webui/modal";
import { useNavigation } from "react-router-dom";
import { Filter } from "../../common/components/Filter";
import { usePageTitle } from "../../common/routes/PageTitle";
import {
  GetDeparturesDocument,
  GetDeparturesQuery,
  GetDeparturesQueryVariables,
} from "../../generated";
import { formatServerDate } from "../bookings/utils/dateUtils";
import { RouteSelect } from "../common/RouteSelect";
import { DeparturesList } from "./components/DeparturesList";
import { useDeparturesFilterState } from "./useDeparturesFilterState";

export const DeparturesPage = () => {
  const [filter, setFilter] = useDeparturesFilterState();
  const debouncedFilter = useDebounce(filter, 700);
  const navigation = useNavigation();

  usePageTitle("Departures");

  const { loading, error, data } = useQuery<
    GetDeparturesQuery,
    GetDeparturesQueryVariables
  >(GetDeparturesDocument, {
    variables: {
      endDate: formatServerDate(debouncedFilter.endDate),
      startDate: formatServerDate(debouncedFilter.startDate),
      routeCode: debouncedFilter.route ?? "",
    },
    skip: debouncedFilter.route == null,
  });

  const departures = data?.departuresForDateAndRoute.departures ?? [];

  return (
    <>
      <Filter
        onDateRangeChanged={(dateRange) => {
          setFilter({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          });
        }}
        contentLeft={
          <RouteSelect onChange={(value) => setFilter({ route: value })} />
        }
        dateRange={{
          endDate: formatServerDate(debouncedFilter.endDate),
          startDate: formatServerDate(debouncedFilter.startDate),
        }}
      />
      <DeparturesList
        departures={departures}
        error={error != null}
        loading={loading}
      />
      {navigation.state === "loading" && <LoadingModal />}
    </>
  );
};
