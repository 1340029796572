import { ResultListBannerProps } from "@stenajs-webui/elements";
import {
  FormIntermodalBookingLegFragment,
  HazardousGoodsStatus,
  ResultMessage,
  UpdateIntermodalBookingMutation,
} from "../../../generated";
import { bookingIsUpdated } from "../../booking-details/utils/statusUtils";

export const createSuccessUpdateBanner = (): ResultListBannerProps => ({
  variant: "success",
  bannerState: {
    text: "Your changes were saved",
  },
});

export const createSuccessCheckInBanner = (): ResultListBannerProps => ({
  variant: "success",
  bannerState: {
    text: "The booking was checked-in successfully",
  },
});

export const createSuccessCancelBanner = (): ResultListBannerProps => ({
  variant: "success",
  bannerState: {
    text: "The booking was cancelled successfully",
  },
});

export const createMultiBannerFromUpdateResult = (
  updateResult: UpdateIntermodalBookingMutation | null | undefined
): ResultMessage[] | null => {
  if (
    updateResult &&
    updateResult.updateIntermodalBooking.messages.length > 0
  ) {
    return updateResult.updateIntermodalBooking.messages;
  } else {
    return null;
  }
};

export const getBannerUpdatedState = (
  statusCode: string
): ResultListBannerProps | null => {
  if (!bookingIsUpdated(statusCode)) {
    return null;
  }

  return {
    variant: "warning",
    bannerState: {
      headerText: "Update reminder",
      text: "This booking has been updated since the train partner booking(s) was confirmed. Check if the train booking(s) needs to be updated.",
    },
  };
};

export const getBannerInvalidJourneyState = (
  bookings: FormIntermodalBookingLegFragment[]
): ResultListBannerProps | null => {
  if (bookings.every((booking) => booking.departureTimeIsValid)) {
    return null;
  }

  return {
    variant: "warning",
    bannerState: {
      headerText: "No matching intermodal connections",
      text: "The booking contains one or more legs that are overlapping and the unit can't go on the selected departures. Please select new departures for the overlapping legs.",
    },
  };
};

export const getBannerHazardousGoodsState = (
  bookings: FormIntermodalBookingLegFragment[]
): ResultListBannerProps | null => {
  if (
    bookings.some(
      (b) => b.hazardousGoodsStatus === HazardousGoodsStatus.Requested
    ) ||
    (bookings.some(
      (b) => b.hazardousGoodsStatus === HazardousGoodsStatus.Confirmed
    ) &&
      bookings.some(
        (b) => b.hazardousGoodsStatus === HazardousGoodsStatus.NotRequested
      ))
  ) {
    return {
      variant: "info",
      bannerState: {
        headerText: "Hazardous goods",
        text: "Hazardous goods data must be added to all legs in Freightlink.",
      },
    };
  }

  return null;
};
