import { Text } from "@stenajs-webui/core";
import { Tag } from "@stenajs-webui/elements";
import { Link } from "../../../../../common/common-ui/Link";
import { getStatusTag } from "../../../../booking-details/utils/statusUtils";
import { TableColumnSetting } from "../../common/TableColumns";
import { IntermodalBookingTableViewModel } from "../../models/IntermodalBookingsTableViewModel";
import { SpecialPropertyIcon } from "../SpecialPropertyIcon";

export const resolveIntermodalBookingTableCellContent = (
  column: TableColumnSetting,
  intermodalBooking: IntermodalBookingTableViewModel
) => {
  switch (column.id) {
    case "id":
      return (
        <Link
          className="t_intermodal_booking_number"
          to={intermodalBooking.id}
          state={{ prevPath: "bookings" }}
        >
          {intermodalBooking.id}
        </Link>
      );
    case "specialProperties":
      return (
        <SpecialPropertyIcon values={intermodalBooking.specialProperties} />
      );
    case "status":
      return (
        <Tag
          label={intermodalBooking.status.text}
          variant={getStatusTag(intermodalBooking.status.code)}
        />
      );
    case "customerRef":
    case "departure":
    case "customer":
    case "arrival":
    case "iluCode":
    case "loadingNote":
    case "numberOfPlugins":
    case "routeName":
    case "vehicleReg":
    case "vehicleType":
    case "firstPartnerBookingNumber":
    case "secondPartnerBookingNumber":
      return <Text whiteSpace={"nowrap"}>{intermodalBooking[column.id]}</Text>;
    default:
      return null;
  }
};
