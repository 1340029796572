import { parseISO } from "date-fns";
import {
  TableBookingLegFragment,
  TableIntermodalBookingFragment,
} from "../../../generated";
import { formatDateTimeISO } from "../../common/utils/dateUtils";
import { BookingViewModel } from "../bookings-table/models/BookingViewModel";
import { IntermodalBookingTableViewModel } from "../bookings-table/models/IntermodalBookingsTableViewModel";
import { getTrainBookings } from "./getTrainBookings";
import {
  getBookingSpecialProperties,
  getBookingSpecialPropertiesFromBookings,
} from "./specialProperty";

export const transformIntermodalBookingToTableViewModel = (
  intermodalBooking: TableIntermodalBookingFragment
): IntermodalBookingTableViewModel => {
  const b = intermodalBooking.bookings[0];

  const departureDate = parseISO(b.sailing.scheduledDeparture);
  const arrivalDate = parseISO(
    intermodalBooking.bookings[intermodalBooking.bookings.length - 1].sailing
      .publicArrival
  );
  const formattedDepartureDateTime = formatDateTimeISO(departureDate);
  const formattedArrivalDateTime = formatDateTimeISO(arrivalDate);

  const trainBookings = getTrainBookings(intermodalBooking);

  return {
    id: intermodalBooking.id,
    specialProperties:
      getBookingSpecialPropertiesFromBookings(intermodalBooking),
    routeName: intermodalBooking.route.id,
    bookings: intermodalBooking.bookings.map((booking) =>
      createBookingViewModel(
        booking,
        trainBookings.findIndex((b) => b.id === booking.id),
        intermodalBooking.craneable,
        intermodalBooking.numberOfPlugins
      )
    ),
    customer: b.customer.customerIndex,
    customerRef: b.customerRef,
    departure: formattedDepartureDateTime,
    arrival: formattedArrivalDateTime,
    iluCode: b.iluCode,
    loadingNote: b.loadingNote,
    numberOfPlugins: intermodalBooking.numberOfPlugins ? "Yes" : "No",
    status: intermodalBooking.status,
    firstPartnerBookingNumber: trainBookings[0]?.partnerBooking?.number ?? "",
    secondPartnerBookingNumber: trainBookings[1]?.partnerBooking?.number ?? "",
    vehicleReg: b.vehicleReg ?? "",
    firstSailingId: b.sailing.id,
    vehicleType: b.vehicleType.description,
  };
};

const createBookingViewModel = (
  booking: TableBookingLegFragment,
  trainBookingIndex: number,
  craneable: boolean,
  numberOfPlugins: number
): BookingViewModel => {
  const formattedDepartureDateTime = formatDateTimeISO(
    parseISO(booking.sailing.scheduledDeparture)
  );
  const formattedArrivalDateTime = formatDateTimeISO(
    parseISO(booking.sailing.publicArrival)
  );

  const partnerBookingNo = booking?.partnerBooking?.number ?? "";
  return {
    id: booking.id,
    routeName: booking.sailing.route.id,
    customerRef: booking.customerRef,
    customer: booking.customer.customerIndex,
    departure: formattedDepartureDateTime,
    arrival: formattedArrivalDateTime,
    iluCode: booking.iluCode,
    loadingNote: booking.loadingNote,
    numberOfPlugins: "",
    status: booking.status,
    specialProperties: getBookingSpecialProperties(
      booking.hazardousGoodsStatus,
      craneable,
      numberOfPlugins
    ),
    firstPartnerBookingNumber: trainBookingIndex === 0 ? partnerBookingNo : "",
    secondPartnerBookingNumber: trainBookingIndex === 1 ? partnerBookingNo : "",
    vehicleReg: booking.vehicleReg ?? "",
    vehicleType: booking.vehicleType.description,
    isTrain: booking.sailing.isTrain,
    isEditable: booking.isEditable,
  };
};
