import { IntermodalBookingTableViewModel } from "../bookings-table/models/IntermodalBookingsTableViewModel";

export const bookingMatchesSearchInput = (
  booking: IntermodalBookingTableViewModel,
  searchInput: string
): boolean => {
  if (searchInput === "") {
    return true;
  }

  const searchInputLowerCase = searchInput.toLocaleLowerCase();
  return Boolean(
    booking.id.toLocaleLowerCase().includes(searchInputLowerCase) ||
      booking.bookings.some((leg) =>
        leg.id.toLocaleLowerCase().includes(searchInputLowerCase)
      ) ||
      booking.vehicleReg.toLocaleLowerCase().includes(searchInputLowerCase) ||
      booking.customer.toLocaleLowerCase().includes(searchInputLowerCase) ||
      booking.iluCode?.toLocaleLowerCase().includes(searchInputLowerCase) ||
      booking.loadingNote?.toLocaleLowerCase().includes(searchInputLowerCase) ||
      booking.customerRef?.toLocaleLowerCase().includes(searchInputLowerCase)
  );
};
