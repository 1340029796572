import { FC } from "react";
import { FormIntermodalBookingLegFragment } from "../../../../generated";
import { isBookingLegCompletable } from "../../utils/bookingActionsUtils";
import { formatBookingRoute } from "../formatter";
import { SelectMultipleModal } from "./SelectMultipleModal";
import { Tag } from "@stenajs-webui/elements";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (indexesToComplete: number[]) => void;
  bookings: FormIntermodalBookingLegFragment[];
  loading: boolean;
  initialState: boolean[];
}

export const MarkBookingsAsCompletedModal: FC<Props> = ({
  bookings,
  ...restProps
}) => {
  return (
    <SelectMultipleModal
      {...restProps}
      subHeaderText="Select bookings to mark as completed. The bookings will disappear from the To-do list."
      headerText="Mark to-do completed"
      options={bookings.map((b) => ({
        label: formatBookingRoute(b.sailing.route),
        disabled: !isBookingLegCompletable(b),
        contentRight: getBookingStatusTag(b),
      }))}
    />
  );
};

const getBookingStatusTag = (b: FormIntermodalBookingLegFragment) => {
  if (!isBookingLegCompletable(b)) {
    return null;
  }

  if (b.partnerBooking?.isCancelled) {
    return <Tag variant={"error"} label={"Cancelled"} />;
  } else {
    return <Tag variant={"warning"} label={"Updated"} />;
  }
};
