import { TableColumnSetting } from "../../common/TableColumns";
import { BookingViewModel } from "../../models/BookingViewModel";

export const resolveBookingLegPlainTextTableCellContent = (
  column: TableColumnSetting,
  booking: BookingViewModel
): string => {
  switch (column.id) {
    case "status":
      return booking.status.text;
    case "id":
    case "customerRef":
    case "departure":
    case "arrival":
    case "customer":
    case "iluCode":
    case "loadingNote":
    case "numberOfPlugins":
    case "routeName":
    case "vehicleReg":
    case "vehicleType":
    case "firstPartnerBookingNumber":
    case "secondPartnerBookingNumber":
      return booking[column.id] ?? "";
    default:
      return "";
  }
};
