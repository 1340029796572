import logoImage from "../resources/logo_blue_text.svg";
import { Heading, Space } from "@stenajs-webui/core";

export const LoginPageHeader = () => (
  <>
    <img src={logoImage} alt="Stena Line logo" width={150} />
    <Space num={3} />
    <Heading variant={"h3"} as={"h1"}>
      Intermodal admin tool
    </Heading>
  </>
);
