import { SortStateKey } from "../types/SortState";

export interface TableColumnSetting {
  width: number;
  label: string;
  excludeFromPlainText?: boolean;
  id: SortStateKey;
}

export const tableColumns: TableColumnSetting[] = [
  { width: 131, label: "Status", id: "status" },
  { width: 85, label: "Booking", id: "id" },
  {
    width: 40,
    label: "Icon",
    id: "specialProperties",
    excludeFromPlainText: true,
  },
  { width: 65, label: "Route", id: "routeName" },
  { width: 137, label: "Departure", id: "departure" },
  { width: 137, label: "Arrival", id: "arrival" },
  { width: 101, label: "Account", id: "customer" },
  { width: 137, label: "Vehicle reg", id: "vehicleReg" },
  { width: 137, label: "ILU code", id: "iluCode" },
  { width: 128, label: "Vehicle type", id: "vehicleType" },
  { width: 168, label: "Customer ref.", id: "customerRef" },
  { width: 136, label: "1st train booking", id: "firstPartnerBookingNumber" },
  { width: 136, label: "2nd train booking", id: "secondPartnerBookingNumber" },
  { width: 321, label: "Loading note", id: "loadingNote" },
];

export const plainTextTableColumns = tableColumns.filter(
  (t) => !t.excludeFromPlainText
);
