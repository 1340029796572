import { DateTextInput } from "@stenajs-webui/calendar";
import { Box, Row, Txt } from "@stenajs-webui/core";
import { RadioButtonWithLabel } from "@stenajs-webui/forms";
import { FC } from "react";
import { TimeSelect } from "../../../../common/common-ui/TimeSelect";

interface FindLaterDepartureOption {
  label: string;
  disabled: boolean;
}

interface Props {
  options: FindLaterDepartureOption[];
  selectedLegIndex: number;
  setSelectedLegIndex: (index: number) => void;
  date: string;
  setDate: (date: string) => void;
  setTime: (time: string) => void;
  time: string;
}

export const FindLaterDeparture: FC<Props> = ({
  options,
  selectedLegIndex,
  setSelectedLegIndex,
  date,
  setDate,
  time,
  setTime,
}) => {
  return (
    <>
      {options.map((option, index) => {
        return (
          <RadioButtonWithLabel
            key={index}
            label={option.label}
            disabled={option.disabled}
            checked={selectedLegIndex === index}
            onClick={() => setSelectedLegIndex(index)}
          />
        );
      })}
      <Row>
        <Txt>after:</Txt>
      </Row>
      <Row gap>
        <DateTextInput value={date} onValueChange={setDate} />
        <Box width={"100px"}>
          <TimeSelect setTime={setTime} time={time} />
        </Box>
      </Row>
    </>
  );
};
