import { Box } from "@stenajs-webui/core";
import { BreadCrumbs, Crumb } from "@stenajs-webui/elements";
import { PageHeader } from "@stenajs-webui/panels";
import { Link } from "../../../common/common-ui/Link";
import { usePreviousLocation } from "../hooks/previousLocation";

interface Props {
  bookingId: string;
}

export const BookingDetailsHeader: React.FC<Props> = ({ bookingId }) => {
  const parentPage = usePreviousLocation();
  return (
    <Box background={"white"}>
      <PageHeader
        renderBreadCrumbs={() => (
          <BreadCrumbs>
            <Crumb label="INTERMODAL" />
            <Crumb
              render={(props) => <Link to={`/${parentPage}`} {...props} />}
              label={parentPage}
            />
            <Crumb label={bookingId} />
          </BreadCrumbs>
        )}
      />
    </Box>
  );
};
