import { useMemo } from "react";
import { DateParam, StringParam, useQueryParams } from "use-query-params";
import { addDays, subDays } from "date-fns";

export interface DeparturesFilterState {
  endDate: Date;
  startDate: Date;
  route: string | null;
}

const getInitialState = (today = new Date()): DeparturesFilterState => ({
  startDate: subDays(today, 1),
  endDate: addDays(today, 6),
  route: null,
});

export const useDeparturesFilterState = () => {
  const [searchParams, setSearchParams] = useQueryParams({
    startDate: DateParam,
    endDate: DateParam,
    route: StringParam,
  });

  const params = useMemo<DeparturesFilterState>(() => {
    const defaultState = getInitialState();
    return {
      startDate: searchParams.startDate ?? defaultState.startDate,
      endDate: searchParams.endDate ?? defaultState.endDate,
      route: searchParams.route ?? defaultState.route,
    };
  }, [searchParams]);

  return [params, setSearchParams] as const;
};
