const craneableOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const craneableStatusOptions = [
  {
    label: "Requested",
    value: "R",
  },
  {
    label: "Confirmed",
    value: "C",
  },
  {
    label: "Not available",
    value: "NA",
  },
];

export const useCraneableOptions = () => {
  return craneableOptions;
};

export const useCraneableStatusOptions = () => {
  return craneableStatusOptions;
};
