export const formatRegistrationNumberString = (regNo: string): string => {
  return regNo.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
};

export const formatNumericalString = (regNo: string): string => {
  return regNo.replace(/[^0-9]/g, "").toUpperCase();
};

export const formatNumericalDecimalString = (regNo: string): string => {
  return regNo.replace(/[^0-9.,]/g, "").toUpperCase();
};

export const formatCustomerReferenceString = (regNo: string): string => {
  return regNo
    .replace(/[^a-zA-Z0-9/!#$%^&*()_\-+=\\\\|~:;]/g, "")
    .toUpperCase();
};
