import { DateTextInput, TimeTextInput } from "@stenajs-webui/calendar";
import { Box, Column, Row, Spacing, Txt } from "@stenajs-webui/core";
import { Card } from "@stenajs-webui/elements";
import { RadioButton } from "@stenajs-webui/forms";
import {
  StandardTable,
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { parseISO } from "date-fns";
import { orderBy } from "lodash";
import { FC, useMemo, useState } from "react";
import { ENABLE_DEPARTURES_BULK_ACTIONS } from "../../../config/featureFlags";
import { TableDepartureFragment } from "../../../generated";
import { formatDepartureWithMonth } from "../../common/utils/dateUtils";
import { DepartureTableBookingViewModel } from "../models/DepartureTableBookingViewModel";
import { transformDepartureBookingToViewModel } from "../utils/departureTableBookingViewModelUtils";
import { resolveDepartureBookingSortProperty } from "../utils/sortingUtils";
import { DepartureWrapper } from "./DepartureWrapper";
import { config } from "./tableConfig";

type SailingStatus = "NotDeparted" | "Departed" | "Arrived";

interface Props {
  departure: TableDepartureFragment;
}

export const DepartureCard: FC<Props> = ({
  departure: { bookings, sailing },
}) => {
  const [testSailingStatus, setTestSailingStatus] =
    useState<SailingStatus>("NotDeparted");

  const { tableContext } = useLocalStateTableContext<
    keyof DepartureTableBookingViewModel
  >(sailing.id, createStandardTableInitialState("id"));

  const { sortOrder } = tableContext.state;

  const items = useMemo(() => {
    let items = bookings.map((b) => transformDepartureBookingToViewModel(b));
    items = orderBy(
      items,
      (booking) =>
        resolveDepartureBookingSortProperty(booking, sortOrder.sortBy),
      sortOrder.desc ? "desc" : "asc"
    );
    return items;
  }, [bookings, sortOrder.desc, sortOrder.sortBy]);

  const totalActiveBookings = bookings.filter(
    (booking) => booking.status.code !== "X"
  ).length;

  return (
    <Card maxWidth={1200}>
      <Row>
        {ENABLE_DEPARTURES_BULK_ACTIONS && (
          <Box flex={1} borderRight={"1px solid var(--lhds-color-ui-200)"}>
            <Box spacing={2} indent={2}>
              <Txt size={"large"} variant="bold">
                {formatDepartureWithMonth(parseISO(sailing.scheduledDeparture))}
              </Txt>
            </Box>
            <Box spacing={2} indent={2} gap={2}>
              <DepartureWrapper selected={testSailingStatus === "NotDeparted"}>
                <Row gap={2}>
                  <RadioButton
                    className="t_not_departed"
                    size="small"
                    checked={testSailingStatus === "NotDeparted"}
                    onValueChange={() => setTestSailingStatus("NotDeparted")}
                  />
                  <Txt variant="bold">Not departed</Txt>
                </Row>
              </DepartureWrapper>
              <DepartureWrapper selected={testSailingStatus === "Departed"}>
                <Row gap={2}>
                  <Column>
                    <RadioButton
                      className="t_departed"
                      size="small"
                      checked={testSailingStatus === "Departed"}
                      onValueChange={() => setTestSailingStatus("Departed")}
                    />
                  </Column>
                  <Column>
                    <Txt variant="bold">Departed</Txt>
                    <Spacing num={2} />
                    <Txt variant="bold">Est. arrival</Txt>
                  </Column>
                  <Column gap>
                    <DateTextInput />
                    <DateTextInput />
                  </Column>
                  <Column gap>
                    <TimeTextInput />
                    <TimeTextInput />
                  </Column>
                </Row>
              </DepartureWrapper>
              <DepartureWrapper selected={testSailingStatus === "Arrived"}>
                <Row gap={2}>
                  <Column>
                    <RadioButton
                      className="t_arrived"
                      size="small"
                      checked={testSailingStatus === "Arrived"}
                      onValueChange={() => setTestSailingStatus("Arrived")}
                    />
                  </Column>
                  <Column gap={3}>
                    <Txt variant="bold">Arrived</Txt>
                  </Column>
                  <Column gap>
                    <DateTextInput />
                  </Column>
                  <Column gap>
                    <TimeTextInput />
                  </Column>
                </Row>
              </DepartureWrapper>
            </Box>
          </Box>
        )}
        <Box flex={2}>
          <Row spacing={2} indent={2} gap={2}>
            {!ENABLE_DEPARTURES_BULK_ACTIONS && (
              <Txt size={"large"} variant="bold">
                {formatDepartureWithMonth(parseISO(sailing.scheduledDeparture))}
              </Txt>
            )}
            <Txt size={"large"} variant="bold">
              {totalActiveBookings + " bookings"}
            </Txt>
          </Row>
          <StandardTable
            config={config}
            items={items}
            tableContext={tableContext}
          />
        </Box>
      </Row>
    </Card>
  );
};
