import { Box, Row } from "@stenajs-webui/core";
import { Label, stenaSailingTrailer } from "@stenajs-webui/elements";
import { useDispatch, useSelector } from "react-redux";
import {
  formatNumericalDecimalString,
  formatNumericalString,
  formatRegistrationNumberString,
} from "../../../../common/utils/stringUtils";
import { RootState } from "../../../../config/redux/store";
import {
  useCraneableOptions,
  useCraneableStatusOptions,
} from "../../../../services/hooks/useCraneableOptions";
import { useVehicleTypeOptions } from "../../../../services/hooks/useVehicleTypeOptions";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import {
  MAX_LENGTH_EMPTY_VEHICLE_WEIGHT,
  MAX_LENGTH_HEIGHT,
  MAX_LENGTH_ILU_CODE,
  MAX_LENGTH_LENGTH,
  MAX_LENGTH_VEHICLE_REG,
  MAX_LENGTH_WIDTH,
} from "../../../common/bookings/fieldLengths";
import {
  getIntermodalBookingFormValueByIndex,
  setBookingFormValue,
  setIntermodalBookingFormValue,
} from "../../redux/formSlice";
import { useSelectedBookingIndex } from "../SelectedBookingIndexContext";
import { UpdateBookingSelect } from "../form-fields/UpdateBookingSelect";
import { UpdateBookingTextInput } from "../form-fields/UpdateBookingTextInput";
import { UpdateIntermodalBookingSelect } from "../form-fields/UpdateIntermodalBookingSelect";

interface Props {
  editable: boolean;
  showCraneableStatus: boolean;
}

export const VehicleColumn: React.FC<Props> = ({
  editable,
  showCraneableStatus,
}) => {
  const dispatch = useDispatch();
  const selectedBookingIndex = useSelectedBookingIndex();
  const craneable = useSelector((state: RootState) =>
    getIntermodalBookingFormValueByIndex(state, "craneable")
  );
  const { loading, vehicleTypeOptions } = useVehicleTypeOptions();

  const craneableOptions = useCraneableOptions();
  const craneableStatusOptions = useCraneableStatusOptions();

  const onCraneableChanged = (option: typeof craneableOptions[number]) => {
    dispatch(
      setIntermodalBookingFormValue({
        values: { craneableStatus: option.value ? "" : "R" },
      })
    );
  };

  const onVehicleTypeChanged = (option: typeof vehicleTypeOptions[number]) => {
    const { defaultHeight, defaultLength, defaultWeight, defaultWidth } =
      option.data;
    dispatch(
      setBookingFormValue({
        index: selectedBookingIndex,
        values: {
          emptyVehicleWeight: defaultWeight.toString(),
          vehicleWidth: defaultWidth.toString(),
          vehicleHeight: defaultHeight.toString(),
          vehicleLength: defaultLength.toString(),
        },
      })
    );
  };

  return (
    <BookingDetailsColumn
      heading="Vehicle"
      icon={stenaSailingTrailer}
      borderRight
      gap={2}
    >
      <Label text="Vehicle registration">
        <UpdateBookingTextInput
          className="t_vehicle_reg"
          editable={editable}
          showCopyButton
          name="vehicleRegistration"
          selectedBookingIndex={selectedBookingIndex}
          maxLength={MAX_LENGTH_VEHICLE_REG}
          maskInput={formatRegistrationNumberString}
        />
      </Label>
      <Label text="ILU code">
        <UpdateBookingTextInput
          className="t_ilu_code"
          editable={editable}
          showCopyButton
          name="iluCode"
          selectedBookingIndex={selectedBookingIndex}
          maxLength={MAX_LENGTH_ILU_CODE}
          maskInput={formatRegistrationNumberString}
        />
      </Label>
      <Label text="Vehicle type">
        <UpdateBookingSelect
          className="t_vehicle_type"
          name="vehicleTypeCode"
          selectedBookingIndex={selectedBookingIndex}
          isLoading={loading}
          options={vehicleTypeOptions}
          editable={editable}
          onValueChange={onVehicleTypeChanged}
        />
      </Label>
      <Row gap={2}>
        <Box flex={1}>
          <Label text="Craneable">
            <UpdateIntermodalBookingSelect
              className="t_craneable"
              name="craneable"
              isLoading={loading}
              options={craneableOptions}
              editable={editable}
              onValueChange={onCraneableChanged}
            />
          </Label>
        </Box>
        {!craneable && showCraneableStatus && (
          <Box flex={1}>
            <Label text="Status">
              <UpdateBookingSelect
                className="t_craneable_status"
                name="craneableStatus"
                selectedBookingIndex={selectedBookingIndex}
                isLoading={loading}
                options={craneableStatusOptions}
                editable={editable}
              />
            </Label>
          </Box>
        )}
      </Row>
      <Label text="Empty vehicle weight (kg)">
        <UpdateBookingTextInput
          className="t_weight"
          editable={editable}
          showCopyButton
          name="emptyVehicleWeight"
          selectedBookingIndex={selectedBookingIndex}
          maxLength={MAX_LENGTH_EMPTY_VEHICLE_WEIGHT}
          maskInput={formatNumericalString}
        />
      </Label>
      <Row gap={2}>
        <Label text="Length (m)">
          <UpdateBookingTextInput
            className="t_length"
            editable={editable}
            name="vehicleLength"
            selectedBookingIndex={selectedBookingIndex}
            maxLength={MAX_LENGTH_LENGTH}
            maskInput={formatNumericalDecimalString}
          />
        </Label>
        <Label text="Width (m)">
          <UpdateBookingTextInput
            className="t_width"
            editable={editable}
            name="vehicleWidth"
            selectedBookingIndex={selectedBookingIndex}
            maxLength={MAX_LENGTH_WIDTH}
            maskInput={formatNumericalDecimalString}
          />
        </Label>
        <Label text="Height (m)">
          <UpdateBookingTextInput
            className="t_height"
            editable={editable}
            name="vehicleHeight"
            selectedBookingIndex={selectedBookingIndex}
            maxLength={MAX_LENGTH_HEIGHT}
            maskInput={formatNumericalDecimalString}
          />
        </Label>
      </Row>
    </BookingDetailsColumn>
  );
};
