import { ApolloError, isApolloError } from "@apollo/client";
import { ResultListBannerProps } from "@stenajs-webui/elements";
import { GraphQLError } from "graphql/error";
import { first } from "lodash";

export interface ResultMessage {
  message: string;
  code?: string | null;
}

type GQLErrorCode = "UNAUTHORIZED" | "INTERNAL_SERVER_ERROR";

export interface IntermodalGQLError {
  code?: GQLErrorCode;
  name: string;
  message: string;
  errors?: ResultMessage[];
}

interface NormalizedError {
  message: string;
  errors?: string[];
}

export const getIntermodalGQLError = (
  firstGraphQLError: GraphQLError | undefined
): IntermodalGQLError | undefined => {
  if (firstGraphQLError && "exception" in firstGraphQLError.extensions) {
    const exception = firstGraphQLError.extensions
      .exception as IntermodalGQLError;
    exception.message = firstGraphQLError.message;
    exception.code = firstGraphQLError.extensions.code as GQLErrorCode;

    return exception;
  }
};

const getErrorsFromApolloError = (
  apolloError: ApolloError
): ResultMessage[] | null => {
  const graphQLError = first(apolloError.graphQLErrors);

  return getIntermodalGQLError(graphQLError)?.errors ?? null;
};

export class ValidationError extends Error {
  constructor(public errors: string[]) {
    super("One or more validation errors occurred.");

    Object.setPrototypeOf(this, ValidationError.prototype);
    Object.defineProperty(this, "name", { value: "ValidationError" });
  }
}

export const mapResultMessage = ({ message }: ResultMessage): string => message;

export const normalizeError = (
  error: ValidationError | ApolloError | IntermodalGQLError | Error
): NormalizedError => {
  if (error instanceof ValidationError) {
    return { message: error.message, errors: error.errors };
  } else {
    const errors = isApolloError(error)
      ? getErrorsFromApolloError(error)
      : "errors" in error
      ? error.errors
      : [];

    return {
      message: error.message,
      errors: errors?.map(mapResultMessage) ?? [],
    };
  }
};

export const getBannerErrorState = (
  error:
    | ValidationError
    | ApolloError
    | IntermodalGQLError
    | Error
    | null
    | undefined
): ResultListBannerProps | null => {
  if (!error) {
    return null;
  }

  const normalizedError = normalizeError(error);

  return {
    variant: "error",
    bannerState: {
      headerText: normalizedError.message || "Something went wrong",
      items: normalizedError.errors?.map((text) => ({ text })),
    },
  };
};
