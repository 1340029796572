import {
  TableBookingLegFragment,
  TableIntermodalBookingFragment,
} from "../../../generated";

export function getTrainBookings(
  intermodalBooking: TableIntermodalBookingFragment
): TableBookingLegFragment[] {
  return intermodalBooking.bookings.filter((b) => b.sailing.isTrain);
}
