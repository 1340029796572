import { parseFloatElseUndefined } from "@stenajs-webui/core";
import { normalizeFloatStringValue } from "./validation";

export const parseFloatElseThrow = (s: string, fieldName: string): number => {
  if (s.length === 0) {
    throw new Error(`${fieldName} is empty`);
  }

  const x = parseFloatElseUndefined(normalizeFloatStringValue(s));

  if (x == null) {
    throw new Error(`${fieldName}: "${s}" is not a number`);
  }

  return x;
};
