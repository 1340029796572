import {
  Box,
  Column,
  Heading,
  Row,
  SeparatorLine,
  Txt,
} from "@stenajs-webui/core";
import {
  FlatButton,
  ResultListBanner,
  stenaTimes,
} from "@stenajs-webui/elements";
import { Drawer } from "@stenajs-webui/modal";
import { TodoFragment } from "../../../generated";
import { TodoItem } from "./TodoItem";
import { ResultMessage } from "../../common/utils/apolloErrorUtils";

interface Props {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  todos: TodoFragment[];
  messages: ResultMessage[];
}

export const NotificationCenterDrawer: React.FC<Props> = ({
  isDrawerOpen,
  closeDrawer,
  todos,
  messages,
}) => {
  const errorMessages = messages.map((message) => ({ text: message.message }));
  return (
    <Drawer
      slideFrom={"right"}
      isOpen={isDrawerOpen}
      onRequestClose={closeDrawer}
      width={"300px"}
    >
      <Column width={"100%"} background={"white"} height={"100%"}>
        <Row indent={2} spacing={2} justifyContent={"space-between"}>
          <Row alignItems={"center"}>
            <Heading variant={"h4"} as={"h2"}>
              To-do
            </Heading>
          </Row>
          <Row>
            <FlatButton onClick={closeDrawer} leftIcon={stenaTimes} />
          </Row>
        </Row>
        <SeparatorLine />
        {errorMessages.length > 0 ? (
          <ResultListBanner
            variant={"warning"}
            bannerState={{
              headerText: "The following statuses could not be loaded",
              items: errorMessages,
            }}
          />
        ) : null}
        {todos.length > 0 ? (
          todos.map((todo, index) => (
            <TodoItem key={index} todo={todo} onClick={closeDrawer} />
          ))
        ) : (
          <Box spacing={4} indent={2}>
            <Txt size={"small"}>No booking requests</Txt>
          </Box>
        )}
      </Column>
    </Drawer>
  );
};
