import { TableColumnSetting } from "../../common/TableColumns";
import { IntermodalBookingTableViewModel } from "../../models/IntermodalBookingsTableViewModel";

export const resolveIntermodalBookingPlainTextTableCellContent = (
  column: TableColumnSetting,
  intermodalBooking: IntermodalBookingTableViewModel
): string => {
  switch (column.id) {
    case "status":
      return intermodalBooking.status.text;
    case "id":
    case "customerRef":
    case "departure":
    case "arrival":
    case "customer":
    case "iluCode":
    case "loadingNote":
    case "numberOfPlugins":
    case "routeName":
    case "vehicleReg":
    case "vehicleType":
    case "firstPartnerBookingNumber":
    case "secondPartnerBookingNumber":
      return intermodalBooking[column.id] ?? "";
    default:
      return "";
  }
};
