import { formatDateISO } from "../../common/utils/dateUtils";
import { CreateIntermodalBookingFormModel } from "./formSlice";

const DEFAULT_VEHICLE_TYPE_CODE = "TR";

export const createInitialCreateForm = (
  departureDate: Date
): CreateIntermodalBookingFormModel => {
  return {
    bookGoods: [{ code: null, id: null, weight: "" }],
    craneable: true,
    craneableStatus: null,
    customerNumber: null,
    date: formatDateISO(departureDate),
    customerReference: "",
    dropOffReference: "",
    emptyVehicleWeight: "",
    enablePlugin: true,
    time: "00:00",
    hazardousGoods: false,
    iluCode: "",
    loadingNote: "",
    numberOfPlugins: 0,
    pickUpReference: "",
    routeCode: null,
    temperature: "",
    vehicleHeight: "",
    vehicleLength: "",
    vehicleRegistration: "",
    vehicleTypeCode: DEFAULT_VEHICLE_TYPE_CODE,
    vehicleWidth: "",
    searchByDeparture: true,
  };
};
