import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  ConditionallyEditableSelect,
  ConditionallyEditableSelectProps,
  TypedOption,
} from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableSelect";
import {
  CreateIntermodalBookingFormModel,
  getIntermodalCreateFormField,
  setCreateIntermodalFormValue,
} from "../../redux/formSlice";

interface Props<
  TKey extends keyof CreateIntermodalBookingFormModel,
  TData,
  TOption extends TypedOption<CreateIntermodalBookingFormModel[TKey], TData>
> extends Omit<
    ConditionallyEditableSelectProps<
      CreateIntermodalBookingFormModel[TKey],
      TData,
      TOption
    >,
    "editable" | "value" | "onValueChange"
  > {
  name: TKey;
  options?: TOption[];
  onValueChange?: (option: TOption) => void;
  className?: string;
}

export function CreateBookingSelect<
  TKey extends keyof CreateIntermodalBookingFormModel,
  TData,
  TOption extends TypedOption<CreateIntermodalBookingFormModel[TKey], TData>
>({ name, options, onValueChange, ...props }: Props<TKey, TData, TOption>) {
  const value = useSelector((state: RootState) =>
    getIntermodalCreateFormField(state, name)
  );
  const dispatch = useDispatch();

  return (
    <ConditionallyEditableSelect
      {...props}
      editable
      options={options}
      value={value}
      onValueChange={(option) => {
        dispatch(
          setCreateIntermodalFormValue({
            values: { [name]: option.value },
          })
        );
        onValueChange?.(option);
      }}
    />
  );
}
