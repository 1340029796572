import { useMutation } from "@apollo/client";
import {
  Box,
  Indent,
  Row,
  Text,
  useBoolean,
  useTimeoutState,
} from "@stenajs-webui/core";
import {
  ActionMenuItem,
  FlatButton,
  ResultListBanner,
  stenaCheckCircle,
  stenaCopy,
  stenaPrint,
  stenaSearch,
} from "@stenajs-webui/elements";
import { TextInput } from "@stenajs-webui/forms";
import {
  ActionMenuSecondaryButton,
  SelectedItemsActionsPanel,
} from "@stenajs-webui/panels";
import { first } from "lodash";
import {
  CheckInIntermodalBookingLegDocument,
  CheckInIntermodalBookingLegMutation,
  CheckInIntermodalBookingLegMutationVariables,
  GetIntermodalBookingsDocument,
} from "../../../../generated";
import { isBookingViewModelAbleToCheckIn } from "../../../booking-details/utils/bookingActionsUtils";
import { getBannerErrorState } from "../../../common/utils/apolloErrorUtils";
import { IntermodalBookingTableViewModel } from "../models/IntermodalBookingsTableViewModel";
import {
  getSelectedIntermodalBookings,
  isAnySelectedBookingAbleToCheckIn,
} from "../utils/selectIdsUtil";
import { CheckInBookingsTableModal } from "./CheckInBookingsTableModal";
import { useEffect } from "react";

interface Props {
  intermodalBookings: IntermodalBookingTableViewModel[];
  selectedLegIds: string[];
  filteredBookingsLength: number;
  setSearchInput: (value: string) => void;
  onCopyBookings: () => void;
  onCopySelectedLegBookings: () => void;
}

export const BookingsTableHeader: React.FC<Props> = ({
  intermodalBookings,
  selectedLegIds,
  filteredBookingsLength,
  setSearchInput,
  onCopyBookings,
  onCopySelectedLegBookings,
}) => {
  const [checkInModalIsOpen, openCheckInModal, closeCheckInModal] =
    useBoolean(false);
  const selectedBookings = getSelectedIntermodalBookings(
    intermodalBookings,
    selectedLegIds
  );
  const selectedBookingsLength = selectedBookings.length;
  const firstSelectedBooking = first(selectedBookings);
  const firstSelectedLegBooking = first(
    firstSelectedBooking?.bookings.filter((booking) =>
      selectedLegIds.includes(booking.id)
    )
  );

  const [showCopyClicked, setShowCopyClicked] = useTimeoutState(false, 2000);

  const [
    checkInIntermodalBookingLeg,
    { loading: checkInLoading, error: checkInError, reset: checkInReset },
  ] = useMutation<
    CheckInIntermodalBookingLegMutation,
    CheckInIntermodalBookingLegMutationVariables
  >(CheckInIntermodalBookingLegDocument);

  const handleCheckIn = async () => {
    const isValidSingleBookingSelected =
      selectedLegIds.length === 1 &&
      firstSelectedBooking &&
      firstSelectedLegBooking &&
      isBookingViewModelAbleToCheckIn(firstSelectedLegBooking);
    if (isValidSingleBookingSelected) {
      try {
        await checkInIntermodalBookingLeg({
          variables: {
            intermodalBookingId: firstSelectedBooking!.id,
            legId: firstSelectedLegBooking!.id,
          },
          refetchQueries: [GetIntermodalBookingsDocument],
        });
      } catch {
        // error is handled internally by Apollo
      }
    } else {
      openCheckInModal();
    }
  };

  const bannerProps = getBannerErrorState(checkInError);

  useEffect(() => {
    checkInReset();
  }, [selectedLegIds, checkInReset]);

  return selectedBookingsLength === 0 ? (
    <Row indent={3} spacing={1} justifyContent={"space-between"}>
      <Row alignItems={"center"}>
        <Text variant={"bold"}>Bookings</Text>
        <Indent />
        <Text size={"small"}>
          {filteredBookingsLength}/{intermodalBookings.length}
        </Text>
        <Indent />
        <Box width={"200px"}>
          <TextInput
            className="t_search"
            placeholder="Search for booking"
            iconRight={stenaSearch}
            onValueChange={setSearchInput}
          />
        </Box>
      </Row>
      <Box>
        <ActionMenuSecondaryButton
          className="t_table_actions"
          label={"Table actions"}
          menuWidth={"19rem"}
          renderItems={(close) => (
            <>
              <ActionMenuItem
                className="t_table_actions_print"
                leftIcon={stenaPrint}
                label={"Print"}
                onClick={() => {
                  close();
                  setTimeout(() => {
                    window.print();
                  }, 10);
                }}
              />
              <ActionMenuItem
                className="t_table_actions_copy"
                leftIcon={stenaCopy}
                label={showCopyClicked ? "Copied" : "Copy to clipboard"}
                success={showCopyClicked}
                variant={showCopyClicked ? "success" : undefined}
                disableCloseOnClick
                onClick={() => {
                  onCopyBookings();
                  setShowCopyClicked(true);
                }}
              />
            </>
          )}
        />
      </Box>
    </Row>
  ) : (
    <>
      <SelectedItemsActionsPanel
        label={`${selectedBookingsLength} ${
          selectedBookingsLength === 1 ? "booking" : "bookings"
        } selected`}
        afterLabelContent={
          <Row gap>
            <FlatButton
              className="t_check_in"
              leftIcon={stenaCheckCircle}
              label="Check in"
              loading={checkInLoading}
              disabled={
                selectedBookingsLength > 1 ||
                !isAnySelectedBookingAbleToCheckIn(
                  intermodalBookings,
                  selectedLegIds
                )
              }
              onClick={handleCheckIn}
            />
            <FlatButton
              leftIcon={stenaCopy}
              label="Copy to clipboard"
              onClick={onCopySelectedLegBookings}
            />
          </Row>
        }
      />
      {bannerProps && <ResultListBanner {...bannerProps} />}
      {checkInModalIsOpen && firstSelectedBooking && (
        <CheckInBookingsTableModal
          id={firstSelectedBooking.id}
          onCheckInModalClose={closeCheckInModal}
        />
      )}
    </>
  );
};
