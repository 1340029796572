import { formatISO } from "date-fns";

export const formatServerDate = (date: Date) => {
  return formatISO(date, { representation: "date" });
};

export const formatMaybeServerDate = (unformattedDate: Date | undefined) => {
  return unformattedDate
    ? formatISO(unformattedDate, { representation: "date" })
    : undefined;
};
