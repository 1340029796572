import {
  format,
  isSameDay,
  isValid,
  parseISO,
  secondsToMinutes,
} from "date-fns";

export const formatDepartureDateTime = (date: Date) => {
  return format(date, "d LLL HH:mm");
};

export const formatDepartureWithMonth = (date: Date) => {
  return format(date, "d MMM HH:mm");
};

export const formatArrivalDateTime = (date: Date) => {
  return format(date, "LLL d, HH:mm");
};

export const formatDateISO = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const formatTimeISO = (date: Date) => {
  return format(date, "HH:mm");
};

export const formatDateTimeISO = (date: Date) => {
  return format(date, "yyyy-MM-dd, HH:mm");
};

export const getTimeGapFromSeconds = (seconds: number) => {
  const timeDifferenceInMinutes = Math.abs(secondsToMinutes(seconds));
  const hours = Math.floor(timeDifferenceInMinutes / 60);
  const minutes = timeDifferenceInMinutes % 60;
  return `${seconds < 0 ? "-" : ""}${hours}H ${minutes}MIN`;
};

export const formatNotificationDate = (
  date: Date,
  today: Date = new Date()
): string => {
  return isSameDay(date, today)
    ? formatTimeISO(date)
    : formatDepartureDateTime(date);
};

export const isStringValidDate = (dateString: string) => {
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) && dateString.length === 10;
};
