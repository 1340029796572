import { FC } from "react";
import { FormIntermodalBookingLegFragment } from "../../../../generated";
import {
  isBookingLegConfirmable,
  isBookingLegUnconfirmable,
} from "../../utils/bookingActionsUtils";
import { formatBookingRoute } from "../formatter";
import { SelectMultipleModal } from "./SelectMultipleModal";
import { Space, Txt } from "@stenajs-webui/core";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (indexesToConfirm: number[]) => void;
  bookings: FormIntermodalBookingLegFragment[];
  loading: boolean;
  initialState: boolean[];
}

export const ConfirmBookingsModal: FC<Props> = ({ bookings, ...restProps }) => {
  return (
    <SelectMultipleModal
      {...restProps}
      subHeaderText="Select bookings to confirm:"
      headerText="Confirm bookings"
      options={bookings.map((b) => ({
        label: formatBookingRoute(b.sailing.route),
        disabled: !isBookingLegConfirmable(b),
      }))}
    />
  );
};

export const UnconfirmBookingsModal: FC<Props> = ({
  bookings,
  ...restProps
}) => {
  return (
    <SelectMultipleModal
      {...restProps}
      subHeader={
        <>
          <Txt>
            Setting a booking to in progress / unconfirmed will only affect the
            status in Freightlink. Any confirmed bookings will still need to be
            managed at the train operator.
          </Txt>
          <Space />
          <Txt>Select bookings to set to in progress:</Txt>
        </>
      }
      headerText="Set to in progress / unconfirmed"
      options={bookings.map((b) => ({
        label: formatBookingRoute(b.sailing.route),
        disabled: !isBookingLegUnconfirmable(b),
      }))}
    />
  );
};
