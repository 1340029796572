import { debounce } from "lodash";
import { useEffect } from "react";
import { useLatest } from "./useLatest";

const events = ["mousemove", "mousedown", "keydown", "touchstart", "scroll"];

export const useOnNoMouseMovement = (callback: () => void, delay: number) => {
  const eventHandler = useLatest(callback);

  useEffect(() => {
    const onIdleChange = debounce(eventHandler.current, delay);
    events.forEach((event) =>
      window.addEventListener(event, onIdleChange, { passive: true })
    );

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, onIdleChange)
      );
    };
  }, [eventHandler, delay]);
};
