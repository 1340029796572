import { Spacing, Row, Box } from "@stenajs-webui/core";
import { Banner } from "@stenajs-webui/elements";
import { LoadingPanel } from "@stenajs-webui/panels";

interface Props {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  defaultMessage: string;
}

export const LoadingScreen = ({
  loading,
  error,
  errorMessage,
  defaultMessage,
}: Props) => {
  return (
    <>
      <Spacing num={2} />
      <Row justifyContent={"center"}>
        <Box maxWidth={"100%"}>
          {loading ? (
            <LoadingPanel />
          ) : error ? (
            <Banner variant={"error"} text={errorMessage} />
          ) : (
            <Banner variant={"info"} text={defaultMessage} />
          )}
        </Box>
      </Row>
    </>
  );
};
