import { Space, Txt } from "@stenajs-webui/core";
import { PrimaryButton } from "@stenajs-webui/elements";
import { msalInstance } from "../AuthGate";
import { scopes } from "../authConfig";

export const LoginPrompt = () => (
  <>
    <Txt size={"large"}>Use your Stena Line credentials to login.</Txt>
    <Space num={3} />
    <PrimaryButton
      onClick={() => {
        const account = msalInstance.getActiveAccount() ?? undefined;

        return msalInstance.loginRedirect({
          scopes: scopes,
          prompt: account ? "none" : undefined,
          account,
        });
      }}
      label={"Login"}
    />
  </>
);
