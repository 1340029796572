import { Box, useBoolean } from "@stenajs-webui/core";
import {
  FlatButton,
  stenaAngleDown,
  stenaAngleRight,
} from "@stenajs-webui/elements";
import { Checkbox } from "@stenajs-webui/forms";
import { TableCell, TableRow } from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import { Dispatch, SetStateAction } from "react";
import { tableColumns } from "../common/TableColumns";
import { IntermodalBookingTableViewModel } from "../models/IntermodalBookingsTableViewModel";
import {
  isIdSelected,
  isIntermodalBookingIndeterminatelySelected,
  isIntermodalBookingSelected,
  selectBookingId,
} from "../utils/selectIdsUtil";
import { resolveBookingLegTableCellContent } from "./helpers/resolveBookingLegTableCellContent";
import { resolveIntermodalBookingTableCellContent } from "./helpers/resolveIntermodalBookingTableCellContent";

interface BookingTableRowProps {
  intermodalBooking: IntermodalBookingTableViewModel;
  selectedLegIds: string[];
  onSelectIntermodalBooking: (value: boolean) => void;
  setSelectedLegIds: Dispatch<SetStateAction<string[]>>;
}

export const BOOKING_TABLE_BORDER = "1px solid var(--lhds-color-ui-300)";
const TABLE_ROW_HOVER_COLOR = cssColor("--lhds-color-blue-100");
const TABLE_ROW_SELECTED_COLOR = cssColor("--lhds-color-blue-200");

export const BookingTableRow = ({
  intermodalBooking,
  onSelectIntermodalBooking,
  setSelectedLegIds,
  selectedLegIds,
}: BookingTableRowProps) => {
  const [isExpanded, , , toggle] = useBoolean(false);

  const handleSelectBooking = (id: string, isSelecting: boolean) => {
    setSelectedLegIds((selectedLegIds) =>
      selectBookingId(id, isSelecting, selectedLegIds)
    );
  };

  return (
    <Box>
      <TableRow
        hoverBackground={TABLE_ROW_HOVER_COLOR}
        background={
          isIntermodalBookingSelected(intermodalBooking, selectedLegIds) ||
          isIntermodalBookingIndeterminatelySelected(
            intermodalBooking,
            selectedLegIds
          )
            ? TABLE_ROW_SELECTED_COLOR
            : undefined
        }
      >
        <TableCell minWidth={"52px"}>
          <FlatButton
            className="t_expand"
            onClick={toggle}
            leftIcon={isExpanded ? stenaAngleDown : stenaAngleRight}
          />
        </TableCell>
        <TableCell minWidth={"40px"} width={"40px"}>
          <Checkbox
            className="t_select"
            indeterminate={isIntermodalBookingIndeterminatelySelected(
              intermodalBooking,
              selectedLegIds
            )}
            value={isIntermodalBookingSelected(
              intermodalBooking,
              selectedLegIds
            )}
            onValueChange={onSelectIntermodalBooking}
            size={"small"}
          />
        </TableCell>
        {tableColumns.map((column, index) => (
          <TableCell
            borderLeft={index === 0 ? BOOKING_TABLE_BORDER : undefined}
            borderRight={BOOKING_TABLE_BORDER}
            key={column.id}
            minWidth={column.width}
            width={column.width}
            justifyContent={
              column.id === "specialProperties" ? "center" : undefined
            }
          >
            {resolveIntermodalBookingTableCellContent(
              column,
              intermodalBooking
            )}
          </TableCell>
        ))}
      </TableRow>
      {isExpanded &&
        intermodalBooking.bookings.map((booking) => (
          <TableRow
            key={booking.id}
            background={
              isIdSelected(booking.id, selectedLegIds)
                ? TABLE_ROW_SELECTED_COLOR
                : undefined
            }
            hoverBackground={TABLE_ROW_HOVER_COLOR}
          >
            <TableCell minWidth={"52px"} width={"52px"} />
            <TableCell minWidth={"40px"} width={"40px"}>
              <Checkbox
                className="t_select_leg"
                value={isIdSelected(booking.id, selectedLegIds)}
                onValueChange={(value) =>
                  handleSelectBooking(booking.id, value)
                }
                size={"small"}
              />
            </TableCell>
            {tableColumns.map((column, index) => (
              <TableCell
                borderLeft={index === 0 ? BOOKING_TABLE_BORDER : undefined}
                borderRight={BOOKING_TABLE_BORDER}
                key={column.id}
                minWidth={column.width}
                width={column.width}
                justifyContent={
                  column.id === "specialProperties" ? "center" : undefined
                }
              >
                {resolveBookingLegTableCellContent(column, booking)}
              </TableCell>
            ))}
          </TableRow>
        ))}
    </Box>
  );
};
