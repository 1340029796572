import { Box, Txt } from "@stenajs-webui/core";
import { Popover } from "@stenajs-webui/tooltip";
import { Icon, stenaExclamationTriangleFilled } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import { FC } from "react";
import {
  getIntermodalBookingPartnerConfirmedMismatchValue,
  getIntermodalBookingValueIsMismatched,
  IntermodalBookingLegFormModel,
} from "../../booking-details/redux/formSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../config/redux/store";

interface MismatchIndicatorProps {
  message?: string;
}

export const MismatchIndicator: FC<MismatchIndicatorProps> = ({
  message = "Different values on the bookings.",
}) => (
  <Popover
    content={
      <Box>
        {message === "" ? (
          <Txt variant={"caption"} userSelect={"none"}>
            {"Empty"}
          </Txt>
        ) : (
          <Txt>{message}</Txt>
        )}
      </Box>
    }
  >
    <Icon
      icon={stenaExclamationTriangleFilled}
      color={cssColor("--lhds-color-orange-500")}
    />
  </Popover>
);

type MismatchVariant =
  | {
      type: "legs";
    }
  | {
      type: "partner";
      value: unknown;
    };

export const useMismatchState = (
  name: keyof IntermodalBookingLegFormModel,
  selectedBookingIndex: number | null,
  disableClientMismatch: boolean = false
): MismatchVariant | undefined => {
  const hasMismatch = useSelector((state: RootState) =>
    getIntermodalBookingValueIsMismatched(state, name)
  );

  const partnerConfirmValue = useSelector((state: RootState) =>
    getIntermodalBookingPartnerConfirmedMismatchValue(
      state,
      name,
      selectedBookingIndex
    )
  );

  if (hasMismatch && selectedBookingIndex == null && !disableClientMismatch) {
    return { type: "legs" };
  }

  if (partnerConfirmValue !== undefined) {
    return { type: "partner", value: partnerConfirmValue };
  }

  return undefined;
};
