export const getOrdinal = (number: number) => {
  const englishOrdinalRules = new Intl.PluralRules("en", { type: "ordinal" });
  const suffixes = {
    zero: "",
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
    many: "th",
  };
  const category = englishOrdinalRules.select(number);
  const suffix = suffixes[category];
  return number + suffix;
};
