import { FC } from "react";
import { Row, Txt } from "@stenajs-webui/core";
import { Icon, stenaArrowRight } from "@stenajs-webui/elements";
import { FormIntermodalBookingLegFragment } from "../../../../generated";
import { cssColor } from "@stenajs-webui/theme";
import { formatBookingRoute } from "../formatter";
import { BookingActionsModal } from "./BookingActionsModal";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  bookings: FormIntermodalBookingLegFragment[];
}

export const CancelBookingsModal: FC<Props> = ({ bookings, ...props }) => {
  return (
    <BookingActionsModal
      {...props}
      subHeaderText={"The following bookings will be cancelled:"}
      headerText={"Cancel booking"}
    >
      {bookings.map((booking) => {
        if (!booking.isEditable) {
          return null;
        }

        return (
          <Row key={booking.id} gap>
            <Icon
              icon={stenaArrowRight}
              color={cssColor("--lhds-color-ui-500")}
            />
            <Txt>{formatBookingRoute(booking.sailing.route)}</Txt>
          </Row>
        );
      })}
    </BookingActionsModal>
  );
};
