import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseFloatElseUndefined } from "@stenajs-webui/core";
import { sumBy } from "lodash";
import { client } from "../../../config/graphql/apollo";
import {
  GetIntermodalJourneyDocument,
  GetIntermodalJourneyQuery,
  GetIntermodalJourneyQueryVariables,
} from "../../../generated";
import {
  IntermodalGQLError,
  getIntermodalGQLError,
} from "../../common/utils/apolloErrorUtils";
import { isStringValidDate } from "../../common/utils/dateUtils";
import { CreateIntermodalBookingFormModel } from "./formSlice";

export type RecalculateJourneyInput =
  GetIntermodalJourneyQueryVariables["input"];

export const transformToIntermodalJourneyInput = (
  model: CreateIntermodalBookingFormModel
): RecalculateJourneyInput | undefined => {
  const height = parseFloatElseUndefined(model.vehicleHeight);
  const length = parseFloatElseUndefined(model.vehicleLength);
  const vehicleWeight = parseFloatElseUndefined(model.emptyVehicleWeight);
  const width = parseFloatElseUndefined(model.vehicleWidth);

  const goodsWeight = sumBy(
    model.bookGoods,
    (bookGoods) => parseFloatElseUndefined(bookGoods.weight) ?? 0
  );

  if (
    isStringValidDate(model.date) &&
    model.routeCode &&
    height != null &&
    length != null &&
    width != null &&
    goodsWeight != null &&
    vehicleWeight != null &&
    model.customerNumber
  ) {
    return {
      date: model.date,
      time: model.time,
      routeCode: model.routeCode,
      height,
      length,
      totalWeight: goodsWeight + vehicleWeight,
      width,
      customerNumber: model.customerNumber,
      numberOfPlugins: model.numberOfPlugins,
      vehicleTypeCode: model.vehicleTypeCode,
      searchByDeparture: model.searchByDeparture,
    };
  } else {
    return undefined;
  }
};

export const recalculateJourneyThunk = createAsyncThunk<
  GetIntermodalJourneyQuery["intermodalJourneyForFilter"]["legs"],
  RecalculateJourneyInput,
  { rejectValue: IntermodalGQLError }
>(
  "recalculateJourney",
  async (input: RecalculateJourneyInput, { rejectWithValue }) => {
    const { data, errors } = await client.query({
      query: GetIntermodalJourneyDocument,
      fetchPolicy: "network-only",
      errorPolicy: "all",
      variables: { input },
    });

    if (errors) {
      const gqlError = getIntermodalGQLError(errors[0]);

      if (gqlError) {
        return rejectWithValue(gqlError);
      } else {
        console.error(errors);
        throw errors[0];
      }
    }

    return data.intermodalJourneyForFilter.legs;
  }
);
