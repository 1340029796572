import { useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  MismatchIndicator,
  useMismatchState,
} from "../../../common/form-fields/MismatchIndicator";
import { getIntermodalBookingLegFormValueByIndex } from "../../redux/formSlice";
import { Row, Txt } from "@stenajs-webui/core";

interface Props {
  selectedBookingIndex: number | null;
  className?: string;
}

export const UpdateBookingHazardousGoodsStatus = ({
  selectedBookingIndex,
  ...props
}: Props) => {
  const value = useSelector((state: RootState) =>
    getIntermodalBookingLegFormValueByIndex(
      state,
      selectedBookingIndex,
      "hazardousGoodsStatus"
    )
  );

  const mismatchState = useMismatchState(
    "hazardousGoodsStatus",
    selectedBookingIndex
  );
  const message =
    mismatchState?.type === "partner"
      ? "Partner confirmed value: " + mismatchState.value
      : undefined;

  return (
    <Row alignItems={"center"} gap {...props}>
      {mismatchState ? <MismatchIndicator message={message} /> : null}
      <Txt>{value ?? "–"}</Txt>
    </Row>
  );
};
