import { useMutation } from "@apollo/client";
import { Box, Row, Txt } from "@stenajs-webui/core";
import {
  Icon,
  PrimaryButton,
  ResultListBanner,
  stenaCheck,
  stenaCheckCircleFilled,
} from "@stenajs-webui/elements";
import { Modal } from "@stenajs-webui/modal";
import { cssColor } from "@stenajs-webui/theme";
import { useEffect, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { Link } from "../../common/common-ui/Link";
import { RootState } from "../../config/redux/store";
import {
  CreateIntermodalBookingDocument,
  GetIntermodalBookingsDocument,
  GetTodosDocument,
} from "../../generated";
import { BOOKING_TABLE_BORDER } from "../bookings/bookings-table/components/BookingTableRow";
import { ResultMessageBanner } from "../common/ResultMessageBanner";
import {
  ValidationError,
  getBannerErrorState,
} from "../common/utils/apolloErrorUtils";
import { CargoColumn } from "./components/columns/CargoColumn";
import { ConnectionsCreateColumn } from "./components/columns/ConnectionsCreateColumn";
import { DepartureColumn } from "./components/columns/DepartureColumn";
import { DetailsColumn } from "./components/columns/DetailsColumn";
import { VehicleColumn } from "./components/columns/VehicleColumn";
import {
  getIntermodalCreateFormModel,
  getIntermodalCreateJourneyError,
  getIntermodalCreateSubmitIsDisabled,
} from "./redux/formSlice";
import { safeTransformCreateIntermodalFormModelToInput } from "./redux/transformers";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function CreateIntermodalBookingsModal(props: Props) {
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);
  const [successResult, setSuccessResult] = useState<string | null>(null);
  const isDisabled = useSelector(getIntermodalCreateSubmitIsDisabled);
  const journeyError = useSelector(getIntermodalCreateJourneyError);

  const store = useStore<RootState>();
  const [
    createIntermodalBooking,
    { data: mutationResult, loading, error: createError },
  ] = useMutation(CreateIntermodalBookingDocument);

  const timeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  const onCreateIntermodalBooking = async () => {
    const model = getIntermodalCreateFormModel(store.getState());
    setValidationError(null);
    try {
      const intermodalBookingInput =
        safeTransformCreateIntermodalFormModelToInput(model);
      if (intermodalBookingInput.state === "error") {
        setValidationError(
          new ValidationError(
            intermodalBookingInput.errors.map((e) => e.message)
          )
        );
        return;
      }
      const createBookingResult = await createIntermodalBooking({
        variables: { input: intermodalBookingInput.result },
        refetchQueries: [GetTodosDocument, GetIntermodalBookingsDocument],
      });
      const bookingNumber =
        createBookingResult.data?.createIntermodalBooking.intermodalBooking?.id;
      if (bookingNumber) {
        setSuccessResult(bookingNumber);
        timeoutRef.current = window.setTimeout(() => {
          props.onRequestClose();
        }, 5000);
      }
    } catch {
      // error is handled internally by Apollo
    }
  };

  const bannerProps = getBannerErrorState(
    validationError || journeyError || createError
  );

  return (
    <Modal
      {...props}
      shouldCloseOnOverlayClick={Boolean(successResult)}
      parentSelector={() => document.getElementById("modal-root")!}
      headerText={"Add booking"}
      width={"auto"}
      style={{ overlay: { zIndex: "auto" }, content: { maxWidth: "100%" } }}
      draggable
    >
      {successResult ? (
        <Box
          width={600}
          alignItems={"center"}
          justifyContent={"center"}
          gap={4}
          indent
          spacing={4}
        >
          <Icon
            icon={stenaCheckCircleFilled}
            size={100}
            color={cssColor("--lhds-color-green-600")}
          />
          <Txt>
            Booking{" "}
            <Link to={`/bookings/${successResult}`}>{successResult}</Link>{" "}
            successfully created
          </Txt>

          <ResultMessageBanner
            messages={mutationResult?.createIntermodalBooking.messages}
          />
        </Box>
      ) : (
        <>
          {bannerProps && (
            <Box indent spacing>
              <ResultListBanner {...bannerProps} />
            </Box>
          )}
          <Box indent spacing>
            <Box
              overflow={"auto"}
              borderTop={BOOKING_TABLE_BORDER}
              borderBottom={BOOKING_TABLE_BORDER}
            >
              <Row borderLeft={BOOKING_TABLE_BORDER} minHeight={500}>
                <ConnectionsCreateColumn />
                <DepartureColumn />
                <VehicleColumn />
                <CargoColumn />
                <DetailsColumn />
              </Row>
            </Box>
          </Box>
          <Row indent spacing justifyContent={"end"}>
            <PrimaryButton
              className="t_create_booking"
              loading={loading}
              loadingLabel="Create booking"
              label="Create booking"
              variant="success"
              leftIcon={stenaCheck}
              disabled={isDisabled}
              onClick={onCreateIntermodalBooking}
            />
          </Row>
        </>
      )}
    </Modal>
  );
}
