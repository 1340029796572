import { Txt } from "@stenajs-webui/core";
import { Label, Link, stenaUser } from "@stenajs-webui/elements";
import { Select } from "@stenajs-webui/select";
import {
  FormIntermodalBookingFragment,
  FormIntermodalBookingLegFragment,
} from "../../../../generated";
import { BookingDetailsColumn } from "../../../common/bookings/BookingDetailsColumn";
import { formatCustomerName } from "../../../common/utils/customerUtils";

interface Props {
  editable: boolean;
  intermodalBooking: FormIntermodalBookingFragment;
  booking: FormIntermodalBookingLegFragment;
}

export const CustomerColumn: React.FC<Props> = ({
  editable,
  intermodalBooking,
  booking,
}) => {
  return (
    <BookingDetailsColumn heading="Customer" icon={stenaUser} gap={2}>
      <Label text="Account">
        {editable ? (
          <Select
            className="t_account"
            isDisabled
            value={{
              label: formatCustomerName(booking.customer),
              value: booking.customer.id,
            }}
          />
        ) : (
          <Txt>{formatCustomerName(booking.customer)}</Txt>
        )}
      </Label>
      <Label text="Mail">
        {intermodalBooking.userMail ? (
          <Link
            className="t_mail"
            href={`mailto:${intermodalBooking.userMail}`}
          >
            {intermodalBooking.userMail}
          </Link>
        ) : (
          <Txt variant={"caption"} size={"small"}>
            Empty
          </Txt>
        )}
      </Label>
    </BookingDetailsColumn>
  );
};
