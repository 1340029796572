import icon from "./Icon-purpose-lighthouse-80x80.svg";
import { Box, Txt } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";

export const EmptyConnectionsMedal = () => (
  <Box gap={4} alignItems={"center"} spacing={2}>
    <Box
      background={cssColor("--lhds-color-ui-50")}
      shadow={"box"}
      borderRadius={"50%"}
      width={80}
      height={80}
      indent
      spacing
    >
      <img src={icon} alt="Lighthouse icon" />
    </Box>
    <Txt>Select departure to show connections</Txt>
  </Box>
);
