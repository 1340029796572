import {
  FieldValidationError,
  ValidationResult,
} from "../../../common/validation/types";
import {
  isDefined,
  validate,
  validateAndParseNumber,
} from "../../../common/validation/validation";
import { ENABLE_USE_BLOCK_BOOKINGS } from "../../../config/featureFlags";
import { CreateIntermodalBookingInput } from "../../../generated";
import { IntermodalCreateFormModel } from "./formSlice";

type ValidationKeyCreate = keyof CreateIntermodalBookingInput;

export const safeTransformCreateIntermodalFormModelToInput = ({
  intermodalForm: booking,
  legs,
}: IntermodalCreateFormModel): ValidationResult<
  CreateIntermodalBookingInput,
  ValidationKeyCreate
> => {
  const errors: FieldValidationError<ValidationKeyCreate>[] = [];

  validate<ValidationKeyCreate>(
    errors,
    isDefined(booking.customerNumber),
    "customerNumber",
    "Account is empty"
  );
  validate<ValidationKeyCreate>(
    errors,
    isDefined(booking.routeCode),
    "routeCode",
    "Route is empty"
  );

  validate<ValidationKeyCreate>(
    errors,
    isDefined(legs) && legs.length > 0,
    "legs",
    "We could not find a matching connection. Change details (like date or time) to search again."
  );

  const vehicleHeight = validateAndParseNumber<ValidationKeyCreate>(
    errors,
    booking.vehicleHeight,
    "vehicleHeight",
    "Height"
  );

  const vehicleLength = validateAndParseNumber<ValidationKeyCreate>(
    errors,
    booking.vehicleLength,
    "vehicleLength",
    "Length"
  );
  const vehicleWidth = validateAndParseNumber<ValidationKeyCreate>(
    errors,
    booking.vehicleWidth,
    "vehicleWidth",
    "Width"
  );
  const emptyVehicleWeight = validateAndParseNumber<ValidationKeyCreate>(
    errors,
    booking.emptyVehicleWeight,
    "emptyVehicleWeight",
    "Empty vehicle weight"
  );

  const bookGoods = booking.bookGoods.map((bg) => {
    validate<ValidationKeyCreate>(
      errors,
      isDefined(bg.code),
      "bookGoods",
      "Book goods code is empty"
    );

    const weight = validateAndParseNumber<ValidationKeyCreate>(
      errors,
      bg.weight,
      "bookGoods",
      "Book goods weight"
    );

    return {
      code: bg.code,
      weight,
    };
  });

  if (errors.length > 0) {
    return {
      state: "error",
      errors,
    };
  }

  return {
    state: "success",
    result: {
      craneable: booking.craneable,
      customerNumber: booking.customerNumber,
      customerReference: booking.customerReference,
      routeCode: booking.routeCode,
      iluCode: booking.iluCode,
      vehicleHeight,
      vehicleLength,
      vehicleWidth,
      vehicleTypeCode: booking.vehicleTypeCode,
      vehicleRegistration: booking.vehicleRegistration,
      bookGoods,
      temperature: booking.temperature ?? "",
      craneableStatus: booking.craneableStatus,
      numberOfPlugins: booking.numberOfPlugins,
      hazardousGoods: booking.hazardousGoods,
      loadingNote: booking.loadingNote,
      emptyVehicleWeight,
      partnerBookingNumber: "",
      partnerBookingConfirmed: null,
      dropOffRef: booking.dropOffReference,
      pickUpRef: booking.pickUpReference,
      legs: legs.map((l) => ({
        blockBooking:
          l.blockBooking && ENABLE_USE_BLOCK_BOOKINGS
            ? {
                bookingNumber: l.blockBooking.bookingNumber,
                timestamp: l.blockBooking.timestamp,
              }
            : null,
        legNumber: l.legNumber,
        sailingId: l.sailing.id,
      })),
    },
  };
};
