import { ApolloError } from "@apollo/client";
import { Box, Spacing } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import { Dispatch, SetStateAction } from "react";
import { LoadingScreen } from "../../../../common/components/LoadingScreen";
import { AreaLoadingModal } from "../../../common/ModalLoader";
import { IntermodalBookingTableViewModel } from "../models/IntermodalBookingsTableViewModel";
import {
  selectAllIntermodalBookings,
  selectIntermodalBooking,
} from "../utils/selectIdsUtil";
import { BookingTableRow } from "./BookingTableRow";
import { BookingsTableHeadRow } from "./BookingsTableHeadRow";
import { SortState, SortStateKey } from "../types/SortState";

interface Props {
  bookings: IntermodalBookingTableViewModel[];
  selectedBookingIds: string[];
  loading: boolean;
  error: ApolloError | undefined;
  setSelectedBookingIds: Dispatch<SetStateAction<string[]>>;
  sortState: SortState;
  onClickSort: (key: SortStateKey) => void;
}

export const BookingsTable: React.FC<Props> = ({
  bookings,
  selectedBookingIds: selectedLegIds,
  loading,
  error,
  setSelectedBookingIds: setSelectedLegIds,
  onClickSort,
  sortState,
}) => {
  const handleSelectIntermodalBooking = (
    intermodalBookingTableViewModel: IntermodalBookingTableViewModel,
    isSelecting: boolean
  ) => {
    setSelectedLegIds((selectedLegIds) =>
      selectIntermodalBooking(
        intermodalBookingTableViewModel,
        isSelecting,
        selectedLegIds
      )
    );
  };

  const handleSelectAllBookings = (isSelecting: boolean) => {
    setSelectedLegIds(() => selectAllIntermodalBookings(bookings, isSelecting));
  };

  if (bookings.length === 0) {
    return (
      <LoadingScreen
        loading={loading}
        error={error != null}
        errorMessage="Could not fetch bookings"
        defaultMessage="We found no bookings on these dates and routes. Change the dates or routes to widen the search"
      />
    );
  }

  return (
    <Box position={"relative"} flexGrow={1} style={{ isolation: "isolate" }}>
      {loading && <AreaLoadingModal />}
      <Box
        overflowX={"auto"}
        flex={"1 1 1px"}
        background={cssColor("--lhds-color-ui-100")}
      >
        <Box
          display={"table"}
          shadow={"box"}
          background={cssColor("--lhds-color-ui-50")}
        >
          <BookingsTableHeadRow
            intermodalBookings={bookings}
            selectedBookingIds={selectedLegIds}
            onSelectAll={(value) => handleSelectAllBookings(value)}
            sortState={sortState}
            onClickSort={onClickSort}
          />
          {bookings.map((intermodalBooking) => (
            <BookingTableRow
              key={intermodalBooking.id}
              intermodalBooking={intermodalBooking}
              selectedLegIds={selectedLegIds}
              setSelectedLegIds={setSelectedLegIds}
              onSelectIntermodalBooking={(isSelected) =>
                handleSelectIntermodalBooking(intermodalBooking, isSelected)
              }
            />
          ))}
        </Box>
        <Spacing num={3} />
      </Box>
    </Box>
  );
};
