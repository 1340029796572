import * as React from "react";
import { Tag, TagProps } from "@stenajs-webui/elements";
import { PartnerBookingStatus } from "../../../generated";

const getPartnerBookingStatusTagProps = (
  partnerBookingStatus: PartnerBookingStatus | null | undefined
): Partial<TagProps> => {
  switch (partnerBookingStatus) {
    case PartnerBookingStatus.Pending:
      return { label: "Pending", variant: "info" };
    case PartnerBookingStatus.Confirmed:
      return { label: "Confirmed", variant: "success" };
    case PartnerBookingStatus.Unconfirmed:
      return { label: "Unconfirmed", variant: "warning" };
    default:
      return { label: "Unconfirmed", variant: "warning" };
  }
};

interface Props {
  partnerBookingStatus: PartnerBookingStatus | null | undefined;
}

export const PartnerBookingStatusTag: React.FC<Props> = ({
  partnerBookingStatus,
}) => {
  const tagProps = getPartnerBookingStatusTagProps(partnerBookingStatus);

  return <Tag {...tagProps} size={"small"} />;
};
