import { PickByValue } from "@stenajs-webui/core";
import { TextInputProps } from "@stenajs-webui/forms";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../config/redux/store";
import {
  MismatchIndicator,
  useMismatchState,
} from "../../../common/form-fields/MismatchIndicator";
import { ConditionallyEditableTextInput } from "../../../common/form-fields/conditionally-editable-fields/ConditionallyEditableTextInput";
import {
  IntermodalBookingLegFormModel,
  getIntermodalBookingLegFormValueByIndex,
  setBookingFormValue,
} from "../../redux/formSlice";

interface Props
  extends Omit<TextInputProps, "name" | "value" | "onValueChange"> {
  editable: boolean;
  showCopyButton?: boolean;
  name: keyof PickByValue<IntermodalBookingLegFormModel, string>;
  selectedBookingIndex: number | null;
  disableMismatch?: boolean;
  maskInput?: (value: string) => string;
}

export const UpdateBookingTextInput: React.FC<Props> = ({
  editable,
  showCopyButton = false,
  disableMismatch = false,
  name,
  selectedBookingIndex,
  maskInput = undefined,
  ...props
}) => {
  const value = useSelector((state: RootState) =>
    getIntermodalBookingLegFormValueByIndex(state, selectedBookingIndex, name)
  );
  const mismatchState = useMismatchState(
    name,
    selectedBookingIndex,
    disableMismatch
  );
  const hasUpdatedValue = mismatchState != null;
  const dispatch = useDispatch();

  return (
    <ConditionallyEditableTextInput
      {...props}
      maskInput={maskInput}
      editable={editable}
      bold={hasUpdatedValue}
      showCopyButton={showCopyButton}
      value={value ?? ""}
      contentLeft={
        mismatchState ? (
          <MismatchIndicator
            message={
              mismatchState.type === "partner"
                ? String(mismatchState.value)
                : undefined
            }
          />
        ) : null
      }
      onValueChange={(value) =>
        dispatch(
          setBookingFormValue({
            index: selectedBookingIndex,
            values: { [name]: value },
          })
        )
      }
    />
  );
};
