import { BookingViewModel } from "../../bookings-table/models/BookingViewModel";
import { IntermodalBookingTableViewModel } from "../../bookings-table/models/IntermodalBookingsTableViewModel";
import { SpecialProperty } from "../../bookings-table/models/SpecialProperty";
import { createMockCreator } from "../mock";

const intermodalBookingTableViewMock: IntermodalBookingTableViewModel = {
  bookings: [],
  departure: "2020-01-01T15:00",
  id: "12345678",
  routeName: "VEGO",
  specialProperties: [SpecialProperty.Hazardous, SpecialProperty.Plugin],
  customer: "STENASE",
  vehicleReg: "ABC123",
  arrival: "2020-01-0121:00",
  customerRef: "TEST123",
  iluCode: "54321",
  loadingNote: "Notes",
  numberOfPlugins: "Yes",
  status: { text: "Confirmed", code: "C" },
  firstPartnerBookingNumber: "T123456",
  secondPartnerBookingNumber: "T654321",
  vehicleType: "Trailer",
  firstSailingId: "1",
};

const bookingViewModelMock: BookingViewModel = {
  departure: "2020-01-01 15:00",
  id: "",
  routeName: "",
  specialProperties: [SpecialProperty.Plugin],
  vehicleReg: "ABC123",
  arrival: "2020-01-01 21:00",
  customerRef: "TEST123",
  customer: "STENASE",
  iluCode: "54321",
  loadingNote: "Notes",
  numberOfPlugins: "Yes",
  status: { text: "Confirmed", code: "C" },
  firstPartnerBookingNumber: "T123456",
  secondPartnerBookingNumber: "T654321",
  vehicleType: "Trailer",
  isEditable: true,
  isTrain: false,
};

export const createIntermodalBookingTableViewModelMock2 = (
  bookingIds: string[],
  route: string = intermodalBookingTableViewMock.routeName,
  departure: string = intermodalBookingTableViewMock.departure,
  sailingId: string = intermodalBookingTableViewMock.firstSailingId
): IntermodalBookingTableViewModel => {
  const bookings = bookingIds.map((bookingId) => {
    return {
      ...bookingViewModelMock,
      id: bookingId,
      departure: departure,
    };
  });
  return {
    ...intermodalBookingTableViewMock,
    id: "123",
    routeName: route,
    departure: departure,
    firstSailingId: sailingId,
    bookings: bookings,
  };
};

export const createIntermodalBookingTableViewModelMock = createMockCreator(
  intermodalBookingTableViewMock
);

export const createBookingViewModelMock =
  createMockCreator(bookingViewModelMock);
