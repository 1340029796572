import { useQuery } from "@apollo/client";
import { Box, Txt } from "@stenajs-webui/core";
import { LoadingModal } from "@stenajs-webui/modal";
import { cssColor } from "@stenajs-webui/theme";
import { first } from "lodash";
import { Fragment } from "react";
import { LoaderFunctionArgs, useNavigation, useParams } from "react-router-dom";
import { usePageTitle } from "../../common/routes/PageTitle";
import { client } from "../../config/graphql/apollo";
import { store } from "../../config/redux/store";
import {
  AssignIntermodalBookingToCurrentUserDocument,
  AssignIntermodalBookingToCurrentUserMutation,
  AssignIntermodalBookingToCurrentUserMutationVariables,
  GetIntermodalBookingByIdDocument,
  GetIntermodalBookingByIdQuery,
  GetIntermodalBookingByIdQueryVariables,
} from "../../generated";
import { BookingDetailsHeader } from "./components/BookingDetailsHeader";
import { BookingDetailsView } from "./components/BookingDetailsView";
import { initializeBookingForm } from "./redux/formSlice";
import { transformIntermodalFormModel } from "./redux/transformers";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { bookingId } = params;

  if (!bookingId) {
    throw new Response("Not Found", { status: 404 });
  }

  const { data, errors } = await client.mutate<
    AssignIntermodalBookingToCurrentUserMutation,
    AssignIntermodalBookingToCurrentUserMutationVariables
  >({
    mutation: AssignIntermodalBookingToCurrentUserDocument,
    variables: { id: bookingId },
    fetchPolicy: "network-only",
  });

  if (errors && errors.length > 0) {
    throw errors;
  }

  const booking = data?.assignIntermodalBookingToCurrentUser.intermodalBooking;

  if (!booking) {
    throw new Response("Not Found", { status: 404 });
  }

  store.dispatch(initializeBookingForm(transformIntermodalFormModel(booking)));

  return booking;
};

export const BookingDetailsPage = () => {
  const navigation = useNavigation();
  const { bookingId } = useParams();
  const { data } = useQuery<
    GetIntermodalBookingByIdQuery,
    GetIntermodalBookingByIdQueryVariables
  >(GetIntermodalBookingByIdDocument, {
    variables: { id: bookingId! },
    fetchPolicy: "cache-first",
  });
  const intermodalBooking = data?.intermodalBookingById!;
  const firstBooking = first(intermodalBooking.bookings);

  usePageTitle(intermodalBooking.id);

  if (!firstBooking) {
    return <Txt>Intermodal booking has no booking legs</Txt>;
  }

  return (
    <Fragment key={intermodalBooking.id}>
      <Box background={cssColor("--lhds-color-ui-200")} flex={1}>
        <BookingDetailsHeader bookingId={intermodalBooking.id} />
        <BookingDetailsView
          intermodalBooking={intermodalBooking}
          firstBooking={firstBooking}
        />
      </Box>
      {navigation.state === "loading" && <LoadingModal />}
    </Fragment>
  );
};
