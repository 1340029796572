import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Box, Row, Spacing, Txt } from "@stenajs-webui/core";
import { PrimaryButton, SecondaryButton } from "@stenajs-webui/elements";
import { Modal } from "@stenajs-webui/modal";
import { FC, ReactNode } from "react";

export interface BookingActionsModalProps {
  headerText: string;
  subHeaderText?: string;
  subHeader?: ReactNode;
  actionText?: string;
  cancelText?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit?: () => void;
  children: ReactNode;
  loading: boolean;
  onBack?: () => void;
  submitIcon?: IconDefinition;
  width?: string;
}

export const BookingActionsModal: FC<BookingActionsModalProps> = ({
  isOpen,
  onRequestClose,
  loading,
  headerText,
  subHeaderText,
  subHeader = <Txt>{subHeaderText}</Txt>,
  actionText = headerText,
  cancelText = "Cancel",
  onSubmit,
  children,
  onBack = null,
  submitIcon = undefined,
  width = "400px",
}) => {
  return (
    <Modal
      headerText={headerText}
      parentSelector={() => document.getElementById("modal-root")!}
      style={{
        overlay: { zIndex: "auto" },
        content: { maxWidth: "100%" },
      }}
      width={width}
      isOpen={isOpen}
      indent={3}
      spacing={3}
      onRequestClose={onRequestClose}
    >
      <Box>{subHeader}</Box>
      <Spacing num={2} />
      <Box gap={2}>{children}</Box>
      <Spacing num={2} />
      <Row justifyContent={"space-between"} gap={2}>
        <SecondaryButton
          onClick={onRequestClose}
          label={cancelText}
          className="t_close"
        />
        {onBack != null && <SecondaryButton onClick={onBack} label="Back" />}
        <PrimaryButton
          className="t_submit"
          leftIcon={submitIcon}
          label={actionText}
          onClick={onSubmit}
          loading={loading}
          loadingLabel={actionText}
          disabled={onSubmit == null}
        />
      </Row>
    </Modal>
  );
};
